import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/shared/core/services/dialog.service';
import { ContactsFacadeService } from '../../services/contacts-facade.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/core/base/base.component';
import { ParamsDTO } from 'src/app/models';
import { CustomerContactUniverseCreateDTO } from '../../models/customer-contact-universe-create.dto';
import { TableHeaderModel } from 'src/app/models/table-header.model';

@Component({
  selector: 'app-contact-list-detail-item',
  templateUrl: './contact-list-detail-item.component.html',
  styleUrls: ['./contact-list-detail-item.component.scss'],
})
export class ContactListDetailItemComponent extends BaseComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() writingMode: boolean;
  @Input() documentType: number;
  @Input() documentNumber: string;
  @Input() tableHeaders: TableHeaderModel[] = [];
  @Output() reloadList = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter<number>();
  positionList: ParamsDTO[] = [];
  loading: boolean = false;

  constructor(private readonly contactsFacadeService: ContactsFacadeService,
    private readonly dialogService: DialogService) { 
      super();
    }

  ngOnInit(): void {
    this.listenPositions();
  }

  listenPositions() {
    this.contactsFacadeService.positions$
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.positionList = response;
      })
  }

  deleteContact(contactId: number) {
    if (!this.writingMode) {
      this.dialogService.confirmationModal(
          '¿Estás seguro(a) que desea eliminar el contacto?', 
          'Eliminar', 'Cancelar')
        .afterClosed().subscribe(result => {
          if (result) {
            const contactDeleteBody = {
              userIdLastUpdate: this.contactsFacadeService.user,
              idContact: contactId
            }
            this.loading = true;
            this.contactsFacadeService.deleteContact(contactDeleteBody).subscribe({
              next: () => {
                this.reloadList.emit();
                this.loading = false;
              },
              error: () => this.loading = false
            })
          }
        });
    }
  }

  cancel(contactId: number | null) {
    if (contactId) {
      this.onCancelEdit.emit(contactId);
    } else {
      this.onRemove.emit();
    }
  }

  editContact(contactItemForm: FormGroup) {
    if (!this.writingMode) {
      contactItemForm.get('writingMode')?.setValue(true);
      this.onEdit.emit();
    }
  }

  save(contactItemForm: FormGroup) {
    if (contactItemForm.valid) {
      const item: CustomerContactUniverseCreateDTO = {
        idContact: contactItemForm.value.idContact || null,
        contactName: contactItemForm.value.contactName,
        phoneOne: contactItemForm.value.phoneOne,
        phoneTwo: contactItemForm.value.phoneTwo,
        emailOne: contactItemForm.value.emailOne,
        idPosition: contactItemForm.value.positionId,
        contactDocument: contactItemForm.value.nunDocContact,
        documentTypeContact: 1,
        userIdRegistration: this.contactsFacadeService.user,
        userIdLastUpdate: this.contactsFacadeService.user,
        customer: {
          idCustomer: { idDocumentType: this.documentType, documentNumber: this.documentNumber }
        }
      }
      this.loading = true;
      this.contactsFacadeService.saveContact(item)
        .subscribe({
          next: (response) => {
            this.loading = false;
            contactItemForm.get('writingMode')?.setValue(false);
            this.reloadList.emit();
          },
          error: () => this.loading = false
        })
    } else {
      contactItemForm.markAllAsTouched();
    }
  }

  addFavorite(contactId: number, favorite: boolean) {
    if (!this.writingMode) {
      this.loading = true;
        const priority = !(favorite || false);
        this.contactsFacadeService.addAndRemovePriority(priority, contactId)
        .subscribe({
          next: () => {
            this.loading = false;
            this.reloadList.emit();
          },
          error: () => this.loading = false
        })
    }
  }

  public copyToClipboard(email: string){
    navigator.clipboard.writeText(email);
  }

}
