import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { PipelineEffects } from './state/executive/pipeline/pipeline.effects';
import { pipelineReducer } from './state/executive/pipeline/pipeline.reducer';
import { PipelineStateModel } from './state/executive/pipeline/pipeline.model';
import { FallsEffects } from './state/executive/falls/falls.effects';
import { FallsStateModel } from './state/executive/falls/falls.model';
import { fallsReducer } from './state/executive/falls/falls.reducer';
import { NewOperationStateModel } from './state/executive/new-operation/new-operation.model';
import { newOperationReducer } from './state/executive/new-operation/new-operation.reducer';
import { NewOperationEffects } from './state/executive/new-operation/new-operation.effects';
import { ResumeEffects } from './state/executive/resume/resume.effects';
import { ResumeStateModel } from './state/executive/resume/resume.model';
import { resumeReducer } from './state/executive/resume/resume.reducer';
import { ResumeJefeEffects } from './state/boss/resume-jefe/resume-jefe.effects';
import { resumeJefeReducer } from './state/boss/resume-jefe/resume-jefe.reducer';
import { ResumeJefeStateModel } from './state/boss/resume-jefe/resume-jefe.model';
import { RankingEffects } from './state/boss/ranking/ranking.effects';
import { RankingStateModel } from './state/boss/ranking/ranking.model';
import { rankingReducer } from './state/boss/ranking/ranking.reducer';
import { NewEffects } from './state/boss/new/new.effects';
import { NewStateModel } from './state/boss/new/new.model';
import { newReducer } from './state/boss/new/new.reducer';
import { FallsJefeEffects } from './state/boss/falls-jefe/falls-jefe.effects';
import { FallsJefeStateModel } from './state/boss/falls-jefe/falls-jefe.model';
import { fallsJefeReducer } from './state/boss/falls-jefe/falls-jefe.reducer';
import { LossesEffects } from './state/boss/losses/losses.effects';
import { LossesStateModel } from './state/boss/losses/losses.model';
import { lossesReducer } from './state/boss/losses/losses.reducer';

export const ENOTE_FEATURE_KEY = 'enote';

export const selectEnoteState = createFeatureSelector<EnoteState>(
  ENOTE_FEATURE_KEY
);

export const EnoteEffects: any[] = [
    PipelineEffects,
    FallsEffects,
    NewOperationEffects,
    ResumeEffects,
    ResumeJefeEffects,
    RankingEffects,
    NewEffects,
    FallsJefeEffects,
    LossesEffects
];

export interface EnoteState {
  pipeline: PipelineStateModel;
  falls: FallsStateModel;
  newOperation: NewOperationStateModel;
  resume: ResumeStateModel,
  resume_jefe: ResumeJefeStateModel,
  ranking: RankingStateModel,
  new: NewStateModel,
  falls_jefe: FallsJefeStateModel,
  losses: LossesStateModel
}

export const EnoteReducerModule: ActionReducerMap<EnoteState> = {
  pipeline: pipelineReducer,
  falls: fallsReducer,
  newOperation: newOperationReducer,
  resume: resumeReducer,
  resume_jefe: resumeJefeReducer,
  ranking: rankingReducer,
  new: newReducer,
  falls_jefe: fallsJefeReducer,
  losses: lossesReducer
};
