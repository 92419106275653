import { createAction, props } from "@ngrx/store";
import { QualitativeStepsStateDTO } from "src/app/pages/qualitative-sheet/models";

export const qualitativeValidationStep = createAction('[QualitativeNew] Validation Step',
    props<{id: number}>()    
);
export const qualitativeValidationStepFailed = createAction('[QualitativeNew] Failed',
    props<{payload: any}>()
);

export const qualitativeValidationStepSuccess = createAction('[QualitativeNew] Success',
    props<{payload: QualitativeStepsStateDTO}>()
);

export const qualitativeValidationStepReset = createAction(
    '[QualitativeNew] Reset'
);
