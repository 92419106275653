import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CampaignSettingsService } from 'src/app/services/campaign-settings.service';
import { valueOfferList, valueOfferFailed, valueOfferSuccess } from './value-offer.actions';

@Injectable()
export class ValueOfferEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly campaignSettingsService: CampaignSettingsService
    ) { }

    getValueOffer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(valueOfferList),
            mergeMap((action) =>
                this.campaignSettingsService.getValueOffer(action.id)
                    .pipe(
                        map((data) => {
                            return valueOfferSuccess({ payload: data });
                        }),
                        catchError((error) => of(valueOfferFailed(error)))
                    )
            )
        )
    )
}
