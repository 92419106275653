import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { ClientListStateModel } from './state/list/client-list.model';
import { clientListReducer } from './state/list/client-list.reducer';
import { ClientListEffects } from './state/list/client-list.effects';
import { FamilyClientListStateModel } from './state/familyClient/family-client-list.model';
import { familyClientListReducer } from './state/familyClient/family-client-list.reducer';
import { FamilyClientListEffects } from './state/familyClient/family-client-list.effects';
import { HierarchyListStateModel } from './state/hierarchy-list/hierarchy-list.model';
import { hierarchyListReducer } from './state/hierarchy-list/hierarchy-list.reducer';
import { HierarchyListEffects } from './state/hierarchy-list/hierarchy-list.effects';

export const CLIENT_FEATURE_KEY = 'client';

export const selectClientState = createFeatureSelector<ClientState>(
  CLIENT_FEATURE_KEY
);

export const ClientEffects: any[] = [
  ClientListEffects,
  FamilyClientListEffects,
  HierarchyListEffects
];

// export const FamilyClientEffects: any[] = [
//   FamilyClientListEffects
// ];

// export const HierarchyEffects: any[] = [
//   HierarchyListEffects
// ];

export interface ClientState {
  clientList: ClientListStateModel,
  familyList: FamilyClientListStateModel,
  hierarchyList: HierarchyListStateModel
}

// export interface FamilyClientState {
//   list: FamilyClientListStateModel
// }

// export interface HierarchyState {
//   list: HierarchyListStateModel
// }

export const ClientReducerModule: ActionReducerMap<ClientState> = {
  clientList: clientListReducer,
  familyList: familyClientListReducer,
  hierarchyList: hierarchyListReducer
};

// export const FamilyClientReducerModule: ActionReducerMap<FamilyClientState> = {
//   list: familyClientListReducer
// };

// export const HierarchyReducerModule: ActionReducerMap<HierarchyState> = {
//   list: hierarchyListReducer
// };
