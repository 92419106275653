import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { SpeechNotification } from 'src/app/models/speech-notification';
import { SpeechErrorEnum } from '../enums/speech-error.enum';
import { SpeechEventEnum } from '../enums/speech-event.enum';

declare var webkitSpeechRecognition: any;

@Injectable()
export class SpeechRecognizerService {
  recognition: any;
  language: string = 'es-ES';
  isListening: boolean = false;

  constructor(private ngZone: NgZone) {}

  initialize(): boolean {
    if ('webkitSpeechRecognition' in window) {
      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.setLanguage();
      return true;
    }
    return false;
  }

  setLanguage(): void {
    this.recognition.lang = this.language;
  }

  start(): void {
    if (!this.recognition) return;

    this.recognition.start();
    this.isListening = true;
  }

  onStart(): Observable<SpeechNotification<never>> {
    if (!this.recognition) {
      this.initialize();
    }

    return new Observable((observer) => {
      this.recognition.onstart = () => {
        this.ngZone.run(() => {
          observer.next({
            event: SpeechEventEnum.Start,
          });
        });
      };
    });
  }

  onEnd(): Observable<SpeechNotification<never>> {
    return new Observable((observer) => {
      this.recognition.onend = () => {
        this.ngZone.run(() => {
          observer.next({
            event: SpeechEventEnum.End,
          });
          this.isListening = false;
        });
      };
    });
  }

  onResult(): Observable<SpeechNotification<string>> {
    return new Observable((observer) => {
      this.recognition.onresult = (event: any) => {
        let interimContent = '';
        let finalContent = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalContent += event.results[i][0].transcript;
            this.ngZone.run(() => {
              observer.next({
                event: SpeechEventEnum.FinalContent,
                content: finalContent,
              });
            });
          } else {
            interimContent += event.results[i][0].transcript;
            this.ngZone.run(() => {
              observer.next({
                event: SpeechEventEnum.InterimContent,
                content: interimContent,
              });
            });
          }
        }
      };
    });
  }

  onError(): Observable<SpeechNotification<never>> {
    return new Observable((observer) => {
      this.recognition.onerror = (event: any) => {
        const eventError: string = (event as any).error;
        let error: SpeechErrorEnum;
        switch (eventError) {
          case 'no-speech':
            error = SpeechErrorEnum.NoSpeech;
            break;
          case 'audio-capture':
            error = SpeechErrorEnum.AudioCapture;
            break;
          case 'not-allowed':
            error = SpeechErrorEnum.NotAllowed;
            break;
          default:
            error = SpeechErrorEnum.Unknown;
            break;
        }

        this.ngZone.run(() => { observer.next({error }); });
      };
    });
  }

  stop(): void {
    if (!this.recognition) return;
    this.recognition.stop();
  }
}
