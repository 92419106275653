import { createReducer, on} from "@ngrx/store";
import { pipelineFailed, pipelineList, pipelineReset, pipelineSuccess, pipelineCancel, pipelineRemoveItemSuccess } from "./pipeline.actions";
import { PipelineStateModel } from "./pipeline.model";

export const initialState: PipelineStateModel = {
    data: null,
    loading: false,
    failed: false
};

const _pipelineReducer = createReducer(initialState,
    on(pipelineList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(pipelineFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(pipelineSuccess, (state, {payload}) => {
        const previousData = state.data?.operaciones || [];
        const newData = payload?.operaciones?.pipeline || [];
        const data = [...previousData, ...newData];
        return {
            ...state,
            data: {
                ...payload,
                operaciones: data
            },
            loading: false,
            failed: false
        }
    }),
    on(pipelineRemoveItemSuccess, (state, { id }) => {
        const operaciones = [...(state.data?.operaciones || [])];
        const index = operaciones.findIndex((x: any) => x.id == id);
        operaciones.splice(index, 1);
        return {
            ...state,
            data: {
                operaciones
            }
        }
    }),
    on(pipelineCancel, (state, { payload }) => {
        const data = state.data || {};
        return {
            ...state,
            data: {
                ...data,
                operaciones: [...payload]
            },
            loading: false,
            failed: false
        };
    }),
    on(pipelineReset, state => state = initialState)
);

export function pipelineReducer(state: any, action: any): PipelineStateModel {
    return _pipelineReducer(state, action);
}
