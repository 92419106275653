import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createSelector, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CampaignSettingsService } from 'src/app/services/campaign-settings.service';
import { attributesFailed, attributesList, attributesSuccess } from './attributes.actions';
@Injectable()
export class AttributesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<any>,
        private readonly campaignSettingsService: CampaignSettingsService
    ) { }

    geAttributes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attributesList),
            mergeMap(() =>
                this.campaignSettingsService.getAttributes()
                    .pipe(
                        map((data) => {
                            return attributesSuccess(data);
                        }),
                        catchError((error) => of(attributesFailed(error)))
                    )
            )
        )
    )

}
