import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InformationModalModel, TypeInformationModal } from './information-modal.model';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit {

  public src:string;
  public notification:boolean = false;
  constructor(private readonly dialogRef: MatDialogRef<InformationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformationModalModel) { }

  ngOnInit(): void {
    this.setImg();
    // this.isNotification();

    const timeOut = this.data.timeOut || 2500;
    setTimeout(() => {
      this.dialogRef.close();
    }, timeOut);
  }

  public setImg(){
    let type: TypeInformationModal = this.data.type;
    switch (type) {
      case TypeInformationModal.SUCCESS:
        this.src = "/assets/img/check_valid.svg";
        break;
      case TypeInformationModal.ERROR:
        this.src = "/assets/img/icon_error_modal.svg";
        break;
      case TypeInformationModal.WARNING:
        this.src = "/assets/img/information.svg";
        break;
    }
  }

  // public isNotification() {
  //   if(this.data.notification){
  //     this.notification = this.data.notification;
  //   }
  // }
}
