import { createReducer, on} from "@ngrx/store";
import { resumeList, resumeFailed, resumeSuccess, resumeReset } from "./resume.actions";
import { ResumeStateModel } from "./resume.model";

export const initialState: ResumeStateModel = {
    data: {},
    loading: false,
    failed: false
};

const _resumeReducer = createReducer(initialState,
    on(resumeList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(resumeFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(resumeSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false
        }
    }),
    on(resumeReset, state => state = initialState)
);

export function resumeReducer(state: any, action: any): ResumeStateModel {
    return _resumeReducer(state, action);
}
