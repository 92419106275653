import { Injectable } from "@angular/core";
import CryptoJS from 'crypto-js';

@Injectable()
export class EncryptionService {

    private secretKey: string = "my-secret";

    encryptData<T>(data: T): string {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
        } catch (error) {
            console.error('Error al encriptar los datos', error);
            throw error;
        }
    }

    decryptData<T>(encryptedData: string): T | null {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as T;
            }
            return null;
        } catch (error) {
            console.log('Error al desencriptar los datos', error);
            return null;
        }
    }

}