import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InputCampaignModel, StateCampaignModel, SubStateCampaignModel } from 'src/app/pages/campaign-config/pages/campaign/models';
import {
  addInput,
  addState,
  addSubState,
  removeState,
  removeSubState,
  resetInputsStates,
} from './inputs-states.actions';
import {
  selectSelectedInputs,
  selectSelectedInputsStates,
  selectSelectedStates,
  selectSelectedSubStates,
} from './inputs-states.selectors';

@Injectable()
export class InputsStatesFacade {
  constructor(private readonly store: Store) {}

  addState(payload: StateCampaignModel) {
    this.store.dispatch(addState({ payload }));
  }

  removeState(id: number) {
    this.store.dispatch(removeState({ id }));
  }

  addSubState(payload: SubStateCampaignModel) {
    this.store.dispatch(addSubState({ payload }));
  }

  removeSubState(stateId: number, subStateId?: number) {
    this.store.dispatch(removeSubState({ stateId, subStateId }));
  }

  addInput(inputs: InputCampaignModel[]) {
    this.store.dispatch(addInput({ inputs }));
  }

  resetInputsStates() {
    this.store.dispatch(resetInputsStates());
  }

  selectSelectedInputsStates() {
    return this.store.select(selectSelectedInputsStates);
  }

  selectSelectedStates() {
    return this.store.select(selectSelectedStates);
  }

  selectSelectedSubStates() {
    return this.store.select(selectSelectedSubStates);
  }

  selectSelectedInputs() {
    return this.store.select(selectSelectedInputs);
  }
}
