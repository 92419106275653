import { createReducer, on} from "@ngrx/store";
import { addState, removeState, addSubState, removeSubState, addInput, resetInputsStates } from "./inputs-states.actions";
import { InputsStatesModel } from "./inputs-states.model";

export const initialState: InputsStatesModel = {
    states: [],
    subStates: [],
    inputs: []
};

const _inputsStatesReducer = createReducer(initialState,
    on(addState, (state, { payload }) => {
        const exist = state.states?.some(x => x.id == payload.id);
        return {
            ...state,
            states: exist ? [...state.states] : [...state.states, payload]
        }
    }),
    on(removeState, (state, { id }) => {
        const states = [...state.states];
        const index = states.findIndex((x: any) => x.id == id);
        states.splice(index, 1);
        return {
            ...state,
            states: states
        }
    }),
    on(addSubState, (state, { payload }) => {
        const exist = state.subStates?.some(x => x.stateId == payload.stateId && x.subStateId == payload.subStateId);
        return {
            ...state,
            subStates: exist ? [...state.subStates] : [...state.subStates, payload]
        }
    }),
    on(removeSubState, (state, { stateId, subStateId }) => {
        const subStates = [...state.subStates];
        if (subStateId) {
            const index = subStates.findIndex(x => x.stateId == stateId && x.subStateId == subStateId);
            subStates.splice(index, 1);
        } else {
            const subs = subStates.filter(x => x.stateId == stateId);
            for (let item of subs) {
                const index = subStates.findIndex(x => x.stateId == stateId && x.subStateId == item.subStateId);
                subStates.splice(index, 1);
            }
        }

        return {
            ...state,
            subStates: subStates
        }
    }),
    on(addInput, (state, { inputs }) => {
        return {
            ...state,
            inputs: [...inputs]
        }
    }),
    on(resetInputsStates, state => state = initialState)
);

export function inputsStatesReducer(state: any, action: any): InputsStatesModel {
    return _inputsStatesReducer(state, action);
}
