import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { inputOnlyNumbersValidator } from 'src/app/shared/ui/validators/input-number-validator';

@Component({
  selector: 'app-add-operation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-operation.component.html',
  styleUrls: ['./add-operation.component.scss']
})
export class AddOperationComponent implements OnInit {

  @Input() description: string;
  @Input() maxValue: number = 10;
  @Input() showImage: boolean = false;
  @Input() image: string;
  @Output() addClick = new EventEmitter<number>();
  
  quantity = new FormControl(1, [inputOnlyNumbersValidator, Validators.min(1), Validators.max(10)]);

  constructor() { }

  ngOnInit(): void {
  }

  add() {
    const data = this.quantity.value ? +this.quantity.value : 0;
    const value: number = data > 0 ? data : 1;
    this.addClick.emit(+value);
    this.quantity.setValue(1);
  }

  aggregate() {
    let value = this.quantity.value ? +this.quantity.value : 0;
    if (value == this.maxValue) return;

    value += 1;
    this.quantity.setValue(value);
  }

  subtract() {
    let value = this.quantity.value ? +this.quantity.value : 0;
    if (value == 1) return;

    value -= 1;
    this.quantity.setValue(value);
  }

}
