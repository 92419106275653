import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ContentType,
  HttpClientService,
} from '../shared/core/services/http-client.service';
import {
  CheckDocumentDTO,
  DocumentCreateDTO,
  DocumentDownloadGetDTO,
  DocumentsTypeDTO,
  DocumentsTypeGetDTO,
  InformationTypeDTO,
} from '../pages/escom-list/models';
import { HistoryGetDTO } from '../pages/escom-list/models/history.get.dto';
import { HistoryPaginatorDTO } from '../pages/escom-list/models/history.dto';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';

@Injectable()
export class EscomService {
  constructor(private readonly http: HttpClientService) {}

  get(tableId: number): Observable<InformationTypeDTO[]> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.credit_document_path}/parameter/filter?idTabla=${tableId}`;
    return this.http.get<InformationTypeDTO[]>(path, options);
  }

  getDocumentsType(
    request: DocumentsTypeGetDTO
  ): Observable<DocumentsTypeDTO[]> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.credit_document_path}/parameter/count`;
    return this.http.post<DocumentsTypeDTO[]>(path, request, options);
  }

  getHistory(request: HistoryGetDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.credit_document_path}/items/filter`;
    return this.http.post<HistoryPaginatorDTO>(path, request, options);
  }

  uploadDocument(query: DocumentCreateDTO, file: File) {
    let params = new HttpParams();
    params = params.append('documentNumber', query.documentNumber);
    params = params.append('idDocumentType', query.idDocumentType);
    params = params.append('idTable', query.idTable);
    params = params.append('idItem', query.idItem);
    params = params.append('period', query.period);
    params = params.append('userIdentifier', query.userIdentifier);

    const formData = new FormData();
    formData.append('file', file);

    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      },
      params,
    };
    const path = `${environment.base_url}${environment.credit_document_path}/items/upload`;
    return this.http.post<any>(path, formData, options);
  }

  checkDocument(request: DocumentCreateDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.credit_document_path}/items/check-document`;
    return this.http.post<CheckDocumentDTO>(path, request, options);
  }

  deleteDocument(id: number, userIdentifier: string) {
    let params = new HttpParams();
    params = params.append('documentId', id);
    params = params.append('userIdentifier', userIdentifier);
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
      params,
    };
    const path = `${environment.base_url}${environment.credit_document_path}/items/delete`;
    return this.http.delete<any>(path, options);
  }

  download(request: DocumentDownloadGetDTO) {
    let params = new HttpParams();
    params = params.append('documentId', request.idItemCreditDocument);
    params = params.append('userCode', request.userCode);
    params = params.append('userName', request.userName);
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
      params,
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }
    const path = `${environment.base_url}${environment.credit_document_path}/items/download`;
    return this.http.get<Blob>(path, options)
    .pipe(
      map((event: any) => ({
        progress: this.getPercentage(event, request.fileSize),
        response: event,
      }))
    );
  }

  public getPercentage(event: any, total: number) {
    if (event.type === HttpEventType.DownloadProgress) {
      return Math.round((100 * event.loaded) / total);
    } else if (event.type == HttpEventType.Response) {
      return 100;
    }
    return 0;
  }
}
