import { Component, OnInit, ViewChild, AfterViewInit, HostListener} from '@angular/core';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { ContactService } from '../../service/contact.service';
import {AuthService} from "../../../../services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import { Contact } from '../../models/contact';
import {ConfirmationComponent}  from 'src/app/shared/ui/components/confirmation/confirmation.component';
import { InformationModalComponent } from 'src/app/shared/ui/components/information-modal/information-modal.component';
import { TypeInformationModal } from 'src/app/shared/ui/components/information-modal/information-modal.model';
import { SearchComponent } from 'src/app/components/search/search.component';
import { Router } from '@angular/router';
import { LeadModel } from 'src/app/pages/client/pages/leads-detail/models/lead.model';
import { TooltipUiComponent } from 'src/app/shared/ui/components';
import { HierarchyModel } from 'src/app/models/hierarchy.model';
import { HierarchyDescShortEnum } from 'src/app/shared/core/enums/hierarchy.enum';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';
import { SELECTED_LEAD } from 'src/app/shared/core/constants/session.storage.constants';
import { SessionStorageService } from 'src/app/shared/core/services/session.storage.service';


@Component({
  selector: 'app-list-contact',
  templateUrl: './list-contact.component.html',
  styleUrls: ['./list-contact.component.scss']
})
export class ListContactComponent implements OnInit, AfterViewInit {

  @ViewChild(SearchComponent) appSearchComponent!: SearchComponent;
  @ViewChild("tooltip_title") tooltip_title: TooltipUiComponent;

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this.setLeadStorage();
  }
  
  public listContacts: Array<any> = new Array();
  public activeSearch:boolean = false;
  public loading:boolean = false;
  public valueSearchCustomer: string = "";
  public typeSearch: string;
  public isClearingSearch:boolean = false;

  public typeOperation:string = "list";

  public page: string = "0";
  public nRecords: string = "100";

  public newRecords:boolean = false;
  public nAddContact: number = 0;
  public incomplete:boolean = false;
  public invalidContactAcept:boolean = false;
  public CuRucInvalid: boolean = false;

  public newContactsList:Array<any> = new Array();
  public editContactsList:Array<any> = new Array();

  public valueSearchContact: string = "";
  public activeSearchContact: boolean = false;

  public activateContactSearch: boolean = false;
  public isButtonDisabled:boolean = false;

  public leadSelected: LeadModel;

  private dataTypeUniverseContactSubject = new Subject<any>();
  public openFilter: boolean = false;
  isExecutive: boolean = true;

  /*public listaContactos:Array<any> = new Array();
  public tipoOperacion:string = "lista";
  public agregarRegistros:boolean = false;
  public numContactosAgregados: number = 0;
  public invalidContactAcept:boolean = false;
  public nuevosContactos:Array<any> = new Array();
  public editarContactos:Array<any> = new Array();
  public listaContactosSearch:Array<any> = new Array();
  public busquedaActiva:boolean = false;

  public valueSearch:string;
  public incomplete:boolean = false;
  public CuRucInvalid: boolean = false;
  loading: boolean = false;
  public isButtonDisabled:boolean = false;
  */



  constructor(
    private userService: AuthService,
    public loaderService:LoaderService,
    public dialog: MatDialog,
    private contactService: ContactService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService){
    }

  ngAfterViewInit(): void {
    this.leadSelected = this.sessionStorageService.getItem<LeadModel>(SELECTED_LEAD);
    if (this.leadSelected) {
      this.sessionStorageService.removeItem(SELECTED_LEAD);
      const { ruc, uniqueCode, company } = this.leadSelected;
      const searchValue = ruc ? ruc : uniqueCode ? uniqueCode : (company?.trim() || '');
      this.valueSearchCustomer = searchValue;
      this.activeSearch = true;
      this.getContacts();
    } else {
      this.getContacts();
    }
    this.newContactsList = new Array();
  }


  ngOnInit(): void {
    this.contactService.selectedHierarchyUser = null;
    this.dataTypeUniverseContactSubject.subscribe(contactDelete => {
      if(contactDelete){
        this.deleteContact(contactDelete);
      }
    })
  }

  navigateLeadDetail() {
    this.setLeadStorage();
    this.router.navigate([`dashboard/clients/${this.leadSelected?.idLead}/details`]);
  }

  setLeadStorage() {
    this.sessionStorageService.setItem(SELECTED_LEAD, this.leadSelected);
  }

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  setFirstHierarchy(hierarchy: HierarchyModel) {
    this.isExecutive = hierarchy?.hierarchyShort == HierarchyDescShortEnum.EJECUTIVO_NEGOCIO;
  }
  
  public filterHierarchy(user: string) {
    this.openFilter = false;
    this.contactService.selectedHierarchyUser = user;
    this.getContacts()
  }

  public getContacts():void {
    let body = {
      "search": this.activeSearch ? this.valueSearchCustomer: null,
      "userRegistration": this.contactService.selectedHierarchyUser || this.user,
      "limit": this.nRecords,
      "offset": this.page,
      "searchContact": this.activeSearchContact ? this.valueSearchContact : null,
    }
    this.loading = true;
    this.activeSearch = this.activeSearch == false ? false : true;
    this.typeOperation = 'list';
    this.nAddContact = 0;
    this.newRecords = false;

    this.contactService.getContact(body).subscribe( {
      next: (contactsList) => {
        if(contactsList.listContact.length > 0){
          this.loading = false;
          this.activeSearch = this.activeSearch ? true : false;
          this.listContacts = contactsList.listContact.map((contacto:Contact) => this.setMissingProperties(contacto));
          this.setUUIDtoRegister();
        } else {
          this.loading = false;
          if (!this.leadSelected) {
            if(this.valueSearchCustomer.length != 0) {
              this.informationModal(TypeInformationModal.WARNING, "No se encontro la empresa con la busqueda realizada, Ingrese otro CU/RUC o Nombre.");
              if (this.appSearchComponent) {
                this.appSearchComponent.clear();
              }
              this.typeSearch = "";
              this.valueSearchCustomer = "";
              this.activeSearch = false;
              this.page = "0";
              this.getContacts();
            }
          }
        }
      },
      error: (error) => {
        this.loading = false;
        this.typeSearch = "";
        this.valueSearchCustomer = "";
        this.activeSearch = false;
        this.listContacts = new Array();
        if(!this.isClearingSearch){
          this.isClearingSearch = true;
          if (this.appSearchComponent) {
            this.appSearchComponent.clear();
          }
        }else{
          this.isClearingSearch = false;
        }
        this.informationModal(TypeInformationModal.ERROR,  "Error al listar los registros, Intenta nuevamente en unos minutos");
        this.isClearingSearch = false;
      }
    });

  }

  //Para verificar propiedades
  private setMissingProperties(contacto: Contact): Contact {
    const defaultContacto = {
      ruc: '',
      typeDoc: '',
      companyName: '',
      contactName: '',
      position: '',
      phoneOne: '',
      phoneTwo: '',
      email: '',
      updateDate: ''
    };

    return { ...defaultContacto, ...contacto };
  }

  // generar codigo unico
  public setUUIDtoRegister() {
    if(this.listContacts.length > 0){
      this.listContacts.forEach( contact => {
        contact.uuid = (<any>crypto).randomUUID();
      });
    }
  }

  //busqueda de cliente
  public searchContactByCustomer(value:string) {
    let uniqueCodeSearch = '';
    let businessNameSearch = '';
    let rucSearch = '';
    let cantMinCaracter = 3;

    let newValue = value.trim();
    let numberValidate: boolean = this.isNumber(newValue);
    this.activeSearch = true;

    if (numberValidate && (newValue.length > 11)) {
      this.informationModal(TypeInformationModal.WARNING, "Criterio de búsqueda invalido.");
      this.activeSearch = false;
      return;
    }else if( newValue != "" && !numberValidate && (newValue.length < cantMinCaracter)){
      this.informationModal(TypeInformationModal.WARNING, "Para buscar por Nombre, requiere minimo 3 caracteres");
      this.activeSearch = false;
      return;
    }

    if (newValue.length == 0 ){
      this.valueSearchCustomer = '';
      this.activeSearch = false;
      this.page = "0";
      if(this.isClearingSearch != true) this.getContacts();
    }else{
      if (newValue.length <= 10 && numberValidate) {
        let ceros = "";
        for (let i = 0; i < 10 - newValue.length; i++) {
          ceros += "0";
        }
        uniqueCodeSearch = ceros + newValue;
        this.typeSearch = 'uniqueCodeSearch';
        this.valueSearchCustomer = uniqueCodeSearch;
      } else if (newValue.length == 11 && numberValidate) {
        rucSearch = newValue;
        this.typeSearch = 'rucSearch';
        this.valueSearchCustomer = rucSearch;
      } else {
        businessNameSearch = newValue;
        this.typeSearch = 'businessNameSearch';
        this.valueSearchCustomer = businessNameSearch
      }
      this.page = "0";
      this.getContacts();
    }
  }

  //busqueda de contacto
  public searchContact(value:string) {
    let cantMinCaracter = 3;
    let newValue = value.trim();
    let numberValidate: boolean = this.isNumber(newValue);
    this.activeSearchContact = true;

    if (numberValidate) {
      this.informationModal(TypeInformationModal.WARNING, "Criterio de búsqueda invalido.");
      this.activeSearchContact = false;
      return;
    }else if( newValue != "" && !numberValidate && (newValue.length < cantMinCaracter)){
      this.informationModal(TypeInformationModal.WARNING, "Para buscar por Nombre, requiere minimo 3 caracteres");
      this.activeSearchContact = false;
      return;
    }

    if (newValue.length == 0 ){
      this.valueSearchContact = '';
      this.activeSearchContact = false;
      this.page = "0";
      if(this.isClearingSearch != true) this.getContacts();
    }else{
      this.valueSearchContact = value;
      this.page = "0";
      this.getContacts();
    }
  }

  //agregar contacto
  public addContact(nContacts: number) {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses empiezan en 0, por lo que se le suma 1
    const year = date.getFullYear();

    let fecha = `${day}-${month}-${year}`;

    if(this.typeOperation == 'edit'){
      this.informationModal(TypeInformationModal.WARNING, "Porfavor, termine la acción de Editar Contactos para agregar un Nuevo Contacto");
    }else if(this.typeOperation = 'list'){
      //console.log(this.leadSelected);
      const cu = this.leadSelected ? this.leadSelected.uniqueCode? this.leadSelected.uniqueCode : this.leadSelected.ruc :'';
      const companyName = this.leadSelected ? this.leadSelected.company : '';
      const ruc = this.leadSelected ? this.leadSelected.ruc :'';
      
      for (let index=0; index < nContacts; index++){
        let contact: Contact = {
          uuid : (<any>crypto).randomUUID(),
          companyName: companyName || 'Nombre Empresa',
          //contactAddress: [],
          //contactLastName: '',
          //contactMotherlastName: '',
          contactName: '',
          cu,
          email: '',
          idContact: NaN,
          phoneOne: '',
          phoneTwo: '',
          position: '',
          ruc,
          typeDoc: NaN,
          updateDate: fecha,
          isNew: true,
          customer: {
            idCustomer: {
              idDocumentType: NaN,
              documentNumber: ''
            }
          }
        }
        console.log("list => ", contact);
        this.listContacts.unshift(contact);
      }

      this.newRecords = true;
      this.nAddContact = this.nAddContact + nContacts;
      this.incomplete = true;
    }
  }

  //editar contactos
  public editContacts(){
    if (this.listContacts.length > 0) {
      if (this.newRecords == true){
        this.informationModal(TypeInformationModal.WARNING, "Porfavor, termine la acción de Agregar Contactos para editar un contacto");
      } else {
        this.typeOperation = 'edit'
      }
    }
  }

  //obtener el nombre de la empresa, su ruc
  //TODO: en el html tengo que cambiarle y al nombre del componente hijo para que haga un binding
  public getCompanyName(contact: any){
    let body = {
      "type": null,
      "uniqueCode": contact.contactCu
    }

    return this.contactService.getCustomerWithUniqueCode(body).pipe(
      map( (response) => {
        if(response.mensaje){
          //cuando no se encuentra registrado
          this.CuRucInvalid = true;
          this.informationModal(TypeInformationModal.WARNING, "El Cu/Ruc ingresado no se encuentra registrado");
        }else{
          this.CuRucInvalid = false;
          return response;
        }
      })
    )
  }

  //
  public prepareContact(contact: Contact) {
    contact.invalidFormAcept == true ? this.invalidContactAcept = true : this.invalidContactAcept = false;
    contact.invalidForm == true || this.CuRucInvalid == true ? this.incomplete = true : this.incomplete = false;

    if(contact.isNew) {
      let contactNew = {
        uuid: contact.uuid,
        flag: 'I',
        idContact: null,
        contactName: contact.contactName,
        phoneOne: contact.phoneOne,
        phoneTwo: contact.phoneTwo,
        emailOne: contact.email,
        position: contact.position,
        userIdRegistration: this.user,
        userIdLastUpdate: this.user,
        customer: contact.customer
      }

      if(contact.uuid && contact.contactName) {
        if((this.newContactsList.length <= this.nAddContact)){
          let indexContactNew = this.newContactsList.findIndex(contactItem => contactItem.uuid == contact.uuid);
          if(indexContactNew != -1){
            this.newContactsList[indexContactNew] = contactNew;
          }else{
            this.newContactsList.push(contactNew);
          }
        }
      }
    } else if(this.typeOperation == 'edit') {

      if (contact.idContact){
        let contactEdit = {
          uuid: contact.uuid,
          flag: 'A',
          idContact: contact.idContact,
          contactName: contact.contactName,
          phoneOne: contact.phoneOne,
          phoneTwo: contact.phoneTwo,
          emailOne: contact.email,
          position: contact.position,
          userIdRegistration: this.user,
          userIdLastUpdate: this.user,
          customer: contact.customer
        }

        if(contact.uuid){
          let indexContactEdit = this.editContactsList.findIndex((contactItem => contactItem.uuid == contact.uuid));
          if(indexContactEdit != -1){
            this.editContactsList[indexContactEdit] = contactEdit;
          }else{
            this.editContactsList.push(contactEdit);
          }
        }
      }
    }

  }

  public checkContactType(contactDelete: any) {

    let contactBody = {
      idContact: contactDelete.idContact
    }

    this.contactService.getContactTypeUniverse(contactBody).subscribe(response => {

      if(response.idContact == contactDelete.idContact){
        let contactDeleteBody = {
          modo: contactDelete.modo,
          uuid: contactDelete.uuid,
          idContact: contactDelete.idContact,
          search: contactDelete.busqueda,
          ceActive: response.ceActive,
          npsActive: response.npsActive
        }

        this.dataTypeUniverseContactSubject.next(contactDeleteBody);
      }
    })
  }

  public removeContact(contactDelete: any) {
    if (contactDelete.modo == 'agregado') {
      this.listContacts.splice(contactDelete.index, 1);
      this.newRecords = this.listContacts.some(item => item.isNew);
    } else {
      this.checkContactType(contactDelete);
    }
  }

  private deleteContact(contactDelete: any){

    let message: string = '';

    if(contactDelete.ceActive == true && contactDelete.npsActive == false && contactDelete.idContact){
      message = 'Este contacto ha sido elegido para Comprobante Electrónico';
    }else if(contactDelete.ceActive == false && contactDelete.npsActive == true && contactDelete.idContact){
      message = 'Este contacto ha sido elegido para NPS';
    }else if(contactDelete.ceActive == true && contactDelete.npsActive == true && contactDelete.idContact){
      message = 'Este contacto ha sido elegido para NPS y Comprobante Electronico';
    }

    let description = `<p>${message}</p>`;
        description += `<p>¿Estás seguro(a) que desea eliminar el contacto?</p>`;

    let typeInfo = message != '' ? TypeInformationModal.ERROR : TypeInformationModal.WARNING;

    this.dialog.open(ConfirmationComponent, {
      width: '500px',
      data: {
        description: description,
        type: typeInfo,
        okText: 'Eliminar',
        cancelText: 'Cancelar'
      },
    }).afterClosed().subscribe(result => {
      if(result) {
        if(contactDelete.modo == 'agregado'){
          let indexContactDelete = this.listContacts.findIndex(contactItem => contactItem.uuid == contactDelete.uuid);
          let indexContactDeleteListNews = this.newContactsList.findIndex(contactItem => contactItem.uuid == contactDelete.uuid);
          this.listContacts.splice(indexContactDelete, 1);
          if(indexContactDeleteListNews != -1) this.newContactsList.splice(indexContactDeleteListNews,1);
          this.nAddContact = this.nAddContact - 1;
          this.newContactsList.length > 0 ? this.newRecords = true : this.newRecords = false;

          this.invalidContactAcept == true
          ? (this.newContactsList.length == 0)
            ? this.invalidContactAcept = false
            : this.invalidContactAcept = true
          : this.invalidContactAcept = false

        } else {
          let contactDeleteBody = {
            userIdLastUpdate: this.user,
            idContact:contactDelete.idContact
          }

          this.contactService.deleteContact(contactDeleteBody).subscribe({
            next: (result) => {
              let elementIndex = this.listContacts.findIndex((contactItem => contactItem.uuid == contactDelete.uuid));
              if(elementIndex != -1 )this.listContacts.splice(elementIndex, 1);

              if(this.typeOperation == 'edit'){
                let elementIndex = this.editContactsList.findIndex((contactItem => contactItem.uuid == contactDelete.uuid));
                if(elementIndex != -1){
                  this.editContactsList.splice(elementIndex, 1);
                }
              }
              this.informationModal(TypeInformationModal.SUCCESS, "Se elimino el contacto correctamente");
              this.getContacts();
            },
            error: (error) => {
              if(this.typeOperation == 'edit'){
                let elementIndex = this.editContactsList.findIndex((contactItem => contactItem.uuid == contactDelete.uuid));
                if(elementIndex != -1){
                  this.editContactsList.splice(elementIndex, 1);
                }
              }
              this.getContacts();
            }
          })
        }
      }
    })
  }

  public closeSearchContacts() {
    this.activateContactSearch = false;
    this.valueSearchContact = '';
    this.activeSearchContact = false;
    this.page = "0";
    if(this.isClearingSearch != true) this.getContacts();
  }

  public save() {
    this.isButtonDisabled = true;
    if(this.newContactsList.length == 0 && this.typeOperation == 'list'){
      this.informationModal(TypeInformationModal.WARNING, "No se puede guardar la operación. Debe completar los campos requeridos");
    }else if(this.editContactsList.length == 0 && this.typeOperation == 'edit'){
      this.informationModal(TypeInformationModal.WARNING, "No se ha modificado contactos");
    }else{
      if(this.newContactsList.length == this.nAddContact && this.newRecords){
        let listContactSinUuid = this.newContactsList.map(contact => {
          const { uuid, ...restoDelContacto } = contact;
          return restoDelContacto;
        });

        this.contactService.saveContact(listContactSinUuid).subscribe({
          next: (contactsAdd) => {
            this.informationModal(TypeInformationModal.SUCCESS, `Se guardó correctamente el/los contacto/s (${this.nAddContact}).`);
            this.listContacts.splice(0, this.nAddContact);
            this.newContactsList.splice(0, this.nAddContact);
            listContactSinUuid.splice(0, this.nAddContact);
            this.nAddContact = 0;
            this.newRecords = false;
            this.invalidContactAcept = false;
            this.getContacts();
          },
          error: (error) => {
            this.informationModal(TypeInformationModal.ERROR, "Error al guardar el/los contacto/s, Intenta nuevamente en unos minutos.");
            listContactSinUuid.splice(0, this.nAddContact);
            this.listContacts.splice(0, this.nAddContact);
            this.newContactsList.splice(0, this.nAddContact);
            this.nAddContact = 0;
            this.newRecords = false;
            this.invalidContactAcept = false;
          }
        });
      } else if(this.typeOperation == 'edit'){
        let listContactSinUuid = this.editContactsList.map(contact => {
          const { uuid, ...restoDelContacto } = contact;
          return restoDelContacto;
        });

        this.contactService.saveContact(listContactSinUuid).subscribe({
          next: (contactsEdit) => {
            this.informationModal(TypeInformationModal.SUCCESS, `Se actualizó correctamente el/los contacto/s (${this.editContactsList.length}).`);
            listContactSinUuid.splice(0, this.editContactsList.length);
            this.editContactsList.splice(0, this.editContactsList.length);
            this.invalidContactAcept = false;
            this.typeOperation = 'list';
            //
            this.activeSearch == true
            ? this.getContacts()
            : this.valueSearchContact = "";
              this.activateContactSearch = false;
              this.getContacts();
          },
          error: (error) => {
            this.informationModal(TypeInformationModal.ERROR, "Error al guardar los cambios realizados, Intenta nuevamente en unos minutos.");
            listContactSinUuid.splice(0, this.editContactsList.length);
            this.editContactsList.splice(0, this.editContactsList.length);
            this.invalidContactAcept = false;
            this.typeOperation = 'list';
            this.activeSearch = false;
            this.activateContactSearch = false;
          }
        });
      }else if(this.newContactsList.length != this.nAddContact){
        this.incomplete = true;
        this.informationModal(TypeInformationModal.WARNING, "No se puede guardar la operación. Debe completar los campos requeridos");
      }

      //para evitar el doble-click
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);
    }
  }

  public cancel() {
    this.dialog.open(ConfirmationComponent, {
      width: '500px',
      data: {
        description: '¿Está seguro que no desea guardar la información?',
        okText: 'Aceptar',
        cancelText: 'Cancelar'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        if((this.newContactsList.length > 0) || (this.nAddContact > 0)) {
          this.listContacts.splice(0, this.nAddContact);
          this.newContactsList.splice(0, this.newContactsList.length);
          this.nAddContact = 0;
          this.newRecords = false;
          this.invalidContactAcept = false;
          this.getContacts();
        }else if(this.editContactsList.length > 0 || this.typeOperation == 'edit'){
          this.editContactsList.splice(0, this.editContactsList.length);
          this.invalidContactAcept = false;
          this.typeOperation = 'list';
          this.getContacts();
        }
      }
    })
  }
  // modal de notificacion
  private informationModal(typeInfo: TypeInformationModal, text: string, buttonText: string = 'Aceptar') {
    const dialogRef = this.dialog.open(InformationModalComponent, {
      width: '500px',
      data: {
        description: text,
        type: typeInfo
      }
    });
  }

  //Validar valor numerico
  private isNumber(str: string): boolean {
    let nuevoValor = str.trim();
    let patternNum = /^[0-9]+$/;

    if (nuevoValor.trim() === '') {
      return false;
    }
    if (!patternNum.test(nuevoValor)){
      return false;
    }else{
      return true;
    }
  }


  showTooltip(e: any) {
    const offsetWidth = e.target.offsetWidth;
    const scrollWidth = e.target.scrollWidth;
    if (offsetWidth < scrollWidth) {
      this.tooltip_title.width = `${scrollWidth / 2}px`;
      this.tooltip_title.show();
    }
  }
  hideTooltip() {
    this.tooltip_title?.hide();
  }










/*

  // cuando se incrementa el valor en los numeros del agregar
  public agregarContacto(countContactos: number){

    const fechaActual = new Date();
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1; // Los meses empiezan en 0, por lo que se le suma 1
    const anio = fechaActual.getFullYear();

    let fecha = `${dia}-${mes}-${anio}`;

    if(this.tipoOperacion == 'edit'){
      this.informationModal(TypeInformationModal.WARNING, "Porfavor, termine la acción de Editar Contactos para agregar un Nuevo Contacto");
    }else if(this.tipoOperacion = 'lista'){
      for (let index=0; index < countContactos; index++){
        let contact: Contact = {
          uuid : (<any>crypto).randomUUID(),
          companyName: 'Nombre Empresa',
          contactAddress: [],
          contactLastName: '',
          contactMotherlastName: '',
          contactName: '',
          cu: '',
          email: '',
          idContact: NaN,
          phoneOne: '',
          phoneTwo: '',
          position: '',
          ruc: '',
          typeDoc: NaN,
          updateDate: fecha,
          isNew: true,
          customer: {
            idCustomer: {
              idDocumentType: NaN,
              documentNumber: ''
            }
          }
        }
        if(this.listaContactosSearch.length == 0) {
          this.listaContactos.unshift(contact);
        } else if (this.listaContactosSearch.length > 0) {
          this.listaContactosSearch.unshift(contact);
        }

      }
      this.agregarRegistros = true;
      this.numContactosAgregados = this.numContactosAgregados + countContactos;
      this.incomplete = true;
    }
  }

  // cuando se hace click en el editar
  public edit(){
    if(this.agregarRegistros == true){
      this.informationModal(TypeInformationModal.WARNING, "Porfavor, termine la acción de Agregar Contactos para editar un contacto");
    }else{
      this.tipoOperacion = 'edit'
    }
  }
  // buscar contacto
  public buscarContacto(value:string){
    let uniqueCodeSearch = '';
    let businessNameSearch = '';
    let rucSearch = '';
    let cantMinCaracter = 3;

    let nuevoValor = value.trim();
    let numberValidate: boolean = this.isNumber(nuevoValor);

    if (numberValidate && (nuevoValor.length < 10 || nuevoValor.length > 11)) {
      this.informationModal(TypeInformationModal.WARNING, "Los datos ingresados no son correctos");
      return;
    }else if( nuevoValor != "" && !numberValidate && (nuevoValor.length < cantMinCaracter)){
      this.informationModal(TypeInformationModal.WARNING, "Para buscar por Empresa, requiere minimo 3 caracteres");
      return;
    }

    if (nuevoValor.length == 0 ){
      this.valueSearch = '';
      this.listaContactosSearch = [];
      this.tipoOperacion = "lista"
      this.busquedaActiva = false;
      this.getContacts();
    }

    if (nuevoValor.length <= 10 && numberValidate) {
      let ceros = "";
      for (let i = 0; i < 10 - nuevoValor.length; i++) {
        ceros += "0";
      }
      uniqueCodeSearch = ceros + nuevoValor;
      this.valueSearch = uniqueCodeSearch;
    } else if (nuevoValor.length == 11 && numberValidate) {
      rucSearch = nuevoValor;
      this.valueSearch = rucSearch;
    } else {
      businessNameSearch = nuevoValor;
      this.valueSearch = businessNameSearch
    }

    this.search(this.valueSearch);

  }

  private search(value:string) {
    let body = {
      "search": value,
      "userRegistration": this.user,
      "limit":'500',
      "offset":'0'
    }
    this.loading = true;
    this.contactService.getContact(body).subscribe( {
      next: (contactos) => {
        this.listaContactosSearch = contactos.listContact.map((contacto:Contact) => this.setMissingProperties(contacto));
        this.busquedaActiva = true;
        this.setUUIDToContact();
        this.loading = false;
      },
      error: (error) => {
        this.busquedaActiva = false;
        this.informationModal(TypeInformationModal.ERROR, "Error al realizar la busqueda, Intenta nuevamente en unos minutos");
        this.loading = false;
      }
    });

  }

  //obtener el nombre de la empresa, su ruc
  public obtenerCompany(contact: any){
    let body = {
      "type": null,
      "uniqueCode": contact.contactCu
    }

    return this.contactService.getCustomerWithUniqueCode(body).pipe(
      map( (response) => {
        if(response.mensaje){
          //cuandono se encuentra registrado
          this.CuRucInvalid = true;
          this.informationModal(TypeInformationModal.WARNING, "El Cu/Ruc ingresado no se encuentra registrado");
        }else{
          this.CuRucInvalid = false;
          return response;
        }
      })
    )
  }

  // actualizar contacto
  public contactUpdate(contact: Contact){
    //TODO: aca tambien si el formulario no cumple con lo valido poner en true invalidContact
    contact.invalidFormAcept == true ? this.invalidContactAcept = true : this.invalidContactAcept = false;
    contact.invalidForm == true || this.CuRucInvalid == true ? this.incomplete = true : this.incomplete = false;

    let user = this.userService.user
    if(contact.isNew){
      let contactNew = {
        uuid: contact.uuid,
        flag: 'I',
        idContact: null,
        contactName: contact.contactName,
        phoneOne: contact.phoneOne,
        phoneTwo: contact.phoneTwo,
        emailOne: contact.email,
        position: contact.position,
        userIdRegistration: user,
        userIdLastUpdate: user,
        customer: contact.customer
      }
      //TODO: si en el agregar un nuevo registro modifican los valores se tiene que hacer lo mismo que el editar

      if(contact.uuid && contact.contactName){
        if((this.nuevosContactos.length <= this.numContactosAgregados)){
          let indiceContactoNuevo = this.nuevosContactos.findIndex(contacto => contacto.uuid == contact.uuid);
          if(indiceContactoNuevo != -1){
            this.nuevosContactos[indiceContactoNuevo] = contactNew;
          }else{
            this.nuevosContactos.push(contactNew);
          }
        }

      }
    } else if (this.tipoOperacion == 'edit') {
      if (contact.idContact){
        let contactEdit = {
          uuid: contact.uuid,
          flag: 'A',
          idContact: contact.idContact,
          contactName: contact.contactName,
          phoneOne: contact.phoneOne,
          phoneTwo: contact.phoneTwo,
          emailOne: contact.email,
          position: contact.position,
          userIdRegistration: user,
          userIdLastUpdate: user,
          customer: contact.customer
        }

        if(contact.uuid){
          let indiceContactoEditado = this.editarContactos.findIndex((contactItem => contactItem.uuid == contact.uuid));
          if(indiceContactoEditado != -1){
            this.editarContactos[indiceContactoEditado] = contactEdit;
          }else{
            this.editarContactos.push(contactEdit);
          }

        }
      }
    }
  }

  // boton de guardar
  public save(){
    this.isButtonDisabled = true;
    if(this.nuevosContactos.length == 0 && this.tipoOperacion == 'lista'){
      this.informationModal(TypeInformationModal.WARNING, "No se puede guardar la operación. Debe completar los campos requeridos");
    }else if(this.editarContactos.length == 0 && this.tipoOperacion == 'edit'){
      this.informationModal(TypeInformationModal.WARNING, "No se ha modificado contactos");
    }else{
      if(this.nuevosContactos.length == this.numContactosAgregados && this.agregarRegistros) {
        let listaContactosSinUuid = this.nuevosContactos.map(contacto => {
          const { uuid, ...restoDelContacto } = contacto;
          return restoDelContacto;
        });

        this.contactService.saveContact(listaContactosSinUuid).subscribe({
          next: (contactosAdd) => {
            this.informationModal(TypeInformationModal.SUCCESS, `Se guardó correctamente el/los contacto/s (${this.numContactosAgregados}).`);
            this.listaContactos.splice(0, this.numContactosAgregados);
            this.nuevosContactos.splice(0, this.numContactosAgregados);
            listaContactosSinUuid.splice(0, this.numContactosAgregados);
            this.numContactosAgregados = 0;
            this.agregarRegistros = false;
            this.invalidContactAcept = false;
            this.getContacts();
          },
          error: (error) => {
            this.informationModal(TypeInformationModal.ERROR, "Error al guardar el/los contacto/s, Intenta nuevamente en unos minutos.");
            listaContactosSinUuid.splice(0, this.numContactosAgregados);
            this.listaContactos.splice(0, this.numContactosAgregados);
            this.nuevosContactos.splice(0, this.numContactosAgregados);
            this.numContactosAgregados = 0;
            this.agregarRegistros = false;
            this.invalidContactAcept = false;
          }
      });
      }else if( this.tipoOperacion == 'edit'){

        let listaContactosSinUuid = this.editarContactos.map(contacto => {
          const { uuid, ...restoDelContacto } = contacto;
          return restoDelContacto;
        });
        this.contactService.saveContact(listaContactosSinUuid).subscribe({
          next: (contactosEditados) => {
            this.informationModal(TypeInformationModal.SUCCESS, `Se actualizó correctamente el/los contacto/s (${this.editarContactos.length}).`);
            listaContactosSinUuid.splice(0, this.editarContactos.length);
            this.editarContactos.splice(0, this.editarContactos.length);
            this.invalidContactAcept = false;
            this.tipoOperacion = 'lista';
            //
            this.busquedaActiva == true ? this.search(this.valueSearch) : this.getContacts();


          },
          error: (error) => {
            this.informationModal(TypeInformationModal.ERROR, "Error al guardar los cambios realizados, Intenta nuevamente en unos minutos.");
            listaContactosSinUuid.splice(0, this.editarContactos.length);
            this.editarContactos.splice(0, this.editarContactos.length);
            this.invalidContactAcept = false;
            this.tipoOperacion = 'lista';
            this.busquedaActiva = false;
          }
        });
      }else if (this.nuevosContactos.length != this.numContactosAgregados){
        this.incomplete = true;
        this.informationModal(TypeInformationModal.WARNING, "No se puede guardar la operación. Debe completar los campos requeridos");
      }
      if(this.listaContactosSearch.length > 0){
        this.busquedaActiva = true;
        this.search(this.valueSearch)
      }

      //para evitar el doble-click
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);

    }

  }

  // boton de cancelar
  public cancel() {
    this.dialog.open(ConfirmationComponent, {
      width: '500px',
      data: {
        description: '¿Está seguro que no desea guardar la información?',
        okText: 'Aceptar',
        cancelText: 'Cancelar'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        if((this.nuevosContactos.length > 0) || (this.numContactosAgregados > 0)) {
          this.listaContactos.splice(0, this.numContactosAgregados);
          this.nuevosContactos.splice(0, this.nuevosContactos.length);
          this.numContactosAgregados = 0;
          this.agregarRegistros = false;
          this.invalidContactAcept = false;
          this.getContacts();
        }else if(this.editarContactos.length > 0 || this.tipoOperacion == 'edit'){
          this.editarContactos.splice(0, this.editarContactos.length);
          this.invalidContactAcept = false;
          this.tipoOperacion = 'lista';
          this.busquedaActiva ?
            (this.search(this.valueSearch), this.busquedaActiva = true) :
            (this.getContacts(), this.busquedaActiva = false);
        }
      }
    });


  }

  // eliminar contacto
  public remove(contactDelete: any){
    this.dialog.open(ConfirmationComponent, {
      width: '500px',
      data: {
        description: '¿Estás seguro(a) que desea eliminar contacto?',
        okText: 'Eliminar',
        cancelText: 'Cancelar'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        if(contactDelete.modo == 'agregado'){
          let listaContactosAux = this.listaContactos.slice(0, this.numContactosAgregados);
          this.listaContactos.splice(contactDelete.indice, 1);
          this.nuevosContactos.splice(contactDelete.indice, 1);
          listaContactosAux.splice(contactDelete.indice, 1);
          this.numContactosAgregados = this.numContactosAgregados - 1;
          this.nuevosContactos.length > 0 || listaContactosAux.length > 0 ? this.agregarRegistros = true : this.agregarRegistros = false;
          this.invalidContactAcept == true
          ? (this.nuevosContactos.length == 0 || listaContactosAux.length == 0)
            ? this.invalidContactAcept = false
            :this.invalidContactAcept = true
          : this.invalidContactAcept = false

        }else{
          let user = this.userService.user
          let contactoEliminar = {
            userIdLastUpdate: user,
            idContact:contactDelete.idContact
          }
          this.contactService.deleteContact(contactoEliminar).subscribe( data => {


            if(contactDelete.busqueda == false){
              let elementIndex = this.listaContactos.findIndex((contactItem => contactItem.idContact == contactDelete.idContact));
              this.listaContactos.splice(elementIndex, 1);
            }else{
              let elementIndex = this.listaContactosSearch.findIndex((contactItem => contactItem.idContact == contactDelete.idContact));
              this.listaContactosSearch.splice(elementIndex, 1);
            }

            if(this.tipoOperacion == 'edit'){
              let elementIndex = this.editarContactos.findIndex((contactItem => contactItem.idContact == contactDelete.idContact));
              if(elementIndex != -1){
                this.editarContactos.splice(elementIndex, 1);
              }
            }
          });


        }

        this.informationModal(TypeInformationModal.SUCCESS, "Se elimino el contacto correctamente");
      }
    });
  }

  */

}

