import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {MessageService} from 'src/app/services/message.service';
import {Router} from "@angular/router";
import { SecurityService } from 'src/app/services/security.service';
import { MenuDTO, SubMenuDTO } from 'src/app/models/menu.dto';
import { AccessModuleEnum } from '../core/enums/access.module.enum';
import { BaseComponent } from '../core/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent extends BaseComponent implements OnInit {
  transition: boolean = true;
  backOn: boolean = false;
  background: boolean;
  menuItems: MenuDTO[] = [];
  flsGestor: boolean = false;
  flgEjecutivo: boolean = false;

  selectedItem:any = null;
  constructor(
    private authservice: AuthService,
    private messageService: MessageService,
    private router: Router,
    private readonly securityService: SecurityService
  ) {

    super();
  }

  public get AccessModuleEnum(): typeof AccessModuleEnum {
    return AccessModuleEnum;
  }

  ngOnInit() {
    this.getMenu();
    const accessStorage = localStorage.getItem("access");
    if (accessStorage) {
      const access: MenuDTO[] = JSON.parse(accessStorage || null!) || [];
      this.securityService.setMenuItems(access);
    }

  }

  getMenu() {
    this.securityService.getMenuItems()
    .pipe(takeUntil(this.destroy$))
      .subscribe( (menuItems:any) => {
        this.menuItems = menuItems;
      });
  }


  home(){
    this.router.navigate(['/dashboard'])
  }

  transi(subMenu: SubMenuDTO) {
    this.selectedItem = null;
    if (subMenu.subOp?.length > 0) {
      subMenu.transition = !subMenu.transition;
    } else {
      this.router.navigate([subMenu.path]);
    }
    this.selectedItem = subMenu.name;
  }

  panelOpenState = false;

  logout() {
    this.authservice.logOut()
      .subscribe((resp: any) => {
        this.messageService.showInfo(resp.mensaje, 'top right');
      });
  }
}
