import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OperationService } from 'src/app/services/operation.service';
import { rankingList, rankingFailed, rankingSuccess } from "./ranking.actions";

@Injectable()
export class RankingEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly operationService: OperationService
    ) { }

    getRanking$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rankingList),
            mergeMap((action) =>
                this.operationService.getRanking(action.payload)
                    .pipe(
                        map((data) => {
                            return rankingSuccess({ payload: data });
                        }),
                        catchError((error) => of(rankingFailed(error)))
                    )
            )
        )
    )

}
