import { createReducer, on} from "@ngrx/store";
import { newList, newFailed, newSuccess, newReset } from "./new.actions";
import { NewStateModel } from "./new.model";

export const initialState: NewStateModel = {
    data: null,
    loading: false,
    failed: false
};

const _newReducer = createReducer(initialState,
    on(newList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(newFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(newSuccess, (state, {payload}) => {
        const previousData = state.data?.operaciones || [];
        const newData = payload?.operaciones || [];
        const data = [...previousData, ...newData];

        return {
            ...state,
            data: {
                ...payload,
                operaciones: data
            },
            loading: false,
            failed: false
        }
    }),
    on(newReset, state => state = initialState)
);

export function newReducer(state: any, action: any): NewStateModel {
    return _newReducer(state, action);
}
