import { createAction, props } from "@ngrx/store";
import { InstructiveProductsDTO } from "src/app/pages/instructive/models";

export const instructiveProductsList = createAction('[Instructive Products] List',
    props<{idCampaign: number | null}>()    
);
export const instructiveProductsFailed = createAction('[Instructive Products] Failed',
    props<{payload: any}>()
);

export const instructiveProductsSuccess = createAction('[Instructive Products] Success',
    props<{payload: InstructiveProductsDTO[]}>()
);

export const instructiveRemoveProduct = createAction('[Instructive Products] Remove Product',
    props<{idElementFormat: number}>()    
);

export const instructiveUpdateProduct = createAction('[Instructive Products] Update Product',
    props<{idElementFormat: number, name: string}>()    
);

export const instructiveProductReset = createAction(
    '[Instructive Products] Reset'
);
