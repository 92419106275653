<div class="container">
    <div class="header">
        <div class="contacts">
            <div class="contactsTitle">
                <h1> Contactos</h1>
            </div>
        </div>

        <div class="input border-button" >
            <mat-form-field  class="mat-form-field-sinlinea">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="Busca aquí tu cliente"
                [(ngModel)]="terminoChildAux" 
                (keyup.enter)="searchContactParent(txtTermino.value)"
                #txtTermino
                autocomplete="off">
            </mat-form-field>
        </div>
        <p></p>
        <button mat-stroked-button class="border-button" [matMenuTriggerFor]="menubutton">
            <mat-icon class="green">format_list_bulleted</mat-icon>
        </button>
        <mat-menu #menubutton="matMenu" class="mat-menu-view-contact">
            <button mat-menu-item (click)="goToListContacts()">
                <mat-icon class="green">format_list_bulleted</mat-icon>
            </button>
            <button mat-menu-item>
                <mat-icon class="reverse-icon">bar_chart_outlined</mat-icon>
            </button>
        </mat-menu>
        
        
    </div>

    
    <div fxLayout="row">
        <div fxFlex="20%">
            <mat-divider style="width:100%"></mat-divider>
            <div fxLayout="row">
                Empresa
            </div>
            <div fxLayout="row">
                CU
            </div>
            <mat-divider style="width:100%"></mat-divider>
            
            <div fxLayout="row" class="mat-card-center" *ngFor="let enterprise of enterpriseList">
                <div class="div-contact-list"  fxFlex="90%" >
                    <mat-card [ngClass]="enterpriseSelect.cu == enterprise.cu ? 'mat-card-contact mat-card-contact-select' : 'mat-card-contact'"
                    (click)="selectEnterprise(enterprise)">
                        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100%">
                            <div fxFlex="90%" class="letter-possition-contact">
                                {{enterprise.companyName}}
                                <br><div>{{enterprise.cu}}</div>
                            </div>
                            <div fxFlex="10%"class="mat-icon-delete">
                                <button mat-icon-button>
                                    <mat-icon class="green">keyboard_arrow_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>

        </div>

        <div fxFlex="80%" class="div-border-list-contact" *ngIf="enterpriseSelect.lisContacts && enterpriseSelect.lisContacts?.length>0">
                <app-list-contact-double #listContactDouble [enterpriseSelect]="enterpriseSelect" [contactSelect]="contactSelect">
                </app-list-contact-double>
        </div>



    </div>

    
</div>