import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { OperationGetRequest } from "src/app/pages/enote-config/pages/enote/models";
import { fallsList, fallsReset, fallsCancel, fallsRemoveItemSuccess } from "./falls.actions";
import { selectFallsOperations, selectFallsOperationsLoading } from "./falls.selectors";

@Injectable()
export class FallsFacade {

    constructor(private readonly store: Store) {}
    
    getFalls(request: OperationGetRequest) {
        this.store.dispatch(fallsList({ payload: request }));
    }

    reset() {
        this.store.dispatch(fallsReset());
    }

    cancel(data: any) {
        this.store.dispatch(fallsCancel({ payload: data }));
    }

    selectFallsOperations() {
        return this.store.select(selectFallsOperations);
    }

    selectFallsOperationsLoading() {
        return this.store.select(selectFallsOperationsLoading);
    }

    removeItem(id: number) {
        this.store.dispatch(fallsRemoveItemSuccess({ id }));
    }

}