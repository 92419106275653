import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FINANCIAL_FEATURE_KEY, FinancialEffects, FinancialReducerModule } from './financial.store';
import { CoreModule } from 'src/app/shared/core/core.module';
import { FinancialEeffFacade } from './financial-eeff/financial-eeff.facade';
import { FinancialService } from 'src/app/services/financial.service';
import { FinancialNewFacade } from './financial-new/qualitative-new.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    StoreModule.forFeature(FINANCIAL_FEATURE_KEY, FinancialReducerModule),
    EffectsModule.forFeature(FinancialEffects)
  ],
  providers: [
    FinancialService,
    FinancialEeffFacade,
    FinancialNewFacade
  ]
})
export class FinancialStoreModule { }
