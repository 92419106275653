import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ClientSettingsService } from 'src/app/services/client-settings.service';
import {
    familyClientFailed,familyClientList,familyClientSuccess
} from './family-client-list.actions';

@Injectable()
export class FamilyClientListEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly clientSettingsService: ClientSettingsService
    ) { }

    getFamilyClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(familyClientList),
            mergeMap((action) => {
                const request = {
                };
                return this.clientSettingsService.getFamilyClients(request)
                    .pipe(
                        map((data) => {
                            return familyClientSuccess({ payload: data });
                        }),
                        catchError((error) => of(familyClientFailed(error)))
                    )
            })
        )
    )

}
