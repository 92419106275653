import { createReducer, on} from "@ngrx/store";
import { resumeJefeList, resumeJefeFailed, resumeJefeSuccess, resumeJefeReset } from "./resume-jefe.actions";
import { ResumeJefeStateModel } from "./resume-jefe.model";

export const initialState: ResumeJefeStateModel = {
    data: {},
    loading: false,
    failed: false
};

const _resumeJefeReducer = createReducer(initialState,
    on(resumeJefeList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(resumeJefeFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(resumeJefeSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false
        }
    }),
    on(resumeJefeReset, state => state = initialState)
);

export function resumeJefeReducer(state: any, action: any): ResumeJefeStateModel {
    return _resumeJefeReducer(state, action);
}
