import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { AutocompleteSearchServiceInterface } from 'src/app/interfaces/autocomplete-search.service.interface';
import { AutocompleteSearchModel } from 'src/app/models/autocomplete-search.model';
import { BaseComponent } from 'src/app/shared/core/base/base.component';
import { SearchComponent } from '../search/search.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent extends BaseComponent {

  @ViewChild("search") searchComponent: SearchComponent;
  @Input() placeholder: string = 'Busca aquí por CU, RUC o empresa...';
  @Input() cleanSelectItem: boolean = true;
  @Output() selectedItem = new EventEmitter<AutocompleteSearchModel | null>();

  open: boolean = false;
  loading: boolean = false;
  dataSource: AutocompleteSearchModel[] = [];
  text: string;
  error: boolean = false;

  constructor(@Inject("SearchServiceInterface") private readonly searchServiceInterface: AutocompleteSearchServiceInterface) {
    super();
   }

  filter(value: string) {
    this.text = value;
    if (value) {
      this.dataSource = [];
      this.loading = false;
      this.getData();
    }

    if (!this.cleanSelectItem && !value) {
      this.dataSource = [];
      this.selectedItem.emit(null);
    }
  }

  onWriting(value: string) {
    this.text = value;
    this.open = true;
    this.loading = true;
    this.error = false;
    if (!value) {
      this.dataSource = [];
    }
  }

  onFocus() {
    this.open = true;
  }

  onFocusOut() {
    setTimeout(() => this.open = false, 350);
  }

  getData() {
    this.loading = true;
    this.dataSource = [];
    this.error = false;
    this.searchServiceInterface.search(this.text)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.dataSource = response || [];
          this.error = this.dataSource.length == 0;
          if (this.error) {
            this.searchComponent.text.setValue('');
            this.searchComponent.clearFocus();
          }
        },
        error: () => this.loading = false
      })
  }

  setItem(item: AutocompleteSearchModel) {
    this.selectedItem.emit(item);
    if (this.cleanSelectItem) {
      this.text = '';
      this.searchComponent.text?.setValue('');
      this.dataSource = [];
    }
  }

}
