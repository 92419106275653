import { createAction, props } from "@ngrx/store";

export const lossesList = createAction('[Losses] List',
    props<{payload: any}>()
);
export const lossesFailed = createAction('[Losses] Failed',
    props<{payload: any}>()
);
export const lossesSuccess = createAction('[Losses] Success',
    props<{payload: any}>()
);

export const lossesReset = createAction(
    '[Losses] Reset'
);

