import { AbstractControl } from "@angular/forms";

export const disallowEmptySpaceValidator = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const value = control.value;
    if (value) {
        if (value.length == 1 && value == ' ') {
            const val = value.substring(0, value.length - 1);
            control.setValue(val, { emitEvent: false });
        }
    }
    return null;
};
