import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'capitalize'
})

export class CapitalizePipe implements PipeTransform {
  transform(value: string, onlyFirstLetter: boolean = false): string {
    if (!value) return '';

    if (onlyFirstLetter) {
      return value.trim().charAt(0).toUpperCase() + value.trim().slice(1).toLowerCase()
    }

    return value.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }
}
