import { createAction, props } from "@ngrx/store";

export const clientList = createAction('[Client] List',
    props<{limit: number, offset: number, userRegistration:string, ruc:any
        , businessName:any, uniqueCode:any, order:number, orderVariable:any}>()
);

export const clientFailed = createAction('[Client] Failed',
    props<{payload: any}>()
);
export const clientSuccess = createAction('[Client] Success',
    props<{payload: any}>()
);

export const clientReset = createAction('[Client] Reset');
