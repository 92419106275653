import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { familyClientList } from "./family-client-list.actions";
import { selectFamilyClientData } from "./family-client-list.model";

@Injectable()
export class FamilyClientFacade {

    constructor(private readonly store: Store) {}

    getProducts() {
        this.store.dispatch(familyClientList());
    }

    selectFamilyClientData() {
        return this.store.select(selectFamilyClientData);
    }

}
