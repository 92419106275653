import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { InstructiveGetDTO } from "src/app/pages/instructive/models";
import { instructiveList, instructiveReset } from "./instructive.actions";
import { selectInstructive } from "./instructive.selectors";

@Injectable()
export class InstructiveFacade {

    constructor(private readonly store: Store) {}
    
    getInstructive(request: InstructiveGetDTO) {
        this.store.dispatch(instructiveList({ payload: request }));
    }

    selectInstructive() {
        return this.store.select(selectInstructive);
    }

    reset() {
        this.store.dispatch(instructiveReset());
    }

}