import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { QualitativeStateModel } from './qualitative-list/qualitative.model';
import { qualitativeReducer } from './qualitative-list/qualitative.reducer';
import { QualitativeEffects } from './qualitative-list/qualitative.effects';
import { QualitativeNewEffects } from './qualitative-new/qualitative-new.effects';
import { QualitativeValidationStepsStateModel } from './qualitative-new/qualitative-new.model';
import { qualitativeNewReducer } from './qualitative-new/qualitative-new.reducer';

export const QUALITATIVE_FEATURE_KEY = 'qualitative';

export const selectQualitativeState = createFeatureSelector<QualitativeState>(
  QUALITATIVE_FEATURE_KEY
);

export const QualitativeListEffects: any[] = [
  QualitativeEffects,
  QualitativeNewEffects
];

export interface QualitativeState {
  qualitative: QualitativeStateModel;
  validationStep: QualitativeValidationStepsStateModel;
}

export const QualitativeReducerModule: ActionReducerMap<QualitativeState> = {
  qualitative: qualitativeReducer,
  validationStep: qualitativeNewReducer
};
