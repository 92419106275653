import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { paramsDataList } from "./params.actions";
import { selectParamsData } from "./params.selectors";

@Injectable()
export class ParamsFacade {

    constructor(private readonly store: Store) {}
    
    getParams(tableId: number) {
        this.store.dispatch(paramsDataList({ id: tableId }));
    }

    selectParamsData(tableId: number) {
        return this.store.select(selectParamsData(tableId));
    }

}