import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';
import { SessionStorageService } from '../core/services/session.storage.service';
import { LocalStorageService } from '../core/services/local.storage.service';
import { USER_INFO } from '../core/constants/local.storage.constants';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly securityService: SecurityService,
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user) {
      const paths = this.securityService.getMenuPath();
      const exist = paths.some((item) =>
        state.url?.trim()?.includes(item.path?.trim())
      );
      
      if ((state.url?.trim() == '/dashboard' && paths.length == 0) || (state.url?.trim() != '/dashboard' && !exist)) {
        this.router.navigate(['/sin-acceso']);
        return false;
      }
    } else {
      this.localStorageService.clear();
      this.sessionStorageService.clear();
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  get user() {
    let user: string = '';
    try {
      user = this.localStorageService.getItem(USER_INFO) ?? ''
    } catch (error) {
      user = '';
    }
    return user;
  }
}
