import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { ContactsService } from 'src/app/services/contacts.service';
import { CustomerContactRequestDTO } from '../models/customer-contact.request.dto';
import { ParamsFacade } from 'src/app/store/params/state/params/params.facade';
import { ParametersEnum } from 'src/app/shared/core/enums/parameters.enum';
import { CustomerContactUniverseCreateDTO } from '../models/customer-contact-universe-create.dto';

@Injectable()
export class ContactsFacadeService {

  selectedHierarchyUser: string | null;
  positions$ = this.paramsFacade.selectParamsData(ParametersEnum.positions);

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly contactsService: ContactsService,
    private readonly paramsFacade: ParamsFacade
  ) { }

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  addAndRemovePriority(favorite: boolean, contactId: number) {
    return this.contactsService.favoriteContact({ favorite, idContact: contactId });
  }

  deleteContact(body: any) {
    return this.contactsService.deleteContact(body);
  };

  saveContact(body: CustomerContactUniverseCreateDTO) {
    return this.contactsService.saveContact(body);
  }

  getContact(body: any) {
    return this.contactsService.getContact(body);
  };

  getCustomerWithUniqueCode(body: any) {
    return this.contactsService.getCustomerWithUniqueCode(body);
  };

  getContactsByEnterprises(body: any) {
    return this.contactsService.getContactsByEnterprises(body);
  };


  getListContactsByEnterprises(body: any){
    return this.contactsService.getListContactsByEnterprises(body);
  }

  getContactTypeUniverse(body: any){
    return this.contactsService.getContactTypeUniverse(body);
  }

  getPositions() {
    this.paramsFacade.getParams(ParametersEnum.positions);
  }

  getCustomerContact(request: CustomerContactRequestDTO) {
    return this.contactsService.getCustomerContact(request);
  }

  getContactUniverse(request: any) {
    return this.contactsService.getContactUniverse(request);
  }
};
