
<div class="customer-contact-list">
    <div class="customer-contact-list__title">
        <h1>Contactos</h1>
    </div>
    <div class="customer-contact-list__actions">
        <app-search
            placeholder="Busca aquí por Ruc, Nombre o Código único"
            (search)="search($event)">
        </app-search>
        <!-- <div class="customer-contact-list__actions-filterhierarchy"
          *ngIf="!isExecutive"
          [ngClass]="{'disabled-filter': loading}" (click)="openFilter = true">
          <div class="customer-contact-list__actions-filterhierarchy_content">
            <img src="/assets/img/filter.svg" alt="filter">
          </div>
        </div>
        <app-vertical-modal 
          [ngClass]="{'display-none': !openFilter}"
          title="Filtros"
          (close)="openFilter = false"
        >
          <app-hierarchy
            (sendFilter)="filterHierarchy($event)"
            (firstHierarchiesLoaded)="setFirstHierarchy($event)">
          </app-hierarchy>
        </app-vertical-modal> -->
    </div>
</div>

<div class="customer-contact-list__table">
    <app-loading [loading]="loading"></app-loading>
    <app-table
      #table
      [headers]="tableHeaders"
      [data]="customerContactList"
      [showEmpty]="!loading"
      [headerTemplate]="headerTemplate"
      [bodyTemplate]="bodyTemplate"
      [expandTemplate]="expandTemplate"
      tableHeight="calc(100vh - 103px)"
      [hasScroll]="true"
      [loading]="loading"
      [totalPages]="totalPages"
      [currentPage]="currentPage"
      (onScrolling)="onScroll()">
    </app-table>
     
    <ng-template #headerTemplate let-header>
      <ng-container [ngSwitch]="header.name">
          <ng-container *ngSwitchCase="'enterpriseName'">
              <div class="color-text-color-default">
                  {{ header.label }}
              </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
              <div class="width-100-per text-align-center color-text-color-default">
                {{ header.label }}
              </div>
          </ng-container>
      </ng-container>
    </ng-template>
     
    <ng-template #bodyTemplate let-cell>
      <ng-container [ngSwitch]="cell.column">
        <ng-container *ngSwitchCase="'enterpriseName'">
          <app-tooltip-text 
              [top]="'-40px'"
              [right]="'auto'"
              [width]="'300px'"
              [cssColorDescription]="'color-primary'"
              [description]="cell.value"
              [textHover]="true"
              (clickDescription)="expandir(cell.index)">
          </app-tooltip-text>
        </ng-container>
        <ng-container *ngSwitchCase="'uniqueCode;documentNumber'">
            <div class="display-flex flex-direction-column text-align-center">
              <span>{{cell.value[0]}}</span>
              <span>{{cell.value[1]}}</span>
            </div>
          </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="text-align-center">
                {{ cell.value}}
            </div>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #expandTemplate let-row>
      <app-contact-list-detail 
        [documentNumber]="row.data.documentNumber" 
        [documentType]="row.data.documentType"
        (onClose)="expandir(row.index)"
        (onRefresh)="refreshItem(row.index)">
      </app-contact-list-detail>
    </ng-template>
</div>
