import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { hierarchyList, hierarchyReset } from "./hierarchy-list.actions";
import { selectHierarchyData } from "./hierarchy-list.model";
import { HierarchyRequestDTO } from "src/app/models";

@Injectable()
export class HierarchyListFacade {

    constructor(private readonly store: Store) {}
    
    getHierarchy(request: HierarchyRequestDTO) {
        this.store.dispatch(hierarchyList({ request }));
    }

    reset() {
        this.store.dispatch(hierarchyReset());
    }

    selectHierarchy() {
        return this.store.select(selectHierarchyData);
    }

}