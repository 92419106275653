import { createAction, props } from "@ngrx/store";
import { DocumentsTypeDTO, DocumentsTypeGetDTO, EscomFilterDTO } from "src/app/pages/escom-list/models";

export const documentsTypeList = createAction('[Escom] DocumentsType List',
    props<{request: DocumentsTypeGetDTO}>()    
);
export const documentsTypeFailed = createAction('[Escom] DocumentsType Failed',
    props<{payload: any}>()
);

export const documentsTypeSuccess = createAction('[Escom] DocumentsType Success',
    props<{payload: DocumentsTypeDTO[], idTableRef: string, idItemRef: string}>()
);

export const documentsTypeReset = createAction(
    '[Escom] DocumentsType Reset'
);

export const documentTypeItemExpanded = createAction('[Escom] DocumentsType Item Expanded',
    props<{documentType: DocumentsTypeDTO, expanded: boolean}>()    
);

export const subDocumentsTypeList = createAction('[Escom] Sub DocumentsType List',
    props<{request: DocumentsTypeGetDTO}>()    
);
export const subDocumentsTypeFailed = createAction('[Escom] Sub DocumentsType Failed',
    props<{payload: any}>()
);

export const subDocumentsTypeSuccess = createAction('[Escom] Sub DocumentsType Success',
    props<{idTableFatherRef: string, idItemFatherRef: string, payload: DocumentsTypeDTO[]}>()
);

export const documentCreateSuccess = createAction('[Escom] DocumentsType Create Success',
    props<{request: EscomFilterDTO}>()    
);
