<div class="ibk-progress-bar" [ngClass]="{'download': download}">
    <span class="progress-text"
        [style.color]="color">
        {{value | number: '1.0-2'}}%
    </span>
    <div
        class="ibk-progress-bar__body"
        [ngClass]="{'full': value == 100}"
        [style.width]="value + '%'"
        [style.background-color]="bgColor"></div>
</div>
<div class="ibk-progress-bar__text" [style.color]="color">
    {{value | number: '1.0-2'}}%
</div>
