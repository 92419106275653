import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { checkedMinLengthArray } from 'src/app/shared/ui/validators/array-checked-min-length.validator';
import { inputOnlyNumbersValidator } from 'src/app/shared/ui/validators/input-number-validator';
import { inputValuesValidator } from 'src/app/shared/ui/validators/input-values-validator';
import { PeriodValidator } from '../validators/period.validator';
import { inputAlphaNumericValidator } from 'src/app/shared/ui/validators/input-alphaNumeric.validator';
import { disallowEmptySpaceValidator } from 'src/app/shared/ui/validators/input-disallow-empty-space.validator';
import { ConfigurationCampaignFacade } from 'src/app/store/campaign/state/configuration/configuration.facade';
import { InputsStatesFacade } from 'src/app/store/campaign/state/inputs-states/inputs-states.facade';

@Injectable()
export class CampaignService {
  public firstStepCompleted: boolean = false;
  public secondStepCompleted: boolean = false;
  public thirdStepCompleted: boolean = false;
  public fourthStepCompleted: boolean = false;
  public showStepper: boolean = true;

  public campaignResponse: any;

  public campaignDataForm: FormGroup;
  public attributesForm: FormArray;
  public customerAttributesList: any = [];
  public campaignAttributesList: any = [];
  public attributeClientForm = new FormArray([]);

  public productsArray = new FormArray([]);
  public inputStateArray = new FormArray([]);
  public inputsForm = new FormArray([]);
  public simulatorForm: FormGroup;
  public idCampaignInstance: number;
  public idCampaignInstanceCopy: number | null;
  public action: string = 'new';

  constructor(
    private readonly authService: AuthService,
    private readonly configurationCampaignFacade: ConfigurationCampaignFacade,
    private readonly inputsStatesFacade: InputsStatesFacade
  ) {
    this.createDataForm();
    this.createCampaignAttributesForm();
    this.createSimulatorForm();
  }

  public clearFormArray(formArray: FormArray) {
    for (let i = formArray.controls.length - 1; i >= 0; i--) {
      formArray.removeAt(i);
    }
  }

  public clear() {
    this.firstStepCompleted = false;
    this.secondStepCompleted = false;
    this.thirdStepCompleted = false;
    this.fourthStepCompleted = false;
    this.showStepper = true;
    this.campaignDataForm = new FormGroup({});
    this.customerAttributesList = [];
    this.campaignAttributesList = [];
    this.idCampaignInstance = 0;
    this.idCampaignInstanceCopy = null;
    this.campaignDataForm.enable();
    this.attributesForm.enable();
    this.attributeClientForm.enable();
    this.inputsForm.enable();
    this.inputStateArray.enable();
    this.productsArray.enable();
    this.createDataForm();
    this.createCampaignAttributesForm();
    this.clearClientAttributes();
    this.configurationCampaignFacade.resetConfigurations();
    this.inputsStatesFacade.resetInputsStates();
    this.productsArray = new FormArray([]);
    this.inputStateArray = new FormArray([]);
    this.inputsForm = new FormArray([]);
    this.simulatorForm?.get('link')?.setValue('');
    this.simulatorForm?.get('simulator')?.setValue(null);
    this.simulatorForm.markAsUntouched();
  }

  //Step one
  createDataForm() {
    this.campaignDataForm = new FormGroup({
      action: new FormControl(
        { value: 'Crear', disabled: true },
        Validators.required
      ),
      campaignType: new FormControl('', Validators.required),
      period: new FormControl('', [
        inputOnlyNumbersValidator,
        PeriodValidator,
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        inputAlphaNumericValidator,
      ]),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      createdBy: new FormControl(
        { value: this.authService.user, disabled: true },
        Validators.required
      ),
      // active: new FormControl(false, Validators.required),
      objective: new FormControl(''),
      modifiedBy: new FormControl(this.authService.user),
      idCampaignInstance: new FormControl(),
    });
  }

  createCampaignAttributesForm() {
    this.attributesForm = new FormArray([]);

    for (let i = 1; i <= 25; i++) {
      const form = new FormGroup({
        id: new FormControl(i),
        checked: new FormControl(false),
        name: new FormControl({ value: '', disabled: true }),
        attributeType: new FormControl(0),
        idPropertyRequired: new FormControl(0),
      });
      this.attributesForm.push(form);
    }
  }

  clearClientAttributes() {
    for (let form of this.attributeClientForm.controls) {
      form.patchValue({
        checked: false,
      });
    }
  }

  //Step two
  createAttributeForm(input?: any): FormGroup {
    const data = input || {};
    const { name, attributeType, inputValues } = data;
    return new FormGroup({
      name: new FormControl(name, [
        disallowEmptySpaceValidator,
        Validators.required,
      ]),
      attributeType: new FormControl(attributeType || 0, [
        Validators.required,
        Validators.min(1),
      ]),
      inputValues: new FormControl(inputValues || [], inputValuesValidator),
    });
  }

  createInputStateForm(item?: any): FormGroup {
    const data = item || {};
    const {
      state,
      subState,
      input,
      order,
      check,
      idPropertyRequired,
      idPropertyFormat,
      idFieldType,
    } = data;
    return new FormGroup({
      state: new FormControl(state, [Validators.required, Validators.min(1)]),
      subState: new FormControl(subState, [
        Validators.required,
        Validators.min(0),
      ]),
      input: new FormControl(input, [Validators.required, Validators.min(1)]),
      order: new FormControl(order, [
        inputOnlyNumbersValidator,
        Validators.required,
      ]),
      check: new FormControl(check || false, Validators.required),
      idPropertyRequired: new FormControl(idPropertyRequired || 0),
      idPropertyFormat: new FormControl(idPropertyFormat || 0),
      idFieldType: new FormControl(idFieldType || 0),
    });
  }

  //Step Three
  createProductsForm(item?: any): FormGroup {
    const data = item || {};
    const { state, subState, product, subProductType, idElementInstance } =
      data;
    return new FormGroup({
      state: new FormControl(state, [Validators.required, Validators.min(1)]),
      subState: new FormControl(subState, [
        Validators.required,
        Validators.min(0),
      ]),
      product: new FormControl(product, [
        Validators.required,
        Validators.min(1),
      ]),
      subProductType: new FormControl(subProductType, Validators.required),
      subProducts: new FormArray([], checkedMinLengthArray),
      idElementInstance: new FormControl(idElementInstance),
    });
  }

  createSubProductForm(
    id: number,
    name: string,
    checked: boolean = false,
    idSubelementInstance: number = 0
  ) {
    return new FormGroup({
      id: new FormControl(id, Validators.required),
      name: new FormControl(name, Validators.required),
      checked: new FormControl(checked, Validators.required),
      idSubelementInstance: new FormControl(idSubelementInstance || 0),
    });
  }

  createSimulatorForm() {
    this.simulatorForm = new FormGroup({
      simulator: new FormControl(),
      link: new FormControl('', Validators.pattern('^(http|https)://+.{2,}$')),
    });
    this.simulatorForm.get('link')?.disable();
  }
}
