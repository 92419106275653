import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EscomListEffects, EscomReducerModule } from './escom.store';
import { ESCOM_FEATURE_KEY } from './escom.store';
import { CoreModule } from 'src/app/shared/core/core.module';
import { EscomService } from 'src/app/services/escom.service';
import { EscomFacade } from './state/escom-list/escom.facade';
import { EscomHistoryFacade } from './state/escom-history/escom-history.facade';
import { EscomInformationTypeFacade } from './state/escom-type-information/escom-information-type.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    StoreModule.forFeature(ESCOM_FEATURE_KEY, EscomReducerModule),
    EffectsModule.forFeature(EscomListEffects)
  ],
  providers: [
    EscomService,
    EscomFacade,
    EscomHistoryFacade,
    EscomInformationTypeFacade
  ]
})
export class EscomModule { }
