import { createReducer, on } from "@ngrx/store";
import { CampaignConfigurationStateModel } from "./configuration.model";
import { configurationsList, configurationsSuccess, configurationsFailed
    , configurationsElementList, 
    configurationsElementFailed, configurationsElementSuccess, configurationsReset } 
from './configuration.actions';

export const initialState: CampaignConfigurationStateModel = {
    elementData: null,
    attributeData: null,
    loading: false,
    loadingElement: false,
    failed: false,
    failedElement: false,
    error: null
};

const _configurationsReducer = createReducer(initialState,
    on(configurationsList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    // on(configurationsSetLastUpdateDate, (state, { lastUpdateDate }) => {
    //     return { 
    //         ...state,
    //         attributeData: {
    //             ...state.attributeData,
    //             lastUpdateDate
    //         }
    //     }
    // }),
    on(configurationsFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(configurationsSuccess, (state, {payload}) => {
        return {
            ...state,
            attributeData: {...payload},
            loading: false,
            failed: false
        }
    }),
    on(configurationsElementList, state => {
        return { 
            ...state,
            loadingElement: true
        }
    }),
    on(configurationsElementFailed, (state, payload) => {
        return {
            ...state,
            loadingElement: false,
            failedElement: true
        }
    }),
    on(configurationsElementSuccess, (state, {payload}) => {
        return {
            ...state,
            elementData: {...payload},
            loadingElement: false,
            failedElement: false
        }
    }),
    on(configurationsReset, state => state = initialState)
);

export function configurationsReducer(state: any, action: any): CampaignConfigurationStateModel {
    return _configurationsReducer(state, action);
}
