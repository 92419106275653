<div class="vertical-modal">
    <div class="vertical-modal__content">
        <img src="/assets/img/close_modal.svg" class="close" alt="close" (click)="closeModal()">
        <div class="title">
            <h1>{{title}}</h1>
            <hr class="line-solid">
        </div>
        <ng-content></ng-content>
    </div>
</div>
