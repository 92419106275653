<div class="table-row" [formGroup]="form">
    <div *ngFor="let header of tableHeaders;" class="table-cell" [ngStyle]="{'width': header.width}">
      <ng-container [ngSwitch]="header.name">
        <ng-container *ngSwitchCase="'situation'">
          <span class="contact-list-detail__situation"
            [ngClass]="{'bg-regal-blue': form.value.situation == 2, 'bg-primary': form.value.situation == 1}">
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'contactName'">
          <mat-form-field class="form-input-control" appearance="fill" 
              *ngIf="form.value.writingMode else contactNameText">
            <input matInput formControlName="contactName" minlength="2">
          </mat-form-field>

          <ng-template #contactNameText>
            <app-tooltip-text 
              [top]="'-25px'"
              [right]="'auto'"
              [width]="'200px'"
              [description]="form.value.contactName">
            </app-tooltip-text>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'nunDocContact'">
          <mat-form-field class="form-input-control" appearance="fill" 
              *ngIf="form.value.writingMode else documentText">
            <input matInput formControlName="nunDocContact">
          </mat-form-field>

          <ng-template #documentText>
            {{form.value.nunDocContact}}
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'position'">
          <mat-form-field appearance="fill" class="form-dropdown-control"
            *ngIf="form.value.writingMode else positionText">
            <mat-select formControlName="positionId">
              <mat-option [value]="-1">Seleccione</mat-option>
                <mat-option [value]="item.idItem" *ngFor="let item of positionList">
                    {{item.campo1}}
                </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-template #positionText>
            <app-tooltip-text 
              [top]="'-25px'"
              [right]="'auto'"
              [width]="'200px'"
              [description]="form.value.position">
            </app-tooltip-text>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'emailOne'">
          <mat-form-field class="form-input-control" appearance="fill" 
              *ngIf="form.value.writingMode else emailText">
            <input matInput formControlName="emailOne">
          </mat-form-field>

          <ng-template #emailText>
            <app-tooltip-text 
              [top]="'-25px'"
              [right]="'auto'"
              [width]="'220px'"
              [lines]="1"
              [description]="form.value.emailOne"
              [buttonText]="'Copiar'"
              (clickButton)="copyToClipboard(form.value.emailOne)">
            </app-tooltip-text>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'phoneOne'">
          <mat-form-field class="form-input-control" appearance="fill" 
              *ngIf="form.value.writingMode else phoneOneText">
            <input matInput formControlName="phoneOne">
          </mat-form-field>

          <ng-template #phoneOneText>
            {{form.value.phoneOne}}
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'phoneTwo'">
          <mat-form-field class="form-input-control" appearance="fill" 
              *ngIf="form.value.writingMode else phoneTwoText">
            <input matInput formControlName="phoneTwo">
          </mat-form-field>

          <ng-template #phoneTwoText>
            {{form.value.phoneTwo}}
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div>
            {{ form.value[header.name]}}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'icon'">
            <app-loading [loading]="loading" [size]="20"></app-loading>
            <div class="display-flex align-items-center" *ngIf="form.value.writingMode else actions">
                <button mat-stroked-button mat-button class="btn-save"
                    [ngClass]="{'width-82': !!form.value.idContact, 'width-65': !form.value.idContact}"
                    (click)="save(form)">
                    {{form.value.idContact ? 'Guardar' : 'Crear'}}
                </button>
                <a (click)="cancel(form.value.idContact )" class="margin-left-5 display-flex cursor-pointer">
                    <img src="/assets/img/close.svg" alt="delete">
                </a>
            </div>
            <ng-template #actions>
                <div *hasPermission class="display-flex">
                  <a (click)="addFavorite(form.value.idContact, form.value.favorite)" class="cursor-pointer margin-right-5"
                    [ngClass]="{'disabled-control': writingMode}"
                    *ngIf="form.value.idContact && form.value.situation == 2">
                    <img src="/assets/img/star{{ form.value.favorite ? '-bg' : '' }}.svg" alt="star" />
                  </a>
                  <a (click)="editContact(form)" class="cursor-pointer"
                    [ngClass]="{'disabled-control': writingMode, 'edit-situation-button': form.value.situation == 1}">
                    <img src="/assets/img/edit.svg" alt="edit" *ngIf="form.value.situation == 2 else editSituation">
                    <ng-template #editSituation>
                      <img src="/assets/img/add.svg" alt="edit" />
                    </ng-template>
                  </a>
                  <a (click)="deleteContact(form.value.idContact)" class="cursor-pointer margin-left-5"
                      [ngClass]="{'disabled-control': writingMode}">
                      <img src="/assets/img/delete_2.svg" alt="delete">
                  </a>
                </div>
            </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </div>