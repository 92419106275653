import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectResumeJefeList = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.resume_jefe
);


export const selectResumeJefeLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.resume_jefe.loading
);
