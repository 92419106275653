<div class="container portfolio">
  <div class="header">
    <div class="leads" >
      <div class="leadTitle">
        <h2>{{nameReport}}</h2>
        
      </div>
      
    </div>
    
    <p></p>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="togglebtn(3)" >
        <mat-icon>list</mat-icon>Lista
      </button>
    </mat-menu>

    <button mat-stroked-button class="border-button" (click)="close()" *ngIf="false">
      <mat-icon>filter_list</mat-icon>
    </button>
  </div>
  <div class="body" >
    <div fxLayout="row" *ngIf="tipViewReport==1">
      <button mat-stroked-button class="button-tab" type="button" [ngStyle]="subOption=='bsc'?clasActive:clasInactive" (click)="addFilter('sReportName','Tablero')">BSC</button> 
      <button mat-stroked-button class="button-tab" type="button" [ngStyle]="subOption=='Ranking'?clasActive:clasInactive" (click)="addFilter('sReportName','Ranking')">Ranking EN</button> 
      <button mat-stroked-button class="button-tab" type="button" [ngStyle]="subOption=='RankingxIndicador'?clasActive:clasInactive" (click)="addFilter('sReportName','Ranking%20x%20Indicador')">Ranking Indicador</button> 
    </div>
    <div fxLayout="row" *ngIf="false">
      <button mat-stroked-button class="button-tab" [ngStyle]="subOption=='resumen'?clasActive:clasInactive" (click)="changeSubPath('resumen')">Resumen</button> 
      <button mat-stroked-button class="button-tab" [ngStyle]="subOption=='clientes'?clasActive:clasInactive" (click)="changeSubPath('clientes')" *ngIf="false">Clientes</button> 
      
    </div>
    
    <div fxLayout="row">
      <div class="portfolio__card" *ngIf="subOption=='bsc'">
        <div class="portfolio__row">
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Banca</mat-label>
              <mat-select disableRipple (selectionChange)="onBancaChange($event)" [(value)]="banca">
                
                <mat-option *ngFor="let item of lstBanca" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Zonal</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','zonal')" [(value)]="zona">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstZonal" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Jefe</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','jefe')" [(value)]="jefe">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstJefe" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Ejecutivo</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','')" [(value)]="ejecu">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstEjecutivo" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <div class="dropdown">
              <mat-form-field style="padding-bottom: 0;" appearance="outline">
                <mat-label>Analista</mat-label>
                <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','')" [(value)]="analista">
                  <mat-option value="">Todos</mat-option>
                  <mat-option *ngFor="let item of lstAnalistas" [value]="item.key">{{item.val}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Periodo</mat-label>
              <mat-select (selectionChange)="onFilterChange($event,'lsSperiodo','')" [(value)]="periodo">
                <mat-option *ngFor="let item of lstPeriodo" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <button mat-button class="portfolio__button" (click)="searchView()">Buscar</button>
          </div>
        </div>
      </div>

      <div class="portfolio__card" *ngIf="subOption=='Ranking'">
        <div class="portfolio__row">
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Banca</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSbanca','banca')" [(value)]="banca">
                
                <mat-option *ngFor="let item of lstBanca" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Zonal</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSzonal','zonalRK')" [(value)]="zona">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstZonal" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Jefe</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSjefe','')" [(value)]="jefe">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstJefe" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Perfil</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsStipopersona','')" [(value)]="perfil">
                <mat-option value="">Todos</mat-option>
                <mat-option value="Analista">Analista</mat-option>
                <mat-option value="Ejecutivo">Ejecutivo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Periodo</mat-label>
              <mat-select (selectionChange)="onFilterChange($event,'lsSperiodo','')" [(value)]="periodo">
                <mat-option *ngFor="let item of lstPeriodo" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <button mat-button class="portfolio__button" (click)="searchView()">Buscar</button>
          </div>
        </div>
      </div>

      <div class="portfolio__card indicador" *ngIf="subOption=='RankingxIndicador'">
        <div class="portfolio__row">
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Banca</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSbanca','banca')" [(value)]="banca">
                
                <mat-option *ngFor="let item of lstBanca" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Zonal</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSzonal','zonalRK')" [(value)]="zona">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstZonal" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Jefe</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSjefe','')" [(value)]="jefe">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstJefe" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Perspectiva</mat-label>
              <mat-select (selectionChange)="onFilterChange($event,'lsStipoIndicador','perspectiva')" [(value)]="perspectiva">
                <mat-option value="">Todos</mat-option>
                <mat-option value="1. Financiero">Financiero</mat-option>
                <mat-option value="2. Ecosistema">Ecosistema</mat-option>
                <mat-option value="3. Procesos">Procesos</mat-option>
                <mat-option value="4. Aprendizaje">Aprendizaje</mat-option>
                <mat-option value="5. Acelerador">Acelerador</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Indicador</mat-label>
              <mat-select (selectionChange)="onFilterChange($event,'lsSindicador','')" [(value)]="indicador">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let item of lstIndicador" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Perfil</mat-label>
              <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsStipopersona','')" [(value)]="perfil">
                <mat-option value="">Todos</mat-option>
                <mat-option value="Analista">Analista</mat-option>
                <mat-option value="Ejecutivo">Ejecutivo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <mat-form-field style="padding-bottom: 0;" appearance="outline">
              <mat-label>Periodo</mat-label>
              <mat-select (selectionChange)="onFilterChange($event,'lsSperiodo','')" [(value)]="periodo">
                <mat-option *ngFor="let item of lstPeriodo" [value]="item.key">{{item.val}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="portfolio__row-col">
            <button mat-button class="portfolio__button" (click)="searchView()">Buscar</button>
          </div>
        </div>
      </div>

      <div class="portfolio__card indicador" *ngIf="subOption=='resumen'">
        <div class="portfolio__row">
          <div class="portfolio__row-col">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Banca</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','bancaLarge')" [(value)]="banca">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstBanca" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>
          <div class="portfolio__row-col">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Zonal</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','zonal')" [(value)]="zona">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstZonal" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>
          <div class="portfolio__row-col">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Jefe</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','jefe')" [(value)]="jefe">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstJefe" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>
          
          <div class="portfolio__row-col">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Ejecutivo</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','')" [(value)]="ejecu">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstEjecutivo" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>
          
          
        </div>
        <div class="portfolio__row">
          <div class="portfolio__row-col">
              <div class="checkedDropdown">
                <div class="dropdown">
                  <mat-form-field style="padding-bottom: 0;" appearance="outline">
                    <mat-label>Fecha</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepickerFilter]="dateValidateFilter" [matDatepicker]="picker" 
                    (dateChange)="onFilterChangeDate($event,'lsSfecha','')" [(value)]="fechaSaldo">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
            </div>
          </div>
            <div class="portfolio__row-col">
                <div class="dropdown">
                  <mat-form-field style="padding-bottom: 0;" appearance="outline">
                    <mat-label>Tipo de colocación</mat-label>
                    <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsStipoproducto','')" [(value)]="colocacion">
                      <mat-option *ngFor="let item of lstColocacion" [value]="item.key">{{item.val}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>
            <div class="portfolio__row-col">
                <div class="dropdown">
                  <mat-form-field style="padding-bottom: 0;" appearance="outline">
                    <mat-label>Tipo de producto</mat-label>
                    <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSplazoproducto','')" [(value)]="producto">
                      <mat-option value="">Todos</mat-option>
                      <mat-option *ngFor="let item of lstProducto" [value]="item.key">{{item.val}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>
            
            <div class="portfolio__row-col">
                <div class="dropdown">
                  <mat-form-field style="padding-bottom: 0;" appearance="outline">
                    <mat-label>Estado de crédito</mat-label>
                    <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsScredito','')" [(value)]="credito">
                      <mat-option value="">Todos</mat-option>
                      <mat-option *ngFor="let item of lstCredito" [value]="item.key">{{item.val}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>

            <div class="portfolio__row-col">
                <div class="dropdown">
                  <mat-form-field style="padding-bottom: 0;" appearance="outline">
                    <mat-label>Moneda</mat-label>
                    <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSmoneda','')" [(value)]="moneda">
                      <mat-option value="">Todos</mat-option>
                      <mat-option *ngFor="let item of lstMoneda" [value]="item.key">{{item.val}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>
            <div class="portfolio__row-col">
              <button mat-button class="portfolio__button" (click)="searchView()">Buscar</button>
            </div>
      </div>

      <div class="portfolio__card indicador" *ngIf="subOption=='clientes' && false">
        <div fxLayout="row" class="wrapper" fxFlex="100%" fxLayoutAlign="flex-start">
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Fecha</mat-label>
                  <input [min]="minDate" [max]="maxDate" (dateChange)="onFilterChangeDate($event,'lsSfecha','')" matInput [matDatepicker]="picker" >
                  <mat-hint>DD/MM/YYYY</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Banca</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSbanca','banca')" [(value)]="banca">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstBanca" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Zonal</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSzonal','zonalRK')" [(value)]="zona">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstZonal" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Jefe</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSjefe','jefe')" [(value)]="jefe">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstJefe" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Ejecutivo</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSnombre','')" [(value)]="ejecu">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstEjecutivo" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Tipo de colocación</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsStipoproducto','')" [(value)]="colocacion">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstColocacion" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="14%">
            <div class="checkedDropdown">
              <div class="dropdown">
                <mat-form-field style="padding-bottom: 0;" appearance="outline">
                  <mat-label>Plazo</mat-label>
                  <mat-select disableRipple (selectionChange)="onFilterChange($event,'lsSplazoproducto','')" [(value)]="plazo">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let item of lstPlazo" [value]="item.key">{{item.val}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  </div>

  <div class="embed-container" *ngIf="tipViewReport!=3">
    <iframe width="100%" height="1000px" style="margin-top: -80px;" frameBorder="0" [src]="urlSafe" allowfullscreen></iframe>
  </div>
  <div class="embed-container" *ngIf="tipViewReport==3">
    <iframe width="100%" height="1000px" frameBorder="0" [src]="urlSafe" allowfullscreen></iframe>
  </div>
<!--
  <ng-container *ngIf="isEmbedded; else embedReportFlow">
    <button (click)="changeVisualType()">Change visual type</button>
    <button (click)="hideFilterPane()">Hide filter pane</button>
    <button (click)="setDataSelectedEvent()">Set event</button>
    <label class="display-message">{{ displayMessage }}</label>
  </ng-container>
  <ng-template #embedReportFlow>
    <label class="display-message position">
      {{ displayMessage }}
    </label>
    <button (click)="embedReport()" class="embed-report">Embed Report</button>
  </ng-template>
  <div class="embed-container">
    <ng-container *ngIf="isEmbedded">
      <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap">
      </powerbi-report>
    </ng-container>
</div>
-->
