import { createAction, props } from "@ngrx/store";
import { InputCampaignModel, StateCampaignModel, SubStateCampaignModel } from "src/app/pages/campaign-config/pages/campaign/models";

export const addState = createAction('[Inputs States] Add State',
    props<{payload: StateCampaignModel}>()    
);

export const removeState = createAction('[Inputs States] Remove State',
    props<{id: number}>()    
);

export const addSubState = createAction('[Inputs States] Add SubState',
    props<{payload: SubStateCampaignModel}>()    
);

export const removeSubState = createAction('[Inputs States] Remove SubState',
    props<{stateId: number, subStateId?: number}>()    
);

export const addInput = createAction('[Inputs States] Add Input',
    props<{inputs: InputCampaignModel[]}>()    
);

export const resetInputsStates = createAction('[Inputs States] Reset Inputs States');
