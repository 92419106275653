<div class="ibk-expansion-panel">
    <div class="ibk-expansion-panel__header">
        <div *ngIf="haveCheckbox == true" class="ibk-expansion-panel__header-check">
          <mat-checkbox
            [(ngModel)]="checked"
            [indeterminate]="indeterminate"
            (change)="changeValue()"
            > {{title}}
          </mat-checkbox>
        </div>
        <div *ngIf="haveCheckbox != true" class="ibk-expansion-panel__header-title">
            <h5>{{title}}</h5>
        </div>
        <div class="ibk-expansion-panel__header-actions">
            <img class="collapse" [ngClass]="{'close': !expand}" src="/assets/img/arrow-down.svg" alt="arrow" (click)="expand = !expand">
        </div>
    </div>
    <div class="ibk-expansion-panel__body"
        [ngClass]="{'open': expand}"
        [ngStyle]="{'max-height': maxHeight}">
        <ng-content></ng-content>
    </div>
</div>
