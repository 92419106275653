import { createAction, props } from "@ngrx/store";
import { QualitativeGetDTO, QualitativePaginatorDTO } from "src/app/pages/qualitative-sheet/models";

export const qualitativeSheetList = createAction('[QualitativeSheet] List',
    props<{request: QualitativeGetDTO}>()    
);
export const qualitativeSheetFailed = createAction('[QualitativeSheet] Failed',
    props<{payload: any}>()
);

export const qualitativeSheetSuccess = createAction('[QualitativeSheet] Success',
    props<{payload: QualitativePaginatorDTO}>()
);

export const qualitativeSheetReset = createAction(
    '[QualitativeSheet] Reset'
);
