<mat-drawer-container>
  <div *ngIf="!backOn" class="sidebar-content-back">
  </div>
  <div *ngIf="backOn" class="sidebar-content-back">
  </div>
  <div class="sidebar-content">
    <app-sidebar></app-sidebar>
  </div>
  <div class="mat-drawer-content" id="contentId">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
