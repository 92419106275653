import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IloadLead } from '../models/loadLead.model';
import { ActiveCampaingModel } from '../pages/client/pages/leads-detail/models/active-campaign.model';
import { HttpClientService, ContentType } from '../shared/core/services/http-client.service';
import { USER_INFO } from '../shared/core/constants/local.storage.constants';
import { LocalStorageService } from '../shared/core/services/local.storage.service';

@Injectable()
export class LeadService {

  constructor(private readonly http: HttpClientService,
    private readonly localStorageService: LocalStorageService
  ) { }

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  //Obtener la lista de campañas por usuario
  getListCampaignFilter(request: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign-user/campaign-filter`;
    return this.http.post<Array<any>>(path, request, options);
  }

  //Obtener la lista de estadios
  getListState() {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign-format/state-catalog`;
    return this.http.get<Array<any>>(path, options);
  }

  getListSubState(request: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign-format/substate-catalog`;
    return this.http.post<Array<any>>(path, request, options);
  }

  getLeadAmount(request: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.leads_path}/leads/get-lead-amount`;
    return this.http.post<Array<any>>(path, request, options);
  }

  getLeads(request: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.leads_path}/leads/listarLeads`;
    return this.http.post<IloadLead>(path, request, options);
  }


  searchCampain(body: { typeElement: number, idElement: string }) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign/search-campaign`;
    return this.http.post<any>(path, body, options);
  };

  registerDetailLead(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
        'userRegistration': this.localStorageService.getItem<string>(USER_INFO) ?? ''
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign/register-detail-lead`;
    return this.http.post<any>(path, body, options);
  };

  listCampaign(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign/list-campaign`;
    return this.http.post<any>(path, body, options);
  };

  updateStateCampaign(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
        'userRegistration': this.localStorageService.getItem<string>(USER_INFO) ?? ''
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign/update-status-campaing`;
    return this.http.post<any>(path, body, options);
  };

  getLastContacts(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.contact_path}/contact/list-last-contact`;
    return this.http.post<any>(path, body, options);
  };

  searchCustomerCampaign(body: any): Observable<ActiveCampaingModel[]> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.config_leads_path}/campaign/search-customer-campaign`;
    return this.http.post<ActiveCampaingModel[]>(path, body, options);
  }

};
