import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectFallsJefeList = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.falls_jefe
);


export const selectFallsJefeLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.falls_jefe.loading
);
