import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectRankingList = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.ranking
);


export const selectRankingLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.ranking.loading
);
