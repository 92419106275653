import { createAction, props } from "@ngrx/store";
import { FinancialEeffDTO } from "src/app/pages/qualitative-sheet/pages/financial-sheet-new/models";

export const financialEEFFList = createAction('[FinancialEEFF] List',
    props<{documentType: number, documentNumber: string }>()    
);
export const financialEEFFFailed = createAction('[FinancialEEFF] Failed',
    props<{payload: any}>()
);

export const financialEEFFSuccess = createAction('[FinancialEEFF] Success',
    props<{payload: FinancialEeffDTO[]}>()
);

export const financialEEFFReset = createAction(
    '[FinancialEEFF] Reset'
);
