import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ClientSettingsService } from 'src/app/services/client-settings.service';
import {
    clientFailed, clientList, clientSuccess
} from './client-list.actions';

@Injectable()
export class ClientListEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly clientSettingsService: ClientSettingsService
    ) { }

    getClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientList),
            mergeMap((action) => {
                const request = {
                    limit: action?.limit,
                    offset: action?.offset,
                    userRegistration: action?.userRegistration,
                    ruc: action?.ruc,
                    businessName: action?.businessName,
                    uniqueCode: action?.uniqueCode,
                    order: action?.order,
                    orderVariable: action?.orderVariable
                };
                return this.clientSettingsService.getClients(request)
                    .pipe(
                        map((data) => {
                            return clientSuccess({ payload: data });
                        }),
                        catchError((error) => of(clientFailed(error)))
                    )
            })
        )
    )

}
