import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ContentType,
  HttpClientService,
} from '../shared/core/services/http-client.service';
import { QualitativeCustomerProviderDTO, QualitativeCustomerProviderRequestDTO, QualitativeGetDTO, QualitativeMetricsDTO, QualitativePaginatorDTO, QualitativeGeneralDataRequestDTO, QualitativeShareholdersBackgroundDTO, QualitativeShareholdersBackgroundRequestDTO, QualitativeGeneralDataDTO, QualitativeStepsStateDTO } from '../pages/qualitative-sheet/models';
import { QualitativeSalesBusinessChannelRequestDTO } from '../pages/qualitative-sheet/models/qualitative-sales-business-channel.request.dto';
import { QualitativeSalesBusinessChannelDTO } from '../pages/qualitative-sheet/models/qualitative-sales-business-channel.dto';
import { map, Observable } from 'rxjs';

@Injectable()
export class QualitativeService {
  constructor(private readonly http: HttpClientService) {}

  get(request: QualitativeGetDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/customerRecord/listCustomerRecord`;
    return this.http.post<QualitativePaginatorDTO>(path, request, options);
  }

  getMetrics(documentNumber: string, documentType: number) {
    const request = {
      documentNumber,
      documentType,
      typeId: null,
      period: null
    }
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/qualitative-metrics/metrics`;
    return this.http.post<QualitativeMetricsDTO[]>(path, request, options);
  }

  saveGeneralData(request: QualitativeGeneralDataRequestDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/qualitativeRecordStepOne/insertStepOne`;
    return this.http.post<{idQualitativeRecord: number}>(path, request, options);
  }

  saveSalesBusinessChannel(request: QualitativeSalesBusinessChannelRequestDTO): Observable<QualitativeSalesBusinessChannelDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/stepTwo/insertStepTwo`;
    return this.http.post<{ qualitativeRecord: QualitativeSalesBusinessChannelDTO}>(path, request, options)
      .pipe(
        map(response => response.qualitativeRecord)
      );
  }

  saveCustomerClientProvider(request: QualitativeCustomerProviderRequestDTO): Observable<QualitativeCustomerProviderDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/stepThree/insertStepThree`;
    return this.http.post<{ qualitativeRecord: QualitativeCustomerProviderDTO}>(path, request, options)
      .pipe(
        map(response => response.qualitativeRecord)
      );
  }

  saveShareholdersBackground(request: QualitativeShareholdersBackgroundRequestDTO): Observable<QualitativeShareholdersBackgroundDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/stepFour/insertStepFour`;
    return this.http.post<{ qualitativeRecord: QualitativeShareholdersBackgroundDTO}>(path, request, options)
      .pipe(
        map(response => response.qualitativeRecord)
      );
  }

  getGeneralData(id: number): Observable<QualitativeGeneralDataDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/qualitativeRecordStepOne/getStepOne?idQualitativeRecord=${id}`;
    return this.http.get<QualitativeGeneralDataDTO>(path, options);
  }

  getSalesBusinessChannel(id: number): Observable<QualitativeSalesBusinessChannelDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/stepTwo/getStepTwo?idQualitativeRecord=${id}`;
    return this.http.get<QualitativeSalesBusinessChannelDTO>(path, options);
  }

  getCustomerClientProvider(id: number): Observable<QualitativeCustomerProviderDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/stepThree/getStepThree?idQualitativeRecord=${id}`;
    return this.http.get<QualitativeCustomerProviderDTO>(path, options);
  }

  getShareholdersBackground(id: number): Observable<QualitativeShareholdersBackgroundDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/stepFour/getStepFour?idQualitativeRecord=${id}`;
    return this.http.get<QualitativeShareholdersBackgroundDTO>(path, options);
  }

  getValidationStep(id: number): Observable<QualitativeStepsStateDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.qualitative_path}/qualitativeRecord/getValidationStep?idQualitativeRecord=${id}`;
    return this.http.get<QualitativeStepsStateDTO>(path, options);
  }

  finish(id: number, userRegistration: string): Observable<QualitativeStepsStateDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const request = {
      idQualitativeRecord: id,
      userRegistration
    }
    const path = `${environment.base_url}${environment.qualitative_path}/qualitativeRecord/finishQualitative`;
    return this.http.post<QualitativeStepsStateDTO>(path, request, options);
  }

}
