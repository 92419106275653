import { createAction, props } from "@ngrx/store";
import { HistoryPaginatorDTO } from "src/app/pages/escom-list/models/history.dto";
import { HistoryGetDTO } from "src/app/pages/escom-list/models/history.get.dto";

export const historyList = createAction('[Escom History] List',
    props<{request: HistoryGetDTO}>()    
);
export const historyFailed = createAction('[Escom History] Failed',
    props<{payload: any}>()
);

export const historySuccess = createAction('[Escom History] Success',
    props<{payload: HistoryPaginatorDTO}>()
);

export const historyReset = createAction(
    '[Escom History] Reset'
);

export const historyDelete = createAction('[Escom History] Delete',
    props<{idItemCreditDocument: number}>()    
);