export interface Ilogin {
  access_token: string;
  userinfo: Userinfo;
}

interface Userinfo {
  username: string;
  idRoleMenu: number;
  email: string;
  groups: string[];
  name: string;
}