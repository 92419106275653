import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ContentType,
  HttpClientService,
} from '../shared/core/services/http-client.service';
import { map, Observable } from 'rxjs';
import { FinancialEeffDTO, FinancialProfitLossStatementsDTO } from '../pages/qualitative-sheet/pages/financial-sheet-new/models';

@Injectable()
export class FinancialService {

  constructor(private readonly http: HttpClientService) {}

  getFinancialEeff(documentType: number, documentNumber: string): Observable<FinancialEeffDTO[]> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.financial_path}/financialEeff/listClientEeff?idDocumentType=${documentType}&documentNumber=${documentNumber}`;
    return this.http.get<{ customerEeffList: FinancialEeffDTO[]}>(path, options)
      .pipe(
        map(response => response?.customerEeffList ?? [])
      );
  }

  saveProfitLossStatements(request: FormData): Observable<FinancialProfitLossStatementsDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      },
    };
    const path = `${environment.base_url}${environment.financial_path}/financialRecordStepOne/insertStepOne`;
    return this.http.post<{ financialRecord: FinancialProfitLossStatementsDTO }>(path, request, options)
      .pipe(map(response => response?.financialRecord));
  }

  getProfitLossStatements(id: number) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
    };
    const path = `${environment.base_url}${environment.financial_path}/financialRecordStepOne/getStepOne?idFinancialRecord=${id}`;
    return this.http.get<FinancialProfitLossStatementsDTO>(path, options);
  }

}
