import { createReducer, on} from "@ngrx/store";
import { FinancialEeffStateModel } from "./financial-eeff.model";
import { financialEEFFFailed, financialEEFFList, financialEEFFReset, financialEEFFSuccess } from "./financial-eeff.actions";

export const initialState: FinancialEeffStateModel = {
    data: [],
    loading: false,
    failed: false,
    loaded: false
};

const _financialEeffReducer = createReducer(initialState,
    on(financialEEFFList, state => {
        return { 
            ...state,
            loading: true,
            loaded: false
        }
    }),
    on(financialEEFFFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(financialEEFFSuccess, (state, { payload }) => {
        return {
            ...state,
            data: [...payload],
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(financialEEFFReset, state => state = initialState)
);

export function financialEEFFReducer(state: any, action: any): FinancialEeffStateModel {
    return _financialEeffReducer(state, action);
}
