<div class="table-container"
    [ngStyle]="{'height': tableHeight }" (scroll)="onScroll($event)" #scrollTable>
    <div class="table-container__header {{headerBgColor}}"
        [ngStyle]="{'min-width': minWidth}">
        <div *ngFor="let header of headers; let i = index" class="table-header-cell"
            [ngStyle]="{'width': header.width}">
            <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: header}">
            </ng-container>
        </div>
    </div>
    <div class="table-container__body"
        [ngStyle]="{'min-width': minWidth}">
        <ng-container *ngIf="data?.length > 0 else nofilter">
            <ng-container *ngFor="let row of data; let i = index">
                <div class="table-row">
                    <div *ngFor="let header of headers;" class="table-cell"
                        [ngStyle]="{'width': header.width}">
                        <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: getName(row, header, i) }">
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="row.expanded" class="position-sticky">
                    <ng-container
                        *ngTemplateOutlet="expandTemplate; context: { $implicit: {data: row, index: i} }">
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #nofilter>
            <app-empty
              *ngIf="showEmpty"
              [description]="descriptionEmpty"
              [height]="heightEmpty"
            ></app-empty>
          </ng-template>
    </div>
</div>