<div class="hierarchy">
    <app-loading [loading]="loading"></app-loading>
    <div class="hierarchy__body" [formGroup]="filterForm">
        <div class="control" *ngIf="loggedInUserHierarchy?.id<=1">
            <mat-form-field appearance="fill" class="form-dropdown-control form-dropdown-label bg-dark">
                <mat-label>Banca</mat-label>
                <mat-select formControlName="banca"
                    (selectionChange)="selectUser($event.value)">
                    <mat-option [value]="item" *ngFor="let item of bancaList">
                        {{item.nameUserFilter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="control" *ngIf="loggedInUserHierarchy?.id<=2">
            <mat-form-field appearance="fill" class="form-dropdown-control form-dropdown-label bg-dark">
                <mat-label>Zonal</mat-label>
                <mat-select placeholder="Zonal" formControlName="zonal"
                    (selectionChange)="selectUser($event.value)">
                    <mat-option [value]="item" *ngFor="let item of zonalList">
                        {{item.nameUserFilter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="control" *ngIf="loggedInUserHierarchy?.id<=3">
            <mat-form-field appearance="fill" class="form-dropdown-control form-dropdown-label bg-dark">
                <mat-label>Jefatura</mat-label>
                <mat-select placeholder="Jefatura" formControlName="jefatura"
                (selectionChange)="selectUser($event.value)">
                    <mat-option [value]="item" *ngFor="let item of jefaturaList">
                        {{item.nameUserFilter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="control" *ngIf="loggedInUserHierarchy?.id<=4">
            <mat-form-field appearance="fill" class="form-dropdown-control form-dropdown-label bg-dark">
                <mat-label>Ejecutivo</mat-label>
                <mat-select placeholder="Ejecutivo" formControlName="ejecutivo"
                (selectionChange)="selectUser($event.value)">
                    <mat-option [value]="item" *ngFor="let item of ejecutivoList">
                        {{item.nameUserFilter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="control" *ngIf="loggedInUserHierarchy?.id == 5 && sectoristasList?.length > 0">
            <mat-form-field appearance="fill" class="form-dropdown-control form-dropdown-label bg-dark">
            <mat-label>Ejecutivo</mat-label>
                <mat-select placeholder="Ejecutivo" formControlName="ejecutivo"
                (selectionChange)="selectUser($event.value, false)">
                    <mat-option [value]="item" *ngFor="let item of sectoristasList">
                        {{item.nameUserFilter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="hierarchy__buttons">
        <div class="button">
            <button mat-flat-button class="ok" (click)="ok()">
                Guardar
            </button>
            <button mat-button class="cancel" (click)="cancel()">
                Limpiar
            </button>
        </div>
    </div>
</div>
