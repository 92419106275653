import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { financialEEFFList, financialEEFFSuccess, financialEEFFFailed } from './financial-eeff.actions';
import { FinancialService } from 'src/app/services/financial.service';

@Injectable()
export class FinancialEeffEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly financialService: FinancialService
    ) { }

    getFinancialEeff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(financialEEFFList),
            mergeMap((action) => {
                return this.financialService.getFinancialEeff(action.documentType, action.documentNumber)
                    .pipe(
                        map((data) => {
                            return financialEEFFSuccess({ payload: data });
                        }),
                        catchError((error) => of(financialEEFFFailed(error)))
                    )
            })
        )
    )

}
