import { createAction, props } from "@ngrx/store";

export const attributesList = createAction('[Attributes] List');
export const attributesFailed = createAction('[Attributes] Failed',
    props<{payload: any}>()
);
export const attributesSuccess = createAction('[Attributes] Success',
    props<{payload: any}>()
);
export const attributesReset = createAction(
    '[Attributes] Reset'
);
