<div #textDiv class="tooltip-text-information" [style.-webkit-line-clamp]="lines"
    [ngClass]="{'text-underline': isClamped(), 'no-clamp': lines == 1, 'clamp': lines > 1}" (mouseenter)="showTooltip()"
    (mouseleave)="tooltip_text.hide()">
    {{ description}}
    <app-tooltip-ui #tooltip_text [top]="top" [right]="right" [width]="width" [left]="left" [color]="color"
        [open]="open">
        <ng-container *ngIf="buttonText else textonly">
            <div class="tooltip-text-information__actions">
                <p class="text-action">
                    {{description}}
                </p>

                <a (click)="clickButton.emit()">
                    {{buttonText}}
                </a>
            </div>
        </ng-container>
        <ng-template #textonly>
            {{description}}
        </ng-template>
    </app-tooltip-ui>
</div>