import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-ui',
  templateUrl: './tooltip-ui.component.html',
  styleUrls: ['./tooltip-ui.component.scss']
})
export class TooltipUiComponent {

  @Input() color: string;
  @Input() width: string = '148px';
  @Input() right: string = 'calc(50% - 74px)';
  @Input() left: string = 'auto';
  @Input() top: string = '-43px';
  @Input() bgColor: string = '#B7BABC';
  /**
   * if 'TIMEOUT' is 0, the "TOOLTIP" is not closed until "OPEN" is false
   */
  @Input() timeout: number = 0;
  @Input() open: boolean;

  constructor() { }

  
  show() {
    this.open = true;
    if (this.timeout > 0) {
      setTimeout(() => {
        this.open = false;
      }, this.timeout);
    }
  }

  hide() {
    this.open = false;
  }

}
