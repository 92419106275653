import { createReducer, on } from '@ngrx/store';
import {
  instructiveProductsList,
  instructiveProductsSuccess,
  instructiveProductsFailed,
  instructiveRemoveProduct,
  instructiveUpdateProduct,
  instructiveProductReset,
} from './instructive-products.actions';
import { InstructiveProductsStateModel } from './instructive-products.model';

export const initialState: InstructiveProductsStateModel = {
  data: [],
  loading: false,
  failed: false,
};

const _instructiveProductsReducer = createReducer(
  initialState,
  on(instructiveProductsList, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(instructiveProductsFailed, (state, { payload }) => {
    return {
      ...state,
      loading: false,
      failed: true,
    };
  }),
  on(instructiveProductsSuccess, (state, { payload }) => {
    return {
      ...state,
      data: [...payload],
      loading: false,
      failed: false,
    };
  }),
  on(instructiveRemoveProduct, (state, { idElementFormat }) => {
    const products = [...state.data];
    const index = products.findIndex(item => item.idElementFormat == idElementFormat);
    products.splice(index, 1);
    return {
      ...state,
      data: [...products]
    };
  }),
  on(instructiveUpdateProduct, (state, { idElementFormat, name }) => {
    const products = [...state.data];
    const index = products.findIndex(item => item.idElementFormat == idElementFormat);
    const product = { ...products[index], name };
    products.splice(index, 1, product);
    return {
        ...state,
        data: [...products]
    }
  }),
  on(instructiveProductReset, state => state = initialState)
);

export function instructiveProductsReducer(
  state: any,
  action: any
): InstructiveProductsStateModel {
  return _instructiveProductsReducer(state, action);
}
