import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { resumeList, resumeReset } from "./resume.actions";
import { selectResumeList } from "./resume.selectors";

@Injectable()
export class ResumeFacade {

    constructor(private readonly store: Store) {}
    
    getResume(request: any) {
        this.store.dispatch(resumeList({ payload: request }));
    }

    reset() {
        this.store.dispatch(resumeReset());
    }

    selectResume() {
        return this.store.select(selectResumeList);
    }

}