<div class="modal-container" id="filter">
  <h1>Seleccionar los Campos</h1>
  <div class="view-field">
    <p>Campos disponibles</p>
    <p>Campos visibles</p>
  </div>
  <dual-list [source]="source" [(destination)]="target"  [format]="format"></dual-list>
   <mat-icon>cached</mat-icon>
  <div class="confirm">
	   <button mat-raised-button color="primary" class="green btn-filter" (click)="CloseDialog()">Guardar</button>
  </div>
</div>
