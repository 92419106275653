import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HierarchyModel } from '../models/hierarchy.model';
import { ContentType, HttpClientService } from '../shared/core/services/http-client.service';
import { ClientDownloadRequest } from '../pages/client-list/models/client-download.request';

@Injectable({
  providedIn: 'root'
})
export class ClientSettingsService {

  private urlReporting = `${environment.base_url}${environment.reporting_path}`;

  constructor(private http: HttpClientService) {}


  getClients(body: any) {
    const options = {
      headers: {
        'Content-Type': ContentType.JSON,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    const path = `${environment.base_url}${environment.leads_path}/customer/customerList`;
    return this.http.post<any>(path, body, options);
  };

  getFamilyClients(body: any) {
    const options = {
      headers: {
        'Content-Type': ContentType.JSON,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    const path = `${this.urlReporting}/client-family/variable-list`;
    return this.http.post<any>(path, body, options);
  };

  getHierarchy(body: any) {
    const options = {
      headers: {
        'Content-Type': ContentType.JSON,
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    const path = `${environment.base_url}${environment.users_path}/users/list-hierarchies`;
    return this.http.post<HierarchyModel>(path, body, options);
  };

  download(request: ClientDownloadRequest) {
    const headers = {
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };

    const path = `${environment.base_url}${environment.leads_path}/customer/download-excel-customer`;
    return this.http.post<any>(path, request, {headers, responseType: 'blob'})
  }

}
