import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PathRest } from '../../../static/path.rest';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';

@Injectable()
export class ContactService {

  selectedHierarchyUser: string | null;

  constructor(private http: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) { }

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  deleteContact(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.DELETE_CONTACT;
    return this.http.post<any>(path, body, { headers });
  };

  saveContact(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.CREATESHORT_CONTACT;
    return this.http.post<any>(path, body, {headers})
  }

  getContact(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_CONTACT;
    return this.http.post<any>(path, body, { headers });
  };

  getCustomerWithUniqueCode(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_CUSTOMER_WITH_UNIQUE_CODE;
    return this.http.post<any>(path, body, { headers });
  };

  getContactsByEnterprises(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_CONTACT_BY_ENTERPRISES;
    return this.http.post<any>(path, body, { headers });
  };


  getListContactsByEnterprises(body: any){
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_LIST_CONTACT_BY_ENTERPRISES;
    return this.http.post<any>(path, body, { headers });
  }

  getContactTypeUniverse(body: any){
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_CONTACT_TYPE_UNIVERSE;
    return this.http.post<any>(path, body, { headers });
  }
};
