import { createAction, props } from "@ngrx/store";

// export const resumeList = createAction('[Resume] List',
//     props<{directas: number[], indirectas: number[]}>()
// );
export const resumeList = createAction('[Resume] List',
    props<{payload: any}>()
);
export const resumeFailed = createAction('[Resume] Failed',
    props<{payload: any}>()
);
export const resumeSuccess = createAction('[Resume] Success',
    props<{payload: any}>()
);

export const resumeReset = createAction(
    '[Resume] Reset'
);

