import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QUALITATIVE_FEATURE_KEY, QualitativeListEffects, QualitativeReducerModule } from './qualitative.store';
import { CoreModule } from 'src/app/shared/core/core.module';
import { QualitativeService } from 'src/app/services/qualitative.service';
import { QualitativeFacade } from './qualitative-list/qualitative.facade';
import { QualitativeNewFacade } from './qualitative-new/qualitative-new.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    StoreModule.forFeature(QUALITATIVE_FEATURE_KEY, QualitativeReducerModule),
    EffectsModule.forFeature(QualitativeListEffects)
  ],
  providers: [
    QualitativeService,
    QualitativeFacade,
    QualitativeNewFacade
  ]
})
export class QualitativeStoreModule { }
