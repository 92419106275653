import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {
    
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInputChange(event: InputEvent) {
        let input = this.el.nativeElement as HTMLInputElement;
        const sanitizedValue = input.value.replace(/[^0-9]/g, '');

        if (sanitizedValue !== input.value) {
            input.value = sanitizedValue;
            event.stopPropagation();
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.isAllowedKey(event)) {
            return;
        }

        if (event.key && !event.key.match(/^[0-9]$/)) {
            event.preventDefault();
        }
    }

    private isAllowedKey(event: KeyboardEvent): boolean {
        const allowedKeys = [
            'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
        ];
        const isCtrlCmdCombo = (event.ctrlKey || event.metaKey) && 
            ['a', 'c', 'v', 'x'].indexOf(event.key.toLowerCase()) > -1;
        
        return allowedKeys.indexOf(event.key) !== -1 || isCtrlCmdCombo;
    }



}
