<div class="page-error">
    <div class="page-error__info">
        <h3 class="margin-0 f-size-20 f-weight-600">Error</h3>
        <h1 class="margin-0 f-size-60 f-weight-600">404</h1>
        <h6 class="margin-top-0 f-size-20 color-red">Página no ubicada</h6>
        <div class="margin-top-40">
            <p class="margin-0 f-size-16 f-weight-600 margin-bottom-4">¡Algo salió mal!</p>
            <small class="f-size-16 f-weight-500 color-dark-liver">No se encontró la página solicitada</small>
        </div>
        <button mat-flat-button
            class="margin-top-30"
            color="primary"
            (click)="goHome()">
            <span>Retornar al home</span>
        </button>
    </div>
    <div class="page-error__image">
        <img src="/assets/img/dashboard/no-access.svg" alt="img"/>
    </div>
</div>