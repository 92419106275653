import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { OperationVariablesService} from './operation-variables.service';

@Component({
  selector: 'app-operation-variables',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './operation-variables.component.html',
  styleUrls: ['./operation-variables.component.scss']
})


export class OperationVariablesComponent implements OnInit {

  @Output() sendFilter = new EventEmitter<any>();
  //Todo: para esto en el componente principal que va a tener esto se tiene que hacer el dispatch


  allVariables: boolean = true;
  someNotSelectedVariable: boolean = false;

  constructor( public operationVariablesService:OperationVariablesService) {
  }

  ngOnInit(): void {

    if(!this.operationVariablesService.someNotSelectedVariable){
      if(!this.operationVariablesService.allVariablesNotSelected){
        this.operationVariablesService.listenClientState();
      }
    }

    if(this.operationVariablesService.allVariablesNotSelected){
      this.allVariables = false;
    }
  }

  public changeStateSelect(variable: any){
    let indexVariable = this.operationVariablesService.variableFamilyListWithData.findIndex(variableObject => variableObject.idClientFamily == variable.title);
    if(indexVariable != -1 ){
      this.operationVariablesService.variableFamilyListWithData[indexVariable].selected = variable.selected;

      let someNotSelectedVariable = this.operationVariablesService.variableFamilyListWithData.some(variableObjeto => variableObjeto.selected == false);
      if (someNotSelectedVariable) {
        this.someNotSelectedVariable = true
        this.allVariables == false;
        this.operationVariablesService.someNotSelectedVariable = true;
        this.operationVariablesService.allVariablesNotSelected = false;
      }

      let allSelectedVariables = this.operationVariablesService.variableFamilyListWithData.every(variableObjeto => variableObjeto.selected == true);
      if(allSelectedVariables) {
        this.allVariables = true;
        this.someNotSelectedVariable = false;
        this.operationVariablesService.someNotSelectedVariable = false;
        this.operationVariablesService.allVariablesNotSelected = false;
      }

      let allNotSelectedVariables = this.operationVariablesService.variableFamilyListWithData.every(variableObjeto => variableObjeto.selected == false);
      if(allNotSelectedVariables) {
        this.allVariables = false;
        this.someNotSelectedVariable = false;
        this.operationVariablesService.someNotSelectedVariable = false;
        this.operationVariablesService.allVariablesNotSelected = true;
      }
    }
  }

  public checkAll(complete: boolean){
    this.allVariables = complete;
    if(complete){
      this.operationVariablesService.variableFamilyListWithData.forEach(element => {element.selected = true});
      this.operationVariablesService.someNotSelectedVariable = false;
      this.operationVariablesService.allVariablesNotSelected = false;
    }else{
      this.operationVariablesService.variableFamilyListWithData.forEach(element => {element.selected = false});
      this.operationVariablesService.someNotSelectedVariable = false;
      this.operationVariablesService.allVariablesNotSelected = true;
      this.someNotSelectedVariable = false;
      this.allVariables = false;
    }
  }

  public save() {
    let variableFamilyListFiltered;

    if(this.operationVariablesService.someNotSelectedVariable){
      variableFamilyListFiltered = this.operationVariablesService.variableFamilyListWithData.filter(variable => variable.selected == true);
    }else{
      variableFamilyListFiltered = this.operationVariablesService.variableFamilyListWithData;
    }
    this.sendFilter.emit(variableFamilyListFiltered)
  }

  public clear() {
    this.allVariables = false;
    this.operationVariablesService.someNotSelectedVariable = false;
    this.operationVariablesService.allVariablesNotSelected = true;
    this.operationVariablesService.variableFamilyListWithData.forEach(element => {element.selected = false});
  }

}
