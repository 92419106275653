import { createAction, props } from "@ngrx/store";

export const resumeJefeList = createAction('[Resume Jefe] List',
    props<{payload: any}>()
);
export const resumeJefeFailed = createAction('[Resume Jefe] Failed',
    props<{payload: any}>()
);
export const resumeJefeSuccess = createAction('[Resume Jefe] Success',
    props<{payload: any}>()
);

export const resumeJefeReset = createAction(
    '[Resume Jefe] Reset'
);

