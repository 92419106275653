<div class="ibk-step" (click)="onClick()"
  [ngClass]="{'bg': hasBg}">
  <div class="ibk-step__number"
    [ngClass]="{'checked': checked, 'active': active, 'error': error}">
    <span>{{ stepNumber }}</span>
    <img
      class="ibk-step__check"
      src="/assets/img/steps/icon-check.svg"
      alt="img"
      *ngIf="checked"
    />
    <img
      class="ibk-step__check"
      src="/assets/img/steps/icon-alert.svg"
      alt="img"
      *ngIf="error"
    />
  </div>
  <span class="ibk-step__text" [ngClass]="{'active': active, 'error': error}">{{ description }}</span>
  <div class="ibk-step__line" *ngIf="active"></div>
</div>
