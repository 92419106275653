import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from '../../models/contact';
import { EsquemaDeValidacion } from '../../models/esquemaValidacion';
import { inputPhoneValidator } from 'src/app/shared/ui/validators/input-phone.validator';
import { inputEmailValidator } from 'src/app/shared/ui/validators/input-email.validator';
import { email, phone } from 'src/app/shared/core/utils';
import { Observable } from 'rxjs';
import { inputAlphabeticWithAccentValidator } from 'src/app/shared/ui/validators/input-alphabetic-accent.validator';
import { ContactsFacadeService } from '../../services/contacts-facade.service';

@Component({
  selector: 'app-operation-contact-list',
  templateUrl: './operation-contact-list.component.html',
  styleUrls: ['./operation-contact-list.component.scss']
})

export class OperationContactListComponent implements OnInit {

  @Input("typeOperation")
  public tipoOperacion: string;

  @Input("index")
  public indiceContacto: number;

  @Input("itemContact")
  public contacto: Contact;

  @Input("searchActivate")
  public searchActivate:boolean;
  
  @Input() leads: boolean;

  @Output()
  public removeContact = new EventEmitter<any>();

  @Output()
  public contactUpdate = new EventEmitter<any>();

  @Input('getCompanyName')
  public obtenerCompanyCallback: (contacto: {contactCu: any, uuid: any }) => Observable<any>;

  @Output() reload = new EventEmitter();

  contactoForm: FormGroup;
  public cuRuc: string;

  loading: boolean = false;

  constructor(
    protected formBuilder: FormBuilder,
    private readonly contactsFacadeService: ContactsFacadeService
  ) { }

  ngOnInit() {
    this.concatCuRuc();
    this.createForm();
    this.contactoForm.valueChanges.subscribe((nuevosValores) => {
      this.validarValores(nuevosValores);
    })

  }

  public getCompany(){
    let valueCuRuc = this.contactoForm.get('cu')?.value.trim();
    if(valueCuRuc.length == 10 || valueCuRuc.length == 11){
      let contactCuRuc = {
        'contactCu': valueCuRuc,
        'uuid': this.contacto.uuid
      }
      this.obtenerCompanyCallback(contactCuRuc).subscribe( {
        next: (resp: {
          documentType: number,
          documentNumber: string,
          enterpriseName: string,
          uniqueCode: string
        }) => {
          if(this.contacto?.customer){
            this.contacto.customer.idCustomer.idDocumentType = resp.documentType;
            this.contacto.customer.idCustomer.documentNumber = resp.documentNumber;
          }

          this.contacto.companyName = resp.enterpriseName;
          this.contacto.ruc = resp.documentNumber;
          this.contacto.cu = resp.uniqueCode;

          this.contactoForm.get('company')?.setValue(this.contacto.companyName);
        },
        error: (error) => {

        }
      });
    }
  }


  private concatCuRuc() {
    if(this.tipoOperacion != 'edit'){
      let cu:string = this.contacto.cu;
      let ruc:string = this.contacto.ruc;

      if (this.contacto.isNew) {
        this.cuRuc = cu;
      } else {
        ruc.length != 11
        ?  this.cuRuc = cu + ' '.repeat(12)
        : this.cuRuc = cu + ' ' + ruc;
      }

    }else {
      this.cuRuc = this.contacto.cu;
    }
  }

  public createForm()  {
    
    //let patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let patternEmail = /^[a-zA-Z][a-zA-Z0-9._-][^<>()[\]\\,;:\s@\"]*@[a-zA-Z]+(\.[a-zA-Z]{2,})*$/;
    //let patternEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let patternTelefono = phone();
    this.contactoForm = this.formBuilder.group({
      company: [this.contacto.companyName , Validators.required],
      cu: [this.cuRuc, [Validators.required, Validators.minLength(10), Validators.maxLength(14)]],
      nombre: [this.contacto.contactName, [Validators.required, Validators.minLength(2), inputAlphabeticWithAccentValidator]],
      cargo: [this.contacto.position, [Validators.minLength(2), inputAlphabeticWithAccentValidator]],
      telefono1: [this.contacto.phoneOne, [Validators.pattern(patternTelefono), inputPhoneValidator, Validators.minLength(6), Validators.maxLength(15)]],
      telefono2: [this.contacto.phoneTwo, [Validators.pattern(patternTelefono), inputPhoneValidator, Validators.minLength(6), Validators.maxLength(15)]],
      correo: [this.contacto.email, [Validators.pattern(email())]],
      fechaActualizacion: [this.contacto.updateDate, [Validators.required]]

    })
    if (this.contacto.isNew) {
      this.getCompany();
    }
  }

  public validarValores(formulario: any) {
    let formularioSemiCompleto:boolean = false;
    let formularioInvalido: boolean = false;
    let formularioInvalidoAceptado: boolean = false;
    let formularioValido:boolean = false

    // let validatorCorreo = /^[a-zA-Z][a-zA-Z0-9._-][^<>()[\]\\,;:\s@\"]*@[a-zA-Z]+(\.[a-zA-Z]{2,})*$/;
    let validatorCorreo = new RegExp(email());

    let esquemaDeValidacion: EsquemaDeValidacion = {
      cu: {tipo:'string', requerido: true, min: 10},
      nombre: {tipo:'string', requerido:true, min: 2},
      cargo: {tipo:'string', requerido:false, min: 2},
      telefono1: {tipo:'string', requerido:false, min: 6},
      telefono2: {tipo:'string', requerido:false, min: 6},
      correo: {tipo:'string', requerido:false, min: 5}
    }

    for(let propiedad in esquemaDeValidacion) {
      let reglas = esquemaDeValidacion[propiedad];

      if(formulario[propiedad] == "" || formulario[propiedad] == null){
        if(reglas.requerido && (propiedad == 'cu' || propiedad == 'nombre')){
          formularioInvalido = true;
          break;
        }else if(!reglas.requerido && (propiedad == 'cargo' || propiedad == 'telefono1' || propiedad == 'telefono2' || propiedad == 'correo')){
          formularioSemiCompleto = true;
        }
      }

      if(formulario[propiedad] != null && formulario[propiedad] != ""){

        if((propiedad == 'cu' || propiedad == 'nombre' || propiedad == 'cargo' || propiedad == 'correo')
        && (formulario[propiedad].length >= reglas.min) ){
          formularioInvalido = false;
          formularioSemiCompleto = formularioSemiCompleto ? true : false;
          if(propiedad == 'correo' && (formulario[propiedad].indexOf('@') == -1 || !validatorCorreo.test(formulario[propiedad]))){
            formularioInvalido = true;
            break;
          }
        }else if((propiedad == 'telefono1' || propiedad == 'telefono2') && (formulario[propiedad].length < reglas.min)){
          formularioInvalidoAceptado = true;
        }else if ((propiedad == 'cu' || propiedad == 'nombre' || propiedad == 'cargo' || propiedad == 'correo')
        && (formulario[propiedad].length < reglas.min)) {
          formularioInvalido = true;

          if(propiedad == 'correo' && formulario[propiedad].indexOf('@') == -1 || !validatorCorreo.test(formulario[propiedad])){
            formularioInvalido = true;
            break;
          }
          break;
        }

      }

    }

    if(formularioSemiCompleto == false && formularioInvalido == false) formularioValido = true;

    this.actualizarContacto(formulario, formularioInvalidoAceptado, formularioSemiCompleto, formularioInvalido, formularioValido);

  }

  public actualizarContacto(formulario:any, formularioInvalidoAceptado:boolean, formularioSemiCompleto:boolean, formularioInvalido:boolean, formularioValido:boolean){
    //objeto con las modificaciones ya sea para agregar o para editar
    let customerGenerate;
    if (this.contacto.isNew == false || this.contacto.isNew == undefined) {
      customerGenerate = {
        idCustomer: {
          idDocumentType: this.contacto.typeDoc,
          documentNumber: this.contacto.ruc
        }
      };
    }

    let contactUpdate: Contact = {
      uuid: this.contacto.uuid,
      companyName : formulario['company'].trim(),
      contactAddress : this.contacto.contactAddress,
      contactLastName : this.contacto.contactLastName,
      contactMotherlastName : this.contacto.contactMotherlastName,
      contactName : formulario['nombre'] == null ? ''.trim() : formulario['nombre'].trim(),
      cu : this.contacto.isNew == true ? formulario['cu'].trim() : this.contacto.cu,
      email : formulario['correo'] == null ? ''.trim() : formulario['correo'].trim(),
      idContact : this.contacto.isNew == true ? NaN : this.contacto.idContact,
      phoneOne : formulario['telefono1'] == null ? ''.trim() : formulario['telefono1'].trim(),
      phoneTwo : formulario['telefono2'] == null ? ''.trim() : formulario['telefono2'].trim(),
      position : formulario['cargo'] == null ? ''.trim() : formulario['cargo'].trim(),
      ruc : this.contacto.ruc,
      typeDoc : this.contacto.isNew == true ? NaN : this.contacto.typeDoc,
      updateDate : this.contacto.updateDate,
      customer : this.contacto.isNew == true ? this.contacto.customer : customerGenerate,
      invalidFormAcept : formularioInvalidoAceptado == true ? true : formularioSemiCompleto == true ? true : false,
      invalidForm: formularioInvalido == true ? true : false,
      index : this.indiceContacto
    }

    if (this.contacto.isNew) contactUpdate.isNew = true;
    this.contactUpdate.emit(contactUpdate);
  }

  public remove(modo:string, indice:number) {
    let deleteContact = {
        modo: modo,
        uuid: this.contacto.uuid,
        idContact: this.contacto.idContact,
        busqueda: this.searchActivate,
        index: indice
      }
    this.removeContact.emit(deleteContact)

  }

  public getErrorMsg(controlName: string){
    const control = this.contactoForm.get(controlName);
    if(control){
      switch (controlName) {
        case "cu":
          if(control.value.trim().length == 0 && control.hasError('required')) return 'Este campo es requerido';
          if(control.value.trim().length < 11 && control.hasError('minlength')) return 'Este campo requiere minimo 9 digitos';
          break;
          
        case "nombre":
          if(control.value.trim().length == 0 && control.hasError('required')) return 'Este campo es requerido';
          if(control.value.trim().length < 9 && control.hasError('minlength')) return 'Este campo requiere minimo 2 caracteres';
          break;

        case "cargo":
          if(control.value.trim().length < 3 && control.hasError('minlength')) return 'Este campo requiere minimo 2 caracteres';
          break;

        case "telefono1":
        case "telefono2":
          if(control.value.trim().length < 6 && control.hasError('minlength')) return 'Este campo requiere minimo 6 digitos';
          if(control.hasError('pattern') || control.value.trim().length == 0) return 'Este campo requiere de digitos';
          break;

        case "correo":
          if(control.value.trim().indexOf('@') == -1 || control.hasError('pattern')) return 'E-mail no valido';
          break;
      }
      return '';
    }
    return '';
  }

  public copyToClipboard(){
    const email = this.contactoForm.value.correo;
    navigator.clipboard.writeText(email);
  }

  addFavorite() {
    if (this.contacto) {
      this.loading = true;
      const { favorite, idContact } = this.contacto;
      const priority = !(favorite || false);
      this.contactsFacadeService.addAndRemovePriority(priority, idContact)
      .subscribe({
        next: () => {
          this.loading = false;
          this.reload.emit();
        },
        error: () => this.loading = false
      })

    }
  }

}
