import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

  @Input() title: string;
  @Input() expand: boolean = true;
  @Input() haveCheckbox: boolean = false;
  @Input() checked: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() maxHeight: string = '200px';
  @Output() sendCheck = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  changeValue() {
    if(this.haveCheckbox){
      let variable = {
        title: this.title,
        selected: this.checked
      }
      this.sendCheck.emit(variable)
    }
  }

}
