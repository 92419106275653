<div class="search" [ngClass]="{'outline': outline, 'error': error}">
    <mat-form-field>
        <input #search_input
            matInput
            (keyup.enter)="ok()"
            (keyup)="keyup($event)"
            (focus)="onFocus.emit()"
            (blur)="onFocusOut.emit()"
            [formControl]="text"
            [placeholder]="error ? 'Los datos ingresados no se ubican en el sistema.' : placeholder">
        <mat-icon *ngIf="text.value?.length > 0" (click)="clear()">close</mat-icon>
    </mat-form-field>
    <ng-container *ngIf="!outline else searchOutline" >
      <button (click)="ok()" [disabled]="disabled">
        <img src="/assets/img/search.svg" alt="search">
      </button>
    </ng-container>
    <ng-template #searchOutline>
      <button (click)="ok()" [disabled]="disabled">
        <img src="/assets/img/search-green.svg" alt="search">
      </button>
    </ng-template>

</div>
