export enum ParametersEnum {
    currency = 7,
    attributeType = 8,
    inputAttribute = 10,
    campaignState = 12,
    campaignTypes = 13,
    campaignStateValidate = 14,
    campaignProducts = 20,
    enoteTypes = 21,
    reason = 22, //motivo
    banks = 24,
    campaignProductsTypes = 25,
    dashboard = 27,
    categoryInstructive = 30,
    renewal = 31,
    contactType = 32,
    userAccessView = 35,
    escom = 40,
    qualitativeType = 51,
    qualitativeState = 52,
    qualitativeBacklog = 50,
    qualitativeActivity = 45,
    qualitativeSubSector = 46,
    qualitativeAccounting = 47,
    qualitativeRawMaterial = 48,
    qualitativeComodities = 49,
    qualitativePaymentSale = 55,
    qualitativePaymentPurchase = 56,
    qualitativeOperationZone = 53,
    qualitativeClientZone = 54,
    qualitativeClientType = 57,
    qualitativeProvidersType = 58,
    qualitativeRole = 59,
    financialPrices = 60
}
