<div class="container">
    <div class="header">
        <div class="contacts">
            <div class="contactsTitle">
                <h1>{{enterprise.companyName}}</h1>
                <mat-icon class="green" (click)="goToListContactsDualView()">keyboard_arrow_left</mat-icon>
            </div>
            <p><b>Detalle de Contactos</b></p>
        </div>
        <p></p>
        <button mat-stroked-button class="border-button" [matMenuTriggerFor]="menubutton">
            <mat-icon class="reverse-icon">bar_chart_outlined</mat-icon>
        </button>
        <mat-menu #menubutton="matMenu" class="mat-menu-view-contact">
            <button mat-menu-item  (click)="goToListContacts()">
                <mat-icon class="green">format_list_bulleted</mat-icon>
            </button>
            <button mat-menu-item>
                <mat-icon class="reverse-icon">bar_chart_outlined</mat-icon>
            </button>
        </mat-menu>
    </div>
</div>

<mat-divider class="mat-divider"></mat-divider>

<div class="container">
    <app-list-contact-double #listContactDouble [enterpriseSelect]="enterprise" [contactSelect]="contactSelect">
    </app-list-contact-double>
</div>


    
