<div class="operation-variables-filter">
  <app-loading></app-loading>

  <div class="operation-variables-filter__body">

    <div class="panelAll">
      <div class="panelAll-check">
        <mat-checkbox
          [(ngModel)]="allVariables"
          (change)="checkAll($event.checked)"
          [indeterminate]="this.operationVariablesService.someNotSelectedVariable"
        >
              Todos
        </mat-checkbox>
      </div>
    </div>

    <div class="expandList">
      <ng-container *ngFor="let variable of this.operationVariablesService.variableFamilyListWithData">
        <app-expansion-panel
          [title]="variable.idClientFamily"
          [expand]="false"
          [haveCheckbox]="true"
          [checked]="variable.selected"
          (sendCheck)="changeStateSelect($event)"
        >
          <ul class="list" >
            <li *ngFor="let item of variable.variableDTOList" >{{item.shortName}}</li>
          </ul>

        </app-expansion-panel>
      </ng-container>
    </div>

  </div>

  <div class="operation-variables-filter__buttons">
      <button mat-button class="ok" (click)="save()">Aplicar</button>
      <button mat-button class="cancel" (click)="clear()">Limpiar</button>
  </div>


</div>
