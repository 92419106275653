import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { HistoryGetDTO } from "src/app/pages/escom-list/models/history.get.dto";
import { historyDelete, historyList, historyReset } from "./escom-history.actions";
import { selectHistory } from "./escom-history.selectors";

@Injectable()
export class EscomHistoryFacade {

    constructor(private readonly store: Store) {}

    getHistory(request: HistoryGetDTO) {
        this.store.dispatch(historyList({request}));
    }

    selectHistoryData() {
        return this.store.select(selectHistory);
    }

    reset() {
        this.store.dispatch(historyReset());
    }

    deleteHistory(idItemCreditDocument: number) {
        this.store.dispatch(historyDelete({idItemCreditDocument}));
    }
}