<div class="table-container"
    [ngStyle]="{'height': tableHeight }" (scroll)="onScroll($event)" #scrollTable>
    <div class="table-container__header"
        [ngStyle]="{'min-width': minWidth}">
        <div *ngFor="let header of headers; let i = index" class="table-header-cell"
            [ngStyle]="{'width': header.width}">
            <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: header}">
            </ng-container>
        </div>
    </div>
    <div class="table-container__body"
        [ngStyle]="{'min-width': minWidth}">
        <ng-container *ngIf="data?.length > 0 else nofilter">
            <div *ngFor="let row of data" class="table-row">
                <div *ngFor="let header of headers;" class="table-cell"
                    [ngStyle]="{'width': header.width}">
                    <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: getName(row, header) }">
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #nofilter>
            <app-empty
              *ngIf="showEmpty"
              [description]="descriptionEmpty"
              [height]="heightEmpty"
            ></app-empty>
          </ng-template>
    </div>
</div>