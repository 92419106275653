import { createSelector } from "@ngrx/store";
import { CampaignState, selectCampaignState } from "../../campaign.store";

export const selectCampaignConfigurationState = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.configuration
) 

export const selectCampaignConfigurations = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.configuration.attributeData 
);

export const selectCampaignConfigurationsElement = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.configuration.elementData
);