import { createAction, props } from "@ngrx/store";
import { ParamsDTO } from "src/app/models";

export const productsList = createAction('[Products] List',
    props<{id: number}>()    
);
export const productsFailed = createAction('[Products] Failed',
    props<{payload: any}>()
);

export const productsSuccess = createAction('[Products] Products Success',
    props<{payload: ParamsDTO[]}>()
);
