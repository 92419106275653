import { createReducer, on} from "@ngrx/store";
import { QualitativeStateModel } from "./qualitative.model";
import { qualitativeSheetFailed, qualitativeSheetList, qualitativeSheetReset, qualitativeSheetSuccess } from "./qualitative.actions";

export const initialState: QualitativeStateModel = {
    data: {
        totalItems: 0,
        currentPage: 0,
        totalPages: 0,
        listCustomerRecord: []
    },
    loading: false,
    failed: false,
    loaded: false
};

const _qualitativeReducer = createReducer(initialState,
    on(qualitativeSheetList, state => {
        return { 
            ...state,
            loading: true,
            loaded: false
        }
    }),
    on(qualitativeSheetFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(qualitativeSheetSuccess, (state, { payload }) => {
        const previousData = state.data?.listCustomerRecord || [];
        const newData = payload?.listCustomerRecord || [];
        const data = [...previousData, ...newData];
        return {
            ...state,
            data: {
                ...payload,
                listCustomerRecord: data
            },
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(qualitativeSheetReset, state => state = initialState)
);

export function qualitativeReducer(state: any, action: any): QualitativeStateModel {
    return _qualitativeReducer(state, action);
}
