import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { productsList } from "./products.actions";
import { selectEnoteProducts, selectEnoteProductsData } from "./products.selectors";

@Injectable()
export class ProductsFacade {

    constructor(private readonly store: Store) {}
    
    getProducts(tableId: number) {
        this.store.dispatch(productsList({ id: tableId }));
    }

    selectEnoteProductsData() {
        return this.store.select(selectEnoteProductsData);
    }

    selectEnoteProducts() {
        return this.store.select(selectEnoteProducts);
    }

}