import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { financialEEFFList, financialEEFFReset } from "./financial-eeff.actions";
import { selectFinancialEeff } from "./financial-eeff.selectors";

@Injectable()
export class FinancialEeffFacade {

    constructor(private readonly store: Store) {}

    get(documentType: number, documentNumber: string) {
        this.store.dispatch(financialEEFFList({ documentType, documentNumber }));
    }

    selectFinancialEeff() {
        return this.store.select(selectFinancialEeff);
    }

    reset() {
        this.store.dispatch(financialEEFFReset());
    }

}