import { createSelector } from "@ngrx/store";
import { ClientState, selectClientState } from "../../client.store";

export interface FamilyClientListStateModel {
    data: any;
    loading: boolean;
    loaded: boolean;
    failed: boolean;
    error: any;
}

export const selectFamilyClientData = createSelector(
    selectClientState,
    (state: ClientState) => state.familyList
);