import { createAction, props } from "@ngrx/store";

export const newList = createAction('[New] List',
    props<{payload: any}>()
);
export const newFailed = createAction('[New] Failed',
    props<{payload: any}>()
);
export const newSuccess = createAction('[New] Success',
    props<{payload: any}>()
);

export const newReset = createAction(
    '[New] Reset'
);

