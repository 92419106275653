<div class="sidebar">
  <!--<div class="back-sidebar" *ngIf="background">

  </div> -->
  <div class="sidebar__content">

    <div class="bottom-menu">
      <img (click)="home()" class="pointer" src="assets/img/sidebar/logo-vpc.svg"  alt=""/>
      <mat-divider></mat-divider>
    </div>

    <div class="nav">
      <ng-container *ngFor="let menuItem of menuItems">
        <li (mouseover)="background = true" 
          (mouseout)="background=false"
          routerLinkActive="active" 
          class="dropdown hover"
          *ngIf="menuItem.flgOption != AccessModuleEnum.NO_ACCESS">
          <div class="section" (mouseover)="backOn=true;" (mouseout)="backOn=false">
            <div class="icon">
              <img alt="" [src]="'/assets/img/sidebar/' + menuItem.icon">
              <p>{{menuItem.title}}</p>
            </div>
          </div>

          <mat-divider></mat-divider>

          <ul class="subOption">
            <div style="margin-top: 52px"></div>
            <div *ngFor="let subItem of menuItem.subOp">
              <li *ngIf="subItem.flgOption != AccessModuleEnum.NO_ACCESS" ngClass="router-active" (click)="transi(subItem)">
                <div class="subitem-menu">
                  <a *ngIf="subItem.path !== 0; else secondOption">
                    <p class="subItem-font menu-list" 
                      [ngClass]="{'blue-active': subItem.name == selectedItem && !subItem.transition}">
                      {{subItem.name}}
                    </p>
                  </a>
                  <mat-icon class="blue blue-active"
                    *ngIf="subItem.subOp?.length > 0"
                    [ngClass]="subItem.transition ? 'state' : 'rotate'">
                    keyboard_arrow_down
                  </mat-icon>
                </div>

                <ul class="subOption2" *ngIf="subItem.subOp"
                  [ngClass]="subItem.transition ? 'transitionOF' : 'transitionON'" style="text-align: left; background: #F2F2F2">
                  <ng-container *ngFor="let subItem2 of subItem.subOp">
                    <li *ngIf="subItem2.flgOption != AccessModuleEnum.NO_ACCESS" 
                      style="display: flex; align-content: center; flex-wrap: wrap; margin-left: 12px">
                      <a [routerLink]="[subItem2.path]" 
                        style="font-weight: 500; color: #333333;">
                        <p class="subItem-font hover-border">
                          {{subItem2.name}}
                        </p>
                      </a>
                    </li>
                  </ng-container>
                </ul>

            </div>
          </ul>

          <!-- <ul class="subOption">
            <div style="margin-top: 52px"></div>
            <div *ngFor="let subItem of menuItem.subOp">
              <li ngClass="router-active" (click)="transi(subItem.nameO, subItem.path)">
                <div class="subitem-menu">
                  <a *ngIf="subItem.path !== 0; else secondOption">
                    <p class="subItem-font menu-list" [ngClass]="{'blue-active': subItem.nameO == selectedItem && !subItem.transition, 'menu-inactive': subItem.transition}">{{subItem.nameO}}</p>
                  </a>
                  <ng-template #secondOption>
                    <a>
                      <p class="subItem-font menu-list" [ngClass]="{'blue-active': subItem.nameO == selectedItem && !subItem.transition, 'menu-inactive': subItem.transition}">{{subItem.nameO}}</p>
                    </a>
                  </ng-template>
                  <mat-icon class="blue blue-active" [ngClass]="subItem.transition ? 'state' : 'rotate'">{{subItem.icon}}</mat-icon>
                </div>

                <ul class="subOption2" [ngClass]="subItem.transition ? 'transitionOF' : 'transitionON'" style="text-align: left; background: #F2F2F2">
                  <li *ngFor="let subItem2 of subItem.subOp2" style="display: flex; align-content: center; flex-wrap: wrap; margin-left: 12px">
                    <a [routerLink]="[subItem2.path]" style="font-weight: 500; color: #333333;">
                      <p class="subItem-font hover-border">{{subItem2.nameO}}</p>
                    </a>
                  </li>
                </ul>
              </li>
            </div>
          </ul> -->
        </li>
      </ng-container>
    </div>

  </div>


  <div class="nav-exit">
    <mat-divider></mat-divider>
    <div class="bottom-menu" (click)="logout()">
      <img alt="" src="assets/img/sidebar/ico-salir.svg" />
      <p class="font-salir">Salir</p>
    </div>
  </div>

</div>
