import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { LeadService } from 'src/app/services/lead.service';
import { MessageService } from '../../services/message.service';
import { TokenBO } from 'src/app/models';
import { DOCUMENT } from '@angular/common';
import { MatCalendarCellClassFunction, MatDatepickerInputEvent } from '@angular/material/datepicker';
/*import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { ElementRef, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IHttpPostMessageResponse } from 'http-post-message';
*/
declare var require: any
var csv = require("csvtojson");

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, OnDestroy, OnChanges {

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getDate();
      const numDate = cellDate.getDay();
      // Highlight the 1st and 20th day of each month.
      return numDate === 5  ? 'mat-calendar-body-active' : 'mat-calendar-body-disabled';
    }

    return '';
  };

  url: string;
  urlSafe: SafeResourceUrl;
  tipViewReport: number;
  filter: string;
  lstFilter: any[];
  lstBanca: any[];
  lstZonal: any[];
  lstJefe: any[];
  lstEjecutivo: any[];
  lstPeriodo: any[];
  lstArbolDiario: any[];
  lstPerpectivas: any[];
  lstIndicador: any[];
  lstColocacion: any[];
  lstProducto: any[];
  lstCredito: any[];
  lstMoneda: any[];
  lstPlazo: any[];
  lstAnalistas: any[];
  subOption: string;
  nameReport: string;
  clasActive: any;
  clasInactive: any;
  banca:string;
  zona:string;
  jefe: string;
  ejecu: string;
  periodo: string;
  perspectiva: string;
  indicador: string;
  perfil: string;
  colocacion: string;
  producto: string;
  credito: string;
  moneda: string;
  plazo: string;
  analista: string;

  minDate: Date;
  maxDate: Date;
  userLogn: any;
  fechaSaldo: Date;

  flgInicio: boolean;

  constructor(public dialog: MatDialog, private leadService: LeadService,
    private messageService: MessageService, public sanitizer: DomSanitizer,
    private authservice: AuthService,
    @Inject(DOCUMENT) document: any, /*private element: ElementRef<HTMLDivElement>*/) { 

      /*this.reportConfig = {
        type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: 'H4sIAAAAAAAEAB2Tx66DVgBE_-VtiWSqgUhZ0A2Xbqp3YC69d4jy73nJ_mgWZ2b-_rGTqx2S7OfPn-5oL40vBGqtkNrU13ThlEb6eFb-pgSbBCV-AzvkBgXtzbpdpC0SiMGYHw9wIt_cmVJU0roHWithU5u8gjhY8SV8U15wbHq97kRzdq5_9xa1Ryjq200at8V8UcssPQYIfXU-XTeBxzLshSTf-pCe50S2fEjCcp5vzjlgud-zZZdltr-jacOdzY6-hTWziqV-UfM7EQf_ICsxH0GrxrWBIvzGcgtu2fuhqh2rjUut5tvbW2jEpJEesUIG1ojW0KVkldyNoaVAqwHxGGkq1ihEvnLSDsvm4eGbDa-WlkW39w06q3dRe0Y6RnLpRMwc55zXdqR8PgVHjdNQfcHPFl0YZTWExMYoqXojURNp2xinRJirjLjCOGVFkXZuE-Mwo-7emPUBna_KMzNGim9jGCPK1HNXMd5IwnSKEnfVXILxyS8iJmA9hcEpbykVBWGD6AzP-5hvg1io-b7kzE_Ncam0EM-s62VQAnaVtkNDusAH_ta7fuCenwxkUd1yl-HN_p2zlc5-USpwiVbF8HTqDhKn6Z49MwJ0Ab40hbysOwBqytdzAE-bpp_HcTG1Srj8KqRU5I5JS_YvkZCIjGvRjlLjV_CN1iTcCc-_Fs0s9anc4jsXA76v9nbNn6_z3O6qcLwgGTuvUhM4ekQnu_eT0TdjzZ5te0MpOInv1O97PyMGuTWBU3biTY7E_NGLKrpgvhbCSleHzsWF8NIMWkzRuP9uAXKU6QO5ze08QbKUI1R4SQGdPMbKMHXk3YyZK3jGSE_2hadjhxDh7BXzipwvSJ_IIhIHvNac9UXct2-76il00nFXR_viC7CDsO83WaXR6Q9p-k5__vgR5mtcBwCv3zudunzjSN7axvrQwksOwCekfGGVFrpzs5r0quZZUvHq633XuvZspcsdF8aOi5CkVNJIOJMNp3Yfz4lCItvVs-XwBvfAv9yxxIPC7I_EldJK8x1lKyDNaFkSyQwj-P0WF8yrLHC-ZfZ19hW59_owTYSrVOAdgcz--o-19lSVblf5knQ7AYm7gytxlTeEg_yYJ5qlb2AShmzT6h3zpw_tEhUuc8mVpveU65ONYAckqF_ERLwS-dnTmLGmpsPRTTyUH_rUKD9G0FLhcccznaT4XEu1m-ON1pY-y3M9J1cnZGJDPQWWHgK6N9xWRQtFyO_V93lW_aSt3uPNdQZrs62C8imUjfvrr_80X2MJZzX4tSwj_iCIZyizbHKstYmF4bc6_qfeVdEn6zbDX2z-HG8GHaOFDYE8fyd0S3mhej05d2h99YM-Ua2KV9w3ppO3nsiwP_VTnyzsdwixS-lGxTvIXB-6Kcv8pxk97Rgiee_HOcVYh4ikCCrWnvh7H6IpjLh9OZZyy1EtXAmJJpIIBFrbUCsqKNZdiBOiV53GRIBMdO8jBodDiRqqy8zATYXWCM1luW6ffOn49RvyLMjEA5P8Jt8qH1f2YwAGlrfLhIslh_fimLJ8TWJ-VHaZ045rZoqWqIp5IwB-T82kMqWNidFIPbLwWRfKAtWNOoAb4ErGvOFhahAXhyERyMX1DXjFPki6KFS95bDCoXr1phpJMUYh2_i6t-9qM_gqmNRn_6-Mf_4F5GMRRUIGAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUNFTlRSQUwtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJleHAiOjE2Nzk1OTMwNzksImFsbG93QWNjZXNzT3ZlclB1YmxpY0ludGVybmV0Ijp0cnVlfQ==',
      embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=4ca87f85-6745-4953-b359-aea36ee7c291&groupId=8894dd44-fdab-4d5f-9e97-4f3fc3b04ff4&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUNFTlRSQUwtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19',
      id: '4ca87f85-6745-4953-b359-aea36ee7c291',
      permissions: models.Permissions.Read,
      settings: {
          panes: {
              filters: {
                  visible: true
              },
              pageNavigation: {
                  visible: true
              }
          }
      }
      };*/

      this.flgInicio = true;
      this.clasActive = {
        "color":"#05BE50",
        "background":"#abe2ab85",
        "border-color":"#05BE50",
        "margin-right":"4px"
      };
      this.clasInactive = {
        "margin-right":"4px"
      }
      if(localStorage.getItem("usuario")){
        this.userLogn = JSON.parse(localStorage.getItem("usuario")!);
      }
      const date = new Date();
      const currentYear = date.getFullYear();
      let currentMonth = date.getMonth();
      let currenDay = date.getDate();
      this.minDate = new Date(currentYear - (currentMonth>1?0:1), 0, 1);
      this.maxDate = new Date(currentYear, currentMonth, currenDay);
      this.fechaSaldo = new Date();
      if(date.getDay()<3){
        this.fechaSaldo.setDate(currenDay-(date.getDay())-2);
      }else{
        this.fechaSaldo.setDate(currenDay-2);
      }

      this.nameReport = "";
      this.url ="";
      this.urlSafe ="";
      
      //this.viewReport("");
      this.tipViewReport = 0;
      this.lstFilter = [];
      this.filter = "";
      this.lstBanca = [];
      this.lstZonal = [];
      this.lstJefe = [];
      this.lstEjecutivo = [];
      this.lstPeriodo = [];
      this.lstArbolDiario = [];
      this.lstAnalistas = [];
      this.lstColocacion = [
        {key:"COL. DIRECTAS", val:"COL. DIRECTAS"},
        {key:"COL. INDIRECTAS", val:"COL. INDIRECTAS"},
        {key:"DEPOSITOS", val:"DEPOSITOS"}
      ];
      this.lstProducto=[
        {key:"CORTO PLAZO", val:"CORTO PLAZO"},
        {key:"MEDIANO PLAZO", val:"MEDIANO PLAZO"},
      ];
      this.lstCredito = [
        {key:"VIGENTE", val:"VIGENTE"},
        {key:"NO VIGENTE", val:"NO VIGENTE"},
      ];
      this.lstMoneda = [
        {key:"PSS", val:"Soles"},
        {key:"USD", val:"Dólares"},
      ];
      this.lstPlazo = [
        {key:"CORTO PLAZO", val:"CORTO PLAZO"},
        {key:"MEDIANO PLAZO", val:"MEDIANO PLAZO"},
      ];
      this.subOption = "";
      this.banca="";
      this.zona="";
      this.jefe="";
      this.ejecu="";
      this.perspectiva="";
      this.indicador="";
      this.perfil="";
      this.colocacion="";
      this.producto="";
      this.credito="";
      this.moneda="";
      this.plazo="";
      this.analista="";
      
      this.lstPerpectivas = [
        { PERSPECTIVA: "1. Financiero", INDICADOR: "CAR" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Colocaciones Directas" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Colocaciones Directas - Corto Plazo" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Colocaciones Directas (CP + Leasing)" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Depósitos No Remunerados" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Depósitos Totales" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Ingreso Totales" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Ingresos Neto de Provisiones" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "INOF + ROF" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "INOF Cash" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "MF Directas + ROF + INOF sin Fincorp/Fies" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "ROF" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Spread (CP + Leasing)" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Spread Directas" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Spread Nuevos Desembolsos" },
        { PERSPECTIVA: "1. Financiero", INDICADOR: "Vencidos" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Actualización EEFF" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Cash In/Out (Volumen)" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Clientes Digital First" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Clientes Nuevos" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Consistencia" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Cross Sell" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Cuenta Sueldo" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Cuenta Sueldo (Monto)" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Fin Ventas (Saldos)" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Financ BPE" },
        { PERSPECTIVA: "2. Ecosistema", INDICADOR: "Vigencia de líneas" },
        { PERSPECTIVA: "3. Procesos", INDICADOR: "Cercanía" },
        { PERSPECTIVA: "3. Procesos", INDICADOR: "EEFF" },
        { PERSPECTIVA: "3. Procesos", INDICADOR: "Tiempos Proc. Cred. E2E" },
        { PERSPECTIVA: "3. Procesos", INDICADOR: "Tiempos WIO E2E" },
        { PERSPECTIVA: "4. Aprendizaje", INDICADOR: "Clima Laboral" },
        { PERSPECTIVA: "5. Acelerador", INDICADOR: "Calidad de Gestión" },
        { PERSPECTIVA: "5. Acelerador", INDICADOR: "NPS" }];

      
      
      this.lstIndicador = [];
      let path = document.location.href;
      if(path.indexOf("ficha360")>0){
        this.viewReport("", true);
        this.nameReport = "";
        this.tipViewReport = 3;
      }
      if(path.indexOf("tableros")>0){
        this.subOption = "bsc";
        this.nameReport = "Tableros";
        this.tipViewReport = 1;
        this.obtenerPeriodo();
        this.obtenerFiltros();
      }
      if(path.indexOf("saldosDiario")>0){
        this.subOption = "resumen";
        this.nameReport = "Saldos Diarios";
        this.colocacion = "COL. DIRECTAS";
        this.tipViewReport = 2;
        this.obtenerPeriodo();
        this.obtenerFiltros();
      }
      
      
    }
  
  stateList: any[] = [{idState:1,updateView:1,total:0,name:'Pendiente'},
                      {idState:2,updateView:1,total:0,name:'Revisado por el EN'},
                      {idState:3,updateView:1,total:0,name:'En negociacion'},
                      {idState:4,updateView:1,total:0,name:'Activados'}];

  public totalsum: number = 0;
  public terminoChild: string = '';
  public terminoChildAux: string = '';
  show1: boolean = false;
  show2: boolean = true;
  show3: boolean = true;
  closer: boolean = true;
  toggleGreen: boolean = false;
  
  viewReport(filter:string, flgToken: boolean){
    let path = document.location.href;
    if(path.indexOf("ficha360")>0){
      this.url = "https://s275vp3:8443/BOE/OpenDocument/opendoc/openDocument.jsp?sIDType=CUID&iDocID=AZwE8g0lpiNIkAKbxvCJlUQ&sInstance=last&sRefresh=N";
      this.tipViewReport = 3;
    }
    if(path.indexOf("tableros")>0){
      this.url = "https://s275vp3:8443/BOE/OpenDocument/opendoc/openDocument.jsp?sIDType=CUID&iDocID=AWZ9nSfjszJCs0fwSIVMDHY&sInstance=last&sReportMode=Light";
      this.tipViewReport = 1;
    }
    if(path.indexOf("saldosDiario")>0){
      this.url = "https://s275vp3:8443/BOE/OpenDocument/opendoc/openDocument.jsp?sIDType=CUID&iDocID=Abq6WsuY5sNMvfuvuUAhORc&sInstance=last&sReportMode=Light";
      if(this.subOption=="clientes"){
        this.url = "http://s275vp3:8080/BOE/OpenDocument/opendoc/openDocument.jsp?sIDType=CUID&iDocID=AapG3_BMHNFAtbQOprMiY8M&sInstance=last&sReportMode=Light";
      }
      this.tipViewReport = 2;
    }
    if(localStorage.getItem("tokenBO")){
      console.log("Existe Token");
      let token: TokenBO = JSON.parse(localStorage.getItem("tokenBO")!);
      console.log("token: ", token);
      let dateNow = new Date();
      let dateExpira = new Date(token.fechaExpira);
      if(dateExpira > dateNow){
        console.log("Token NO Expiro");
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url+filter+(flgToken?"&token="+token.token:""));
      }else{
        console.log("Token Expiro");
        this.loginBO();
      }
      
    }else{
      console.log("No existe Token");
      this.loginBO();
    }
  }

  changeSubPath(subOption: string){
    this.subOption = subOption;
    if(subOption == "resumen"){
      this.lstEjecutivo=[];
      this.lstJefe=[];
      this.lstZonal=[];
      this.lstAnalistas=[];
      this.banca="";
      this.zona="";
      this.ejecu="";
      this.jefe="";
      this.indicador="";
      this.perfil="";
      this.perspectiva="";
      this.lstFilter=[];
      this.colocacion="";
      this.producto="";
      this.credito="";
      this.moneda="";
      this.plazo="";
      this.analista="";
    }
    if(subOption == "clientes"){
      this.lstEjecutivo=[];
      this.lstJefe=[];
      this.lstZonal=[];
      this.lstAnalistas=[];
      this.banca="";
      this.zona="";
      this.ejecu="";
      this.jefe="";
      this.indicador="";
      this.perfil="";
      this.perspectiva="";
      this.lstFilter=[];
      this.colocacion="";
      this.producto="";
      this.credito="";
      this.moneda="";
      this.plazo="";
      this.analista;
    }
    this.viewReport("", true);
  }

  isBlank(val: any): boolean{
    if(val==null || val==""){
      return true;
    }
    return false;
  }

  async addFilterList(key:string, val:string){
    let nuevo = true;
    for (let index = 0; index < this.lstFilter.length; index++) {
      const element = this.lstFilter[index];
      if(element.key == key){
        this.lstFilter[index].val = val;
        element.val = val;
        nuevo = false;
      }
    }
    if(nuevo){
      this.lstFilter.push({key:key,val:val});
    }
  }

  async removeFilterList(key:string){
    for (let index = 0; index < this.lstFilter.length; index++) {
      const element = this.lstFilter[index];
      if(element.key == key){
        this.lstFilter.splice(index,1);
        break;
      }
    }
  }

  async addFilter(key:string, val:string){
    if(key==""){
      return;
    }
    if(val == "Tablero"){
      this.subOption ="bsc";
      await this.removeFilterList("lsSbanca");
      await this.removeFilterList("lsSzonal");
      await this.removeFilterList("lsSjefe");
      await this.removeFilterList("lsStipopersona");
      await this.removeFilterList("lsStipoIndicador");
      await this.removeFilterList("lsSindicador");
      if(this.banca!="" && this.banca.length>0){
        await this.addFilterList("lsSnombre",encodeURIComponent(this.banca));
      }
      if(this.zona!="" && this.zona.length>0){
        await this.addFilterList("lsSnombre",encodeURIComponent(this.zona));
      }
      if(this.jefe!="" && this.jefe.length>0){
        await this.addFilterList("lsSnombre",encodeURIComponent(this.jefe));
      }
      if(this.ejecu!="" && this.ejecu.length>0){
        await this.addFilterList("lsSnombre",encodeURIComponent(this.ejecu));
      }
      if(this.analista!="" && this.analista.length>0){
        await this.addFilterList("lsSnombre",encodeURIComponent(this.analista));
      }
      await this.addFilterList("sReportName",'Tablero');
    }
    if(val == "Ranking"){
      this.subOption ="Ranking";
      await this.removeFilterList("lsSnombre");
      await this.removeFilterList("lsStipoIndicador");
      await this.removeFilterList("lsSindicador");
      await this.addFilterList("lsSbanca",encodeURIComponent(this.banca));
      await this.addFilterList("lsSzonal",encodeURIComponent(this.zona));
      await this.addFilterList("lsSjefe",encodeURIComponent(this.jefe));
      if(this.perfil!="" && this.perfil.length>0){
        await this.addFilterList("lsStipopersona",encodeURIComponent(this.perfil));
      }
      await this.addFilterList("sReportName",'Ranking');
    }
    if(val == "Ranking%20x%20Indicador"){
      this.subOption ="RankingxIndicador";
      await this.removeFilterList("lsSnombre");
      await this.addFilterList("lsSbanca",encodeURIComponent(this.banca));
      await this.addFilterList("lsSzonal",encodeURIComponent(this.zona));
      await this.addFilterList("lsSjefe",encodeURIComponent(this.jefe));
      if(this.perfil!="" && this.perfil.length>0){
        await this.addFilterList("lsStipopersona",encodeURIComponent(this.perfil));
      }
      if(this.perspectiva!="" && this.perspectiva.length>0){
        await this.addFilterList("lsStipoIndicador",encodeURIComponent(this.perspectiva));
      }
      if(this.indicador!="" && this.indicador.length>0){
        await this.addFilterList("lsSindicador",encodeURIComponent(this.indicador));
      }
      await this.addFilterList("sReportName",'Ranking%20x%20Indicador');
    }
    
    if((val== null || val=="") && key=="lsSnombre"){
      if(this.analista==null || this.analista ==""){
        val = this.ejecu;
      }
      if(this.ejecu==null || this.ejecu ==""){
        val = this.jefe;
      }
      if(this.jefe==null || this.jefe ==""){
        val = this.zona;
      }
      if(this.zona==null || this.zona ==""){
        let path = document.location.href;
        if(path.indexOf("saldosDiario")>0){
          if(this.banca=="BC"){
            val="BANCA CORPORATIVA";
          }
          if(this.banca=="BEP"){
            val="BANCA EMPRESA PROVINCIA";
          }
          if(this.banca=="BEL"){
            val="BANCA EMPRESA LIMA";
          }
          if(this.banca=="BE"){
            val="BANCA EMPRESA";
          }
          if(this.banca=="BPE"){
            val="BANCA PEQUEÑA EMPRESA";
          }
        }else{
          val = this.banca;
        }
        
      }
    }

    this.filter = "";
    let nuevo = true;

    for (let index = 0; index < this.lstFilter.length; index++) {
      const element = this.lstFilter[index];
      if(element.key == key){
        this.lstFilter[index].val = val;
        element.val = val;
        nuevo = false;
      }
      this.filter = this.filter + "&" + element.key + "=" + element.val;
    }
    if(nuevo){
      this.filter = this.filter + "&" + key + "=" + val;
      this.lstFilter.push({key:key,val:val});
    }
    if(key=="sReportName"){
      this.viewReport(this.filter,true);
    }
    
  }

  close() {
    this.closer = !this.closer;
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  

  ngOnDestroy(): void {
  }
  ngOnInit(): void {
   
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  isNumber(str: string): boolean {
    if (str.trim() != str) {
      return false;
    }
  

    if (str.trim() === '') {
      return false;
    }
  
    return !Number.isNaN(Number(str));
  }

  searchLeadParent(termino: string) {

    let numberValidate: boolean =this.isNumber(termino);


    if(termino.length == 2 || termino.length == 1){
      this.messageService.showWarning("No se puede filtrar con menos de 3 digitos", 'top right');
      return;
    }
    
    if(termino.length > 11 && numberValidate){
      this.messageService.showWarning("No se puede filtrar por valores numericos con mas de 11 digitos", 'top right');
      return;
    }

    if (termino.length < 10 && numberValidate) {
      let ceros = "";
      for(let i = 0 ; i<10-termino.length;i++){
        ceros+= "0";
      }
      this.terminoChildAux = ceros + termino;
    }
    this.terminoChild = this.terminoChildAux;
  }

  togglebtn(tog: number) {
    if (tog === 1) {
      this.show1 = true;
      this.show2 = true;
      this.show3 = false;
      this.toggleGreen1();
    }
    if (tog === 2) {
      this.show1 = true;
      this.show2 = false;
      this.show3 = true;
      this.openDialog()
    }
    if (tog === 3) {
      this.show1 = false;
      this.show2 = true;
      this.show3 = true;
      this.toggleGreen1();
    }

  }

  toggleGreen1() {
    this.toggleGreen = !this.toggleGreen;
  }

  filtrarStates(state:any) {
    state.updateView += 1;
    state.idState = state.idState;
  }

  loginBO() {
    let tokenBO = new TokenBO();
    console.log("usuario: ",this.userLogn);
    this.authservice.generarTokenBO(this.userLogn.username,this.userLogn.password)
      .subscribe((resp: any) => {
        if(resp.token != undefined){
          let token = encodeURIComponent(resp.token);
          tokenBO.token = token;
          tokenBO.tokenNoUrl = resp.token;
          tokenBO.fechaExpira = this.addHoursToDate(new Date(),2);
          this.getArbolDiario(resp.token);
          localStorage.setItem("tokenBO",JSON.stringify(tokenBO));
          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url+"&token="+token);
        }
      });
  }

  getArbolDiario(token:string){
    this.authservice.getArbol(token)
          .subscribe((resp: any) => {
            let contenido = resp.replace('"Fecha Dt";"Banca Cd";"Zonal Desc";"Nombre Jefe Desc";"Encargado Desc";"Analista";"Cod Sect Uniq En Cd";"Cod Sect Uniq Cd";"Login Val";"Cod Corto Cd";"Cod Sect Largo Cd";"Codigo Jefe Cd";"Tipo Cd";"Segmento"',           
            '"FechaDt";"BancaCd";"ZonalDesc";"NombreJefeDesc";"EncargadoDesc";"Analista";"CodSectUniqEnCd";"CodSectUniqCd";"LoginVal";"CodCortoCd";"CodSectLargoCd";"CodigoJefeCd";"TipoCd";"Segmento"');
            contenido = contenido.replace('"Fecha Dt";"Banca Cd";"Zonal Desc";"Nombre Jefe Desc";"Encargado Desc";"Cod Sect Uniq En Cd";"Cod Sect Uniq Cd";"Login Val";"Cod Corto Cd";"Cod Sect Largo Cd";"Codigo Jefe Cd";"Tipo Cd";"Segmento"',
            '"FechaDt";"BancaCd";"ZonalDesc";"NombreJefeDesc";"EncargadoDesc";"CodSectUniqEnCd";"CodSectUniqCd";"LoginVal";"CodCortoCd";"CodSectLargoCd";"CodigoJefeCd";"TipoCd";"Segmento"'
            );
            
              csv({
                    noheader:false,
                    output: "json",
                    "delimiter":";"
                })
                .fromString(contenido)
                .then((rows:any)=>{ 
                    this.lstArbolDiario = rows;
                    this.obtenerBanca();
                    this.obtenerFiltroEjecutivo(this.flgInicio);
                    console.log("Lista Arbol: ",rows);
                });
          });
  }

  async onBancaChangeInicial(item:any){
    this.lstZonal =[];
    for (let index = 0; index < this.lstArbolDiario.length; index++) {
      const element = this.lstArbolDiario[index];
      if(item.value == element.BancaCd){
        if(!this.validarElemento(this.lstZonal, element.ZonalDesc)){
          this.lstZonal.push({key: element.ZonalDesc, val: element.ZonalDesc});
        }
      }
    }
  }

  async zonaChangeInicial(item:any){
    this.lstJefe =[];
    for (let index = 0; index < this.lstArbolDiario.length; index++) {
      const element = this.lstArbolDiario[index];
      if(item.value == element.ZonalDesc){
        if(!this.validarElemento(this.lstJefe, element.NombreJefeDesc)){
          this.lstJefe.push({key: element.NombreJefeDesc, val: element.NombreJefeDesc});
        }
      }
    }
    if(this.lstJefe.length==0){
      this.lstEjecutivo =[];
      this.lstAnalistas=[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.ZonalDesc){
          if(!this.validarElemento(this.lstEjecutivo, element.EncargadoDesc)){
            this.lstEjecutivo.push({key: element.EncargadoDesc, val: element.EncargadoDesc});
          }
        }
      }
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.ZonalDesc){
          if(!this.validarElemento(this.lstAnalistas, element.Analista)){
            this.lstAnalistas.push({key: element.Analista, val: element.Analista});
          }
        }
      }
    }
    
  }

  async jefeChangeInicial(item:any){
    
      this.lstEjecutivo =[];
      this.lstAnalistas=[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.NombreJefeDesc){
          if(!this.validarElemento(this.lstEjecutivo, element.EncargadoDesc)){
            this.lstEjecutivo.push({key: element.EncargadoDesc, val: element.EncargadoDesc});
          }
        }
      }
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.NombreJefeDesc){
          if(!this.validarElemento(this.lstAnalistas, element.Analista)){
            this.lstAnalistas.push({key: element.Analista, val: element.Analista});
          }
        }
      }
    
  }

  async obtenerFiltroEjecutivo(flgInicio: boolean){
    let path = document.location.href;
    
    if(flgInicio){
      this.banca="";
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(element.LoginVal == this.userLogn.username){
          this.banca = element.BancaCd;
          if(path.indexOf("tableros")>0){
            if(element.BancaCd != "BC" && element.BancaCd != "BE"){
              break;
            }
          }
          await this.onBancaChangeInicial({value: this.banca});
          if(path.indexOf("tableros")>0){
            //if(this.subOption=="bsc"){
              this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(element.BancaCd)});
            //}
            this.zona = element.ZonalDesc;
            this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(element.ZonalDesc)});
            await this.zonaChangeInicial({value: this.zona});
            if(element.NombreJefeDesc!=null && element.NombreJefeDesc.trim().length>0){
              this.jefe = element.NombreJefeDesc;
              this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(element.NombreJefeDesc)});
              await this.jefeChangeInicial({value: this.jefe});
            }
            this.ejecu = element.EncargadoDesc;
            this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(element.EncargadoDesc)});
          }
          if(path.indexOf("saldosDiario")>0){
            this.addFilterList("lsSnombre",encodeURIComponent(element.BancaCd));
            this.zona = element.ZonalDesc;
            this.addFilterList("lsSnombre",encodeURIComponent(element.ZonalDesc));
            await this.zonaChangeInicial({value: this.zona});
            if(element.NombreJefeDesc!=null && element.NombreJefeDesc.trim().length>0){
              this.jefe = element.NombreJefeDesc;
              this.addFilterList("lsSnombre",encodeURIComponent(element.NombreJefeDesc));
              await this.jefeChangeInicial({value: this.jefe});
            }
            this.ejecu = element.EncargadoDesc;
            this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(element.EncargadoDesc)});
          }
          
          break;
        }
      }

      if(this.banca==""){
        this.banca="BC";
        await this.onBancaChangeInicial({value: this.banca});
        if(path.indexOf("tableros")>0){
          this.lstFilter.push({key:"lsSbanca",val:encodeURIComponent(this.banca)});
          if(this.lstZonal != null && this.lstZonal.length>0){
            this.zona = this.lstZonal[0].key;
            this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(this.zona)});
            //this.addFilterList("lsSnombre",encodeURIComponent(this.zona));
            await this.zonaChangeInicial({value: this.zona});
          }
        }
        if(path.indexOf("saldosDiario")>0){
          if(this.lstZonal != null && this.lstZonal.length>0){
            //this.zona = this.lstZonal[0].key;
            //this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent(this.zona)});
            //this.addFilterList("lsSnombre",encodeURIComponent(this.zona));
            //await this.zonaChangeInicial({value: this.zona});
          }
        }
        
      }
      
      if(path.indexOf("ficha360")>0){
        //this.viewReport("", true);
        //this.nameReport = "";
        return;
      }
      if(path.indexOf("tableros")>0){
        //this.addFilter('sReportName','Tablero');
        
        this.lstFilter.push({key:"lsSperiodo",val: this.periodo});
        if(flgInicio){
          this.subOption = "bsc";
          this.nameReport = "Tableros";
          this.lstFilter.push({key:"sReportName",val:'Tablero'});
        }
        
      }
      if(path.indexOf("saldosDiario")>0){
        //this.viewReport("", true);
        if(flgInicio){
          this.subOption = "resumen";
          this.nameReport = "Saldos Diarios";
        }
        this.colocacion = "COL. DIRECTAS";
        this.lstFilter.push({key:"lsStipoproducto",val:encodeURIComponent("COL. DIRECTAS")});
        //this.addFilterList("lsStipoproducto",encodeURIComponent("COL. DIRECTAS"));
        let mes = this.fechaSaldo.getMonth();
        let year = this.fechaSaldo.getFullYear();
        let fecha = ((mes?mes:0)+1)+"/"+this.fechaSaldo.getDate()+"/"+((year?year:0)-2000);
        this.lstFilter.push({key:"lsSfecha",val:encodeURIComponent(fecha)});
  
        if(this.banca=="BC"){
          //this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent("BANCA CORPORATIVA")});
          this.addFilterList("lsSnombre",encodeURIComponent("BANCA CORPORATIVA"));
        }
        if(this.banca=="BEP"){
          //this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent("BANCA EMPRESA PROVINCIA")});
          this.addFilterList("lsSnombre",encodeURIComponent("BANCA EMPRESA PROVINCIA"));
        }
        if(this.banca=="BEL"){
          //this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent("BANCA EMPRESA LIMA")});
          this.addFilterList("lsSnombre",encodeURIComponent("BANCA EMPRESA LIMA"));
        }
  
        if(this.banca=="BE"){
          //this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent("BANCA EMPRESA")});
          this.addFilterList("lsSnombre",encodeURIComponent("BANCA EMPRESA"));
        }
        if(this.banca=="BPE"){
          //this.lstFilter.push({key:"lsSnombre",val:encodeURIComponent("BANCA PEQUEÑA EMPRESA")});
          this.addFilterList("lsSnombre",encodeURIComponent("BANCA PEQUEÑA EMPRESA"));
        }
      }
    }else{
      if(path.indexOf("tableros")>0){
        if(this.subOption=="bsc"){
          this.removeFilterList("lsSbanca");
          this.removeFilterList("lsSzonal");
          this.removeFilterList("lsSjefe");
          this.removeFilterList("lsStipopersona");
          this.removeFilterList("lsStipoIndicador");
          this.removeFilterList("lsSindicador");
          if(this.banca!="" && this.banca.length>0){
            this.addFilterList("lsSnombre",encodeURIComponent(this.banca));
          }
          if(this.zona!="" && this.zona.length>0){
            this.addFilterList("lsSnombre",encodeURIComponent(this.zona));
          }
          if(this.jefe!="" && this.jefe.length>0){
            this.addFilterList("lsSnombre",encodeURIComponent(this.jefe));
          }
          if(this.ejecu!="" && this.ejecu.length>0){
            this.addFilterList("lsSnombre",encodeURIComponent(this.ejecu));
          }
          if(this.analista!="" && this.analista.length>0){
            this.addFilterList("lsSnombre",encodeURIComponent(this.analista));
          }

        }
        if(this.subOption=="Ranking"){
          this.removeFilterList("lsSnombre");
          this.removeFilterList("lsStipoIndicador");
          this.removeFilterList("lsSindicador");
          this.addFilterList("lsSbanca",encodeURIComponent(this.banca));
          this.addFilterList("lsSzonal",encodeURIComponent(this.zona));
          this.addFilterList("lsSjefe",encodeURIComponent(this.jefe));
          if(this.perfil!="" && this.perfil.length>0){
            this.addFilterList("lsStipopersona",encodeURIComponent(this.perfil));
          }
        }

        if(this.subOption=="RankingxIndicador"){
          this.removeFilterList("lsSnombre");
          this.addFilterList("lsSbanca",encodeURIComponent(this.banca));
          this.addFilterList("lsSzonal",encodeURIComponent(this.zona));
          this.addFilterList("lsSjefe",encodeURIComponent(this.jefe));
          if(this.perfil!="" && this.perfil.length>0){
            this.addFilterList("lsStipopersona",encodeURIComponent(this.perfil));
          }
          if(this.perspectiva!="" && this.perspectiva.length>0){
            this.addFilterList("lsStipoIndicador",encodeURIComponent(this.perspectiva));
          }
          if(this.indicador!="" && this.indicador.length>0){
            this.addFilterList("lsSindicador",encodeURIComponent(this.indicador));
          }
          
        }
        
      }
      if(path.indexOf("saldosDiario")>0){
        this.removeFilterList("lsSbanca");
        this.removeFilterList("lsSzonal");
        this.removeFilterList("lsSjefe");
        this.removeFilterList("lsStipopersona");
        this.removeFilterList("lsStipoIndicador");
        this.removeFilterList("lsSindicador");
        if(this.banca!="" && this.banca.length>0){
          this.addFilterList("lsSnombre",encodeURIComponent(this.banca));
        }
        if(this.zona!="" && this.zona.length>0){
          this.addFilterList("lsSnombre",encodeURIComponent(this.zona));
        }
        if(this.jefe!="" && this.jefe.length>0){
          this.addFilterList("lsSnombre",encodeURIComponent(this.jefe));
        }
        if(this.ejecu!="" && this.ejecu.length>0){
          this.addFilterList("lsSnombre",encodeURIComponent(this.ejecu));
        }
        if(this.analista!="" && this.analista.length>0){
          this.addFilterList("lsSnombre",encodeURIComponent(this.analista));
        }
      }
    }
    
    this.filter = "";
    for (let index = 0; index < this.lstFilter.length; index++) {
      const element = this.lstFilter[index];
      this.filter = this.filter + "&" + element.key + "=" + element.val;
    }
    await this.obtenerFiltroOtros();
    this.flgInicio = false;
  }

  async obtenerFiltroOtros(){
    this.searchView();
  }

  obtenerBanca(){
    let path = document.location.href;
    for (let index = 0; index < this.lstArbolDiario.length; index++) {
      const element = this.lstArbolDiario[index];
      if(!this.validarElemento(this.lstBanca,element.BancaCd)){
        if(path.indexOf("tableros")>0){
          if(element.BancaCd == "BC" || element.BancaCd == "BE"){
            this.lstBanca.push({key: element.BancaCd, val: element.BancaCd});
          }
        }else{
          this.lstBanca.push({key: element.BancaCd, val: element.BancaCd});
        }
        
      }
    }
  }

  obtenerFiltros(){

    if(localStorage.getItem("tokenBO")){
      console.log("Existe Token");
      let token: TokenBO = JSON.parse(localStorage.getItem("tokenBO")!);
      console.log("token: ", token);
      let dateNow = new Date();
      let dateExpira = new Date(token.fechaExpira);
      if(dateExpira > dateNow){
        console.log("Token NO Expiro");
        this.getArbolDiario(token.tokenNoUrl);
        
      }
    }else{
      this.loginBO();
    }
  }

  validarElemento(list:any[], val:string): boolean{
    if(val==null || val.trim().length==0){
      return true;
    }
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if(element.key == val){
        return true;
      }
    }
    return false;
  }

  addHoursToDate(objDate: Date, intHours: number) {
    var numberOfMlSeconds = objDate.getTime();
    var addMlSeconds = (intHours * 60) * 60000;
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
    return newDateObj;
  }
  onFilterChangeDate(item:MatDatepickerInputEvent<Date>, filter:string, type: string){
    if(item == undefined){
      this.addFilter(filter, encodeURIComponent(""));
    }else{
      let mes = item.value?.getMonth();
      let year = item.value?.getFullYear();
      let fecha = ((mes?mes:0)+1)+"/"+item.value?.getDate()+"/"+((year?year:0)-2000);
      this.addFilter(filter, encodeURIComponent(fecha));
    }
  }

  onFilterChange(item:any, filter:string, type: string){
    console.log(item);
    if(type=="bancaLarge"){
      let val = "";
      if(item.value=="BC"){
        val="BANCA CORPORATIVA";
      }
      if(item.value=="BEP"){
        val="BANCA EMPRESA PROVINCIA";
      }
      if(item.value=="BEL"){
        val="BANCA EMPRESA LIMA";
      }
      if(item.value=="BE"){
        val="BANCA EMPRESA";
      }
      if(item.value=="BPE"){
        val="BANCA PEQUEÑA EMPRESA";
      }
      this.addFilter(filter, encodeURIComponent(val));
    }else{
      this.addFilter(filter, encodeURIComponent(item.value));
    }
    
    if(type == "zonal"){
      this.lstJefe =[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.ZonalDesc){
          if(!this.validarElemento(this.lstJefe, element.NombreJefeDesc)){
            this.lstJefe.push({key: element.NombreJefeDesc, val: element.NombreJefeDesc});
          }
        }
      }
      this.lstEjecutivo=[];
      this.lstAnalistas=[];
      this.ejecu="";
      this.jefe="";
      this.analista="";
      if(this.lstJefe.length==0 || (this.lstJefe.length==1 && this.lstJefe[0].val.trim().length==0)){
        this.onFilterChange(item, filter, "ejecutivo");
      }
    }
    if(type == "jefe"){
      this.lstEjecutivo =[];
      this.lstAnalistas=[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.NombreJefeDesc){
          if(!this.validarElemento(this.lstEjecutivo, element.EncargadoDesc)){
            this.lstEjecutivo.push({key: element.EncargadoDesc, val: element.EncargadoDesc});
          }
        }
      }
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.NombreJefeDesc){
          if(!this.validarElemento(this.lstAnalistas, element.Analista)){
            this.lstAnalistas.push({key: element.Analista, val: element.Analista});
          }
        }
      }
      this.ejecu="";
      this.analista="";
    }
    if(type == "ejecutivo"){
      this.lstEjecutivo =[];
      this.lstAnalistas=[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.ZonalDesc){
          if(!this.validarElemento(this.lstEjecutivo, element.EncargadoDesc)){
            this.lstEjecutivo.push({key: element.EncargadoDesc, val: element.EncargadoDesc});
          }
        }
      }
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.ZonalDesc){
          if(!this.validarElemento(this.lstAnalistas, element.Analista)){
            this.lstAnalistas.push({key: element.Analista, val: element.Analista});
          }
        }
      }
    }
    
    if(type == "bancaLarge"){
      this.lstZonal =[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.BancaCd){
          if(!this.validarElemento(this.lstZonal, element.ZonalDesc)){
            this.lstZonal.push({key: element.ZonalDesc, val: element.ZonalDesc});
          }
        }
      }
      
      this.zona="";
      this.ejecu="";
      this.jefe="";
      this.indicador="";
      this.perfil="";
      this.perspectiva="";
    }
    if(type == "banca"){
      this.lstZonal =[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.BancaCd){
          if(!this.validarElemento(this.lstZonal, element.ZonalDesc)){
            this.lstZonal.push({key: element.ZonalDesc, val: element.ZonalDesc});
          }
        }
      }
      
      this.zona="";
      this.ejecu="";
      this.jefe="";
      this.indicador="";
      this.perfil="";
      this.perspectiva="";
    }

    if(type == "zonalRK"){
      this.lstJefe =[];
      for (let index = 0; index < this.lstArbolDiario.length; index++) {
        const element = this.lstArbolDiario[index];
        if(item.value == element.ZonalDesc){
          if(!this.validarElemento(this.lstJefe, element.NombreJefeDesc)){
            this.lstJefe.push({key: element.NombreJefeDesc, val: element.NombreJefeDesc});
          }
        }
      }
    }

    if(type == "perspectiva"){
      this.lstIndicador =[];
      for (let index = 0; index < this.lstPerpectivas.length; index++) {
        const element = this.lstPerpectivas[index];
        if(item.value == element.PERSPECTIVA){
          if(!this.validarElemento(this.lstIndicador, element.INDICADOR)){
            this.lstIndicador.push({key: element.INDICADOR, val: element.INDICADOR});
          }
        }
      }
    }

  }

  onBancaChange(item:any){
    this.lstZonal =[];
    for (let index = 0; index < this.lstArbolDiario.length; index++) {
      const element = this.lstArbolDiario[index];
      if(item.value == element.BancaCd){
        if(!this.validarElemento(this.lstZonal, element.ZonalDesc)){
          this.lstZonal.push({key: element.ZonalDesc, val: element.ZonalDesc});
        }
      }
    }
    this.zona="";
    this.ejecu="";
    this.jefe="";
    this.indicador="";
    this.perfil="";
    this.perspectiva="";
  }

  dateValidateFilter (date: Date) {
    if (!date) return false;
    const dateNow = new Date();
    const dateTemp = date.getDate();
    const numDate = date.getDay();
    if(dateNow.getMonth() == date.getMonth()){
      if(numDate>0 && numDate<6){
        return true;
      }else{
        return false;
      }
      
    }else{
      let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      let ultimoDiaIndice = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDay();
      if(ultimoDiaIndice>0 && ultimoDiaIndice<6){
          return ultimoDia == date.getDate();
      }else{
        if(ultimoDiaIndice==0){
          ultimoDia = ultimoDia-2;
        }else{
          ultimoDia = ultimoDia-1;
        }
        return ultimoDia == date.getDate();
      }
    }
    
  }

  searchView(){
    this.viewReport(this.filter,true);
  }

  resetPeriodo(){
    var date = new Date();
    var mes = date.getMonth() + 1;
    var dia = date.getDate();
    var anio = date.getFullYear();
    //if(mes==1 || (mes == 2 && dia < 20)){
    if(mes<=4 || (mes == 5 && dia < 20)){
      this.periodo = (anio-1) +("12");
    }else{
      if(dia>19){
        this.periodo = anio +((mes-1)<10?"0":"")+(mes-1);
      }else{
        this.periodo = anio +((mes-2)<10?"0":"")+(mes-2);
      }
      
    }
    
  }

  obtenerPeriodo(){
    var date = new Date();
    var mes = date.getMonth() + 1;
    var dia = date.getDate();
    var anio = date.getFullYear();
    this.periodo = anio +((mes-1)<10?"0":"")+(mes-1);
    console.log("periodo: ", this.periodo);
    if(mes <= 5){
      if((mes == 1) || (mes == 2) ||(mes == 3) ||(mes == 4) ||(mes == 5 && dia < 31)){
        var anioAnt = anio-1;
        var mesRest = (dia<20 || mes <= 2?1:0);
        for (let index = mes-mesRest; index > 0; index--) {
          var mesStr = ((index)<10?"0"+(index):(index));
          this.lstPeriodo.push({ key: anio+""+mesStr, val: anio+""+mesStr });
        }
        for (let index = 12; index > 0; index--) {
          var mesStr = ((index)<10?"0"+(index):(index));
          this.lstPeriodo.push({ key: anioAnt+""+mesStr, val: anioAnt+""+mesStr });
        }
        
      }
      if(mes<=4 || (mes == 5 && dia < 20)){
        this.periodo = (anio-1) +("12");
      } else if(mes == 5 && dia >= 20){
        this.periodo = (anio) +("01");
      }
      
      
    }else{
      var mesRest = (dia<20?1:0);
      for (let index = 0; index < mes-mesRest; index++) {
        var mesStr = ((index+1)<10?"0"+(index+1):(index+1));
        this.lstPeriodo.push({ key: anio+""+mesStr, val: anio+""+mesStr });
      }
      if(dia<20){
        this.periodo = anio +((mes-2)<10?"0":"")+(mes-2);
      }
    }
  }


  //************Porwer BI********************+ */

  /*@ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

  // Track Report embedding status
  isEmbedded = false;

  // Overall status message of embedding
  displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';

  // CSS Class to be passed to the wrapper
  reportClass = 'report-container';

  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  eventHandlersMap = new Map ([
    ['loaded', () => {
        const report = this.reportObj.getReport();
        report.setComponentTitle('Embedded report');
        console.log('Report has loaded');
      },
    ],
    ['rendered', () => console.log('Report has rendered')],
    ['error', (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;



  async embedReport(): Promise<void> {

    this.isEmbedded = true;
    this.displayMessage = 'Use the buttons above to interact with the report using Power BI Client APIs.';
  }
*/
  

}

/*export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}*/
