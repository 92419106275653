import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../shared/core/services/http-client.service';
import { CustomerContactRequestDTO } from '../pages/contacts/models/customer-contact.request.dto';
import { CustomerContactPaginatorDTO } from '../pages/contacts/models/customer-contact.dto';
import { CustomerContactUniverseDTO } from '../pages/contacts/models/customer-contact-universe.dto';
import { CustomerContactUniverseCreateDTO } from '../pages/contacts/models/customer-contact-universe-create.dto';

@Injectable()
export class ContactsService {

  constructor(private http: HttpClientService) { }

  deleteContact(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/delete-contact`;
    return this.http.post<any>(path, body, { headers });
  };

  // saveContact(body: any) {
  //   const headers = {
  //     'Content-Type': 'application/json;charset=UTF-8',
  //     'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
  //   };
  //   const path =  `${environment.base_url}${environment.contact_path}/contact/process-contact-short-massive`;
  //   return this.http.post<any>(path, body, {headers})
  // }

  getContact(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/list-contact`;
    return this.http.post<any>(path, body, { headers });
  };

  getCustomerWithUniqueCode(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/customer/search-customer`;
    return this.http.post<any>(path, body, { headers });
  };

  getContactsByEnterprises(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/list-contact-dual-customer`;
    return this.http.post<any>(path, body, { headers });
  };


  getListContactsByEnterprises(body: any){
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/list-contact-dual-contact`;
    return this.http.post<any>(path, body, { headers });
  }

  getContactTypeUniverse(body: any){
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/validation-contact-universe`;
    return this.http.post<any>(path, body, { headers });
  }

  favoriteContact(body: { favorite : boolean, idContact: number }){
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/favorite-contact`;
    return this.http.post<any>(path, body, {headers})
  }

  getCustomerContact(body: CustomerContactRequestDTO) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/customer/list-customer-contact`;
    return this.http.post<CustomerContactPaginatorDTO>(path, body, { headers });
  };

  getContactUniverse(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.contact_path}/contact/list-contact-universe`;
    return this.http.post<CustomerContactUniverseDTO[]>(path, body, { headers });
  };

  saveContact(request: CustomerContactUniverseCreateDTO) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path =  `${environment.base_url}${environment.contact_path}/contact/process-contact-short`;
    return this.http.post<any>(path, request, {headers})
  }

};
