import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/shared/core/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { hierarchyList } from 'src/app/store/client/state/hierarchy-list/hierarchy-list.actions';
import { selectHierarchyData } from 'src/app/store/client/state/hierarchy-list/hierarchy-list.model';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';

@Component({
  selector: 'app-client-filter',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './client-filter.component.html',
  styleUrls: ['./client-filter.component.scss']
})
export class ClientFilterComponent extends BaseComponent implements OnInit, AfterViewInit {

  checkout: boolean = true
  @Input() openFilter: boolean;
  @Input() type: any;
  @Input() opcion: number;
  @Input() module: number;
  @Output() closeFilter = new EventEmitter<boolean>(false);
  @Output() sendFilter = new EventEmitter<any>();
  @Output() sectoristas = new EventEmitter<any>();

  @Input() flagAnalist:boolean = false;

  clientStates: any;
  filterForm: FormGroup;
  hierarchyGroupList:any[] = [{id:1,name:'Gestor Campaña'},
                          {id:2,name:'Banca'},
                          {id:3,name:'Zonal'},
                          {id:4,name:'Jefatura'},
                          {id:4,name:'Analista'},
                          {id:5,name:'Ejecutivo'}
                          ];
  hierarchySelected:any=null;
  bancaList:any[] = [];
  zonalList:any[] = [];
  jefaturaList:any[] = [];
  ejecutivoList:any[] = [];
  sectoristasList:any[] = [];
  sectorista: boolean = true;

  zonal: boolean = true;
  jefatura: boolean = true;
  analista: boolean = true;
  ejecutivo: boolean = true;

  private userSelected:any = null;
  private variableSelected: any = null

  constructor(private readonly store: Store<any>,
    private readonly localStorageService: LocalStorageService,
  ){
    super();
  }

  ngOnInit(): void {
    this.userSelected = this.user;
    this.listenHierarchyState();
    this.createForm();
  }

  /*ngAfterViewInit() {
    setTimeout(() => {
      this.getHierarchy();
    },1500)
  }*/

  ngAfterViewInit() {
    setTimeout(() => {
      this.getHierarchy();

      setTimeout(() => {
        if(this.flagAnalist && this.executiveListAvailable()) {
          this.setEjecuOnly();
        }
      }, 2000)
    }, 1500);
  }

  public getHierarchy() {
    const request = {
      userRegistration: this.userSelected,
      module: this.module || 0
    };

    this.store.dispatch(hierarchyList({ request }))
  }

  createForm() {
    this.filterForm = new FormGroup({
      banca: new FormControl(),
      zonal: new FormControl(),
      jefatura: new FormControl(),
      ejecutivo: new FormControl()
    });
  }

  close() {
    this.closeFilter.emit(true);
  }

  ok() {
    if (this.opcion == 1){
      this.userSelected = this.type.filter((x: { selected: boolean; }) => x.selected);
    }
    this.sendFilter.emit(this.userSelected);
    this.closeFilter.emit(true);
  }

  cancel() {
    if (this.opcion == 1){
      this.userSelected = this.type.forEach( (x: { selected: boolean; }) => x.selected = false);
      this.checkout = false;
    }else if (this.opcion == 0){
      this.createForm();
      this.userSelected = this.user;
      this.getHierarchy();
    }
  }

  listenHierarchyState() {

    this.store
      .select(selectHierarchyData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hierarchy => {
        if(hierarchy.data!=null){
          const subordinateList = hierarchy.data?.subordinateList || [];
          if (hierarchy.data.hierarchyGroup == this.hierarchyGroupList[5].name && subordinateList?.length > 0 && this.sectorista) {
            this.hierarchySelected = this.hierarchyGroupList[5];
            this.sectoristasList = [...subordinateList];
            this.sectoristas.emit(this.sectoristasList);
          } else {
            this.sectoristasList = [];
            let hierarchyPriority=10;
              this.hierarchyGroupList.forEach(hierarchyGroup => {
    
                if(hierarchy?.data?.hierarchyGroup==hierarchyGroup.name){
                  hierarchyPriority = hierarchyGroup.id;
                  if(this.hierarchySelected == null){
                    this.hierarchySelected=hierarchyGroup;
                  }
                }
    
              });
    
              if(hierarchyPriority <=1){
                this.bancaList=[];
              }
              if(hierarchyPriority <=2){
                this.zonalList=[];
              }
              if(hierarchyPriority <=3){
                this.jefaturaList=[];
              }
              if(hierarchyPriority <=4){
                this.ejecutivoList=[];
              }
              if(hierarchy.data.subordinateList!=null){
                hierarchy.data.subordinateList.forEach((subordinado:any) => {
                  if(subordinado.hierarchyGroup==this.hierarchyGroupList[1].name
                    && hierarchyPriority <=1){
                    this.bancaList.push(subordinado);
                  }
                  else if(subordinado.hierarchyGroup==this.hierarchyGroupList[2].name
                    && hierarchyPriority <=2){
                    this.zonalList.push(subordinado);
                  }
                  else if(subordinado.hierarchyGroup==this.hierarchyGroupList[3].name
                    && hierarchyPriority <=3){
                    this.jefaturaList.push(subordinado);
                  }
                  else if(subordinado.hierarchyGroup==this.hierarchyGroupList[5].name
                    && hierarchyPriority <=4){
                    this.ejecutivoList.push(subordinado);
                  }
                });
              }
          }

            this.sectorista = false;
          }

      })
  }

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  public selectBanca(){
    /*this.filterForm.get('banca')?.setValue(value);*/
    this.userSelected = this.filterForm.value.banca.userRegistration;
    this.getHierarchy();
    this.zonal = false;
  }

  public selectZonal(){
    /*this.filterForm.get('zonal')?.setValue(value);*/
    this.userSelected = this.filterForm.value.zonal.userRegistration;
    this.getHierarchy();
    this.jefatura = false;
  }

  public selectJefatura(){
    /*this.filterForm.get('jefatura')?.setValue(value);*/
    this.userSelected = this.filterForm.value.jefatura.userRegistration;
    this.getHierarchy();
    this.ejecutivo = false;
  }

  public selectEjecutivo(){
    /*this.filterForm.get('ejecutivo')?.setValue(value);*/
    this.userSelected = this.filterForm.value.ejecutivo.userRegistration;
    this.getHierarchy();
  }

  public selectSectorista() {
    this.userSelected = this.filterForm.value.ejecutivo.userRegistration;
  }

  private executiveListAvailable(): boolean {
    return this.ejecutivoList.length > 0;
  }

  // esto es solo cuando el analista solo tiene un ejecutivo
  setEjecuOnly(){
    if(this.flagAnalist && this.ejecutivoList.length == 1){
      let executive = this.ejecutivoList[0];
      this.filterForm.controls['ejecutivo'].setValue(executive);
      this.userSelected = this.filterForm.value.ejecutivo.userRegistration;
    }
  }

}
