import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { DocumentCreateDTO, DocumentDownloadGetDTO, DocumentsTypeDTO, DocumentsTypeGetDTO, EscomFilterDTO } from "src/app/pages/escom-list/models";
import { EscomService } from "src/app/services/escom.service";
import { documentCreateSuccess, documentsTypeList, documentsTypeReset, documentTypeItemExpanded, subDocumentsTypeList } from "./escom.actions";
import { selectEscom } from "./escom.selectors";
import { FilterYearRequestDTO } from "src/app/pages/escom-list/models/filter-year-request.dto";

@Injectable()
export class EscomFacade {

    constructor(private readonly store: Store,
        private readonly escomService: EscomService) {}

    download(request: DocumentDownloadGetDTO){ 
        return this.escomService.download(request);
    }

    checkDocument(request: DocumentCreateDTO) {
        return this.escomService.checkDocument(request);
    }

    deleteDocument(id: number, userIdentifier: string){
        return this.escomService.deleteDocument(id, userIdentifier);
    }

    uploadDocument(query: DocumentCreateDTO, file: File) {
        return this.escomService.uploadDocument(query, file);
    }

    changeHistoryStatus(request: EscomFilterDTO) {
        this.store.dispatch(documentCreateSuccess({ request }));
    }

    getDocumentsType(request: DocumentsTypeGetDTO) {
        this.store.dispatch(documentsTypeList({request}));
    }

    getSubDocumentsType(request: DocumentsTypeGetDTO) {
        this.store.dispatch(subDocumentsTypeList({request}));
    }

    documentTypeItemExpanded(documentType: DocumentsTypeDTO, expanded: boolean) {
        this.store.dispatch(documentTypeItemExpanded({ documentType, expanded }))
    }

    getFilter(request: FilterYearRequestDTO) {
        return this.escomService.getFilter(request);
    }

    selectDocumentTypeData() {
        return this.store.select(selectEscom);
    }

    reset() {
        this.store.dispatch(documentsTypeReset());
    }

}