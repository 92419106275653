import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EnoteReducerModule, EnoteEffects, ENOTE_FEATURE_KEY } from './enote.store';
import { FallsFacade } from './state/executive/falls/falls.facade';
import { PipelineFacade } from './state/executive/pipeline/pipeline.facade';
import { ResumeFacade } from './state/executive/resume/resume.facade';
import { ResumeJefeFacade } from './state/boss/resume-jefe/resume-jefe.facade';
import { FallsJefeFacade } from './state/boss/falls-jefe/falls-jefe.facade';
import { LossesFacade } from './state/boss/losses/losses.facade';
import { RankingFacade } from './state/boss/ranking/ranking.facade';
import { NewFacade } from './state/boss/new/new.facade';
import { NewOperationFacade } from './state/executive/new-operation/new-operation.facade';
import { OperationService } from 'src/app/services/operation.service';
import { CoreModule } from 'src/app/shared/core/core.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    StoreModule.forFeature(ENOTE_FEATURE_KEY, EnoteReducerModule),
    EffectsModule.forFeature(EnoteEffects)
  ],
  providers: [
    OperationService,
    FallsFacade,
    PipelineFacade,
    ResumeFacade,
    ResumeJefeFacade,
    FallsJefeFacade,
    LossesFacade,
    RankingFacade,
    NewFacade,
    NewOperationFacade
  ],
})
export class EnoteModule { }
