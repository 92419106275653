import { AbstractControl } from "@angular/forms";
import { alphabeticWithSpaceAndAccent } from "../../core/utils";

export const inputAlphabeticWithAccentValidator = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const value = control.value;
    if (value) {
        let pattern = new RegExp(alphabeticWithSpaceAndAccent());
        let valid = pattern.test(value);
        if (!valid) {
            const val = value.substring(0, value.length - 1);
            control.setValue(val, { emitEvent: false });
        }
    }
    return null;
};
