import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { rankingList, rankingReset } from "./ranking.actions";
import { selectRankingList, selectRankingLoading } from "./ranking.selectors";

@Injectable()
export class RankingFacade {

    constructor(private readonly store: Store) {}
    
    getRanking(request: any) {
        this.store.dispatch(rankingList({ payload: request }));
    }

    reset() {
        this.store.dispatch(rankingReset());
    }

    selectRanking() {
        return this.store.select(selectRankingList);
    }

    selectRankingLoading() {
        return this.store.select(selectRankingLoading);
    }

}