import { Injectable } from "@angular/core";
import { QualitativeCustomerProviderRequestDTO, QualitativeGeneralDataRequestDTO, QualitativeShareholdersBackgroundRequestDTO } from "src/app/pages/qualitative-sheet/models";
import { QualitativeSalesBusinessChannelRequestDTO } from "src/app/pages/qualitative-sheet/models/qualitative-sales-business-channel.request.dto";
import { QualitativeService } from "src/app/services/qualitative.service";
import { ParamsFacade } from "../../params/state/params/params.facade";
import { ParametersEnum } from "src/app/shared/core/enums/parameters.enum";
import { forkJoin } from "rxjs";
import { Store } from "@ngrx/store";
import { FinancialService } from "src/app/services/financial.service";

@Injectable()
export class FinancialNewFacade {

    constructor(
        private readonly financialService: FinancialService,
        private readonly paramsFacade: ParamsFacade,
        private readonly store: Store
    ) {}

    // getQualitativeParams() {
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeBacklog);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeActivity);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeAccounting);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeRawMaterial);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeSubSector);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeComodities);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativePaymentSale);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativePaymentPurchase);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeOperationZone);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeClientZone);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeClientType);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeProvidersType);
    //     this.paramsFacade.getParams(ParametersEnum.qualitativeRole)
    // }

    getFinancialData(id: number) {
        return forkJoin([
            this.financialService.getProfitLossStatements(id),
        ]);
    }

    saveProfitLossStatements(request: FormData) {
        return this.financialService.saveProfitLossStatements(request);
    }

}