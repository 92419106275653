import { createSelector } from "@ngrx/store";
import { ClientState, selectClientState } from "../../client.store";
import { HierarchyModel } from "src/app/models/hierarchy.model";

export interface HierarchyListStateModel {
    data: HierarchyModel | null;
    loading: boolean;
    loaded: boolean;
    failed: boolean;
    error: any;
}

export const selectHierarchyData = createSelector(
    selectClientState,
    (state: ClientState) => state.hierarchyList
);