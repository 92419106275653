import { createReducer, on } from "@ngrx/store";
import { HierarchyListStateModel } from "./hierarchy-list.model";
import { hierarchyList, hierarchySuccess, hierarchyFailed, hierarchyReset } from './hierarchy-list.actions';

export const initialState: HierarchyListStateModel = {
    data: null,
    loading: false,
    failed: false,
    loaded: false,
    error: null
};

const _hierarchyListReducer = createReducer(initialState,
    on(hierarchyList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(hierarchyFailed, (state, payload) => {
        return {
            ...state,
            data: null,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(hierarchySuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(hierarchyReset, state => state = initialState),
    
);

export function hierarchyListReducer(state: any, action: any): HierarchyListStateModel {
    return _hierarchyListReducer(state, action);
}