import { createAction, props } from "@ngrx/store";

export const campaignList = createAction('[Campaign] List',
    props<{limit: number, name: string, offset: number, periodIn: string, periodFi:string, state: string, typeCampaign: string}>()
);

export const campaignFailed = createAction('[Campaign] Failed',
    props<{payload: any}>()
);
export const campaignSuccess = createAction('[Campaign] Success',
    props<{payload: any}>()
);

export const campaignReset = createAction('[Campaign] Reset');
