import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { ParamsEffects } from '../params/state/params/params.effects';
import { paramsReducer } from '../params/state/params/params.reducer';
import { ParamsStateModel } from '../params/state/params/params.model';
import { ProductsStateModel } from './state/products/products.model';
import { productsReducer } from './state/products/products.reducer';
import { ProductsEffects } from './state/products/products.effects';

export const PARAMS_FEATURE_KEY = 'params';

export const selectParamsState = createFeatureSelector<ParamsState>(
  PARAMS_FEATURE_KEY
);

export const ParamsListEffects: any[] = [
  ParamsEffects,
  ProductsEffects
];

export interface ParamsState {
  params: ParamsStateModel;
  products: ProductsStateModel;
}

export const ParamsReducerModule: ActionReducerMap<ParamsState> = {
  params: paramsReducer,
  products: productsReducer
};
