import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParamsDTO } from '../models';
import { HttpClientService } from '../shared/core/services/http-client.service';
import { PathRest } from '../static/path.rest';
import { USER_INFO } from '../shared/core/constants/local.storage.constants';
import { LocalStorageService } from '../shared/core/services/local.storage.service';

@Injectable()
export class CommonsService {
  constructor(
    private readonly http: HttpClientService,
    private readonly localStorageService: LocalStorageService) {}

  /**
   * Dejar de usar este método, reemplazar por getParams()
   * @param body
   * @returns
   */
  getParametrosPorTabla(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      'access-control-allow-private-network': 'true',
    };
    const path = `${environment.base_url}${environment.commons_path}/commons/listByTable`;
    return this.http.post<any>(path, body, { headers });
  }

  getParams(tableId: number) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: this.localStorageService.getItem<string>(USER_INFO) ?? '',
    };
    const path = `${environment.base_url}${environment.commons_path}/commons/listByTable`;
    return this.http.post<any>(path, { tabla: tableId }, { headers })
      .pipe(
        map(response => {
          return (response?.parametros || []) as ParamsDTO[];
        })
      );
  }
}
