import { createAction, props } from "@ngrx/store";
import { OperationGetRequest } from "src/app/pages/enote-config/pages/enote/models";

export const fallsList = createAction('[Falls] List',
    // props<{operationType: string, products: number[], uniqueCode: string, businessName: string, ruc: string, user?:string}>()
    props<{payload: OperationGetRequest}>()
);
export const fallsFailed = createAction('[Falls] Failed',
    props<{payload: any}>()
);
export const fallsSuccess = createAction('[Falls] Success',
    props<{payload: any}>()
);
export const fallsCancel = createAction('[Falls] Cancel',
    props<{payload: any}>()
);
export const fallsReset = createAction(
    '[Falls] Reset'
);
export const fallsRemoveItemSuccess = createAction('[Falls] Remove Item Success',
    props<{id: number}>()
);
