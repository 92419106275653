import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectLossesList = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.losses
);

export const selectLossesListLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.losses.loading
);
