export class InformationModalModel {
    public description: string;
    public type: TypeInformationModal;
    public timeOut: number;
}

export enum TypeInformationModal {
    SUCCESS = 1,
    ERROR = 2,
    WARNING = 3
}