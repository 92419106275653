import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectFallsOperations = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.falls
);

export const selectFallsOperationsLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.falls.loading 
);
