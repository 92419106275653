import { createAction, props } from "@ngrx/store";

export const familyClientList = createAction('[FamilyClient] List'
);

export const familyClientFailed = createAction('[FamilyClient] Failed',
    props<{payload: any}>()
);
export const familyClientSuccess = createAction('[FamilyClient] Success',
    props<{payload: any}>()
);

export const familyClientReset = createAction('[FamilyClient] Reset');