export interface ILeads {
  company: string;
  uniqueCode: string;
  campaign: string;
  userAssigned: string;
  subStage: string;
  lastDateActivity: string;
  attributes?: any;
  state: string;
  idLead: string;
  nameSubstate: string;
  ruc: string;
}