import { createSelector } from "@ngrx/store";
import { CampaignState, selectCampaignState } from "../../campaign.store";

export const selectSelectedInputsStates = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.inputsStates
);

export const selectSelectedStates = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.inputsStates.states
);

export const selectSelectedSubStates = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.inputsStates.subStates
);

export const selectSelectedInputs = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.inputsStates.inputs 
);
