import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerSpecialHierarchy } from '../pages/closeness/models/customer-special-hierarchy.model';
import { UsersAutocompleteModel } from '../pages/closeness/models/users-autocomplete.model';
import { ClosenessCreateDTO } from '../pages/closeness/pages/closeness-new/models';
import { ClosenessGetDTO } from '../pages/closeness/pages/closeness-list/models';
import { ClosenessPaginatorDTO } from '../pages/closeness/pages/closeness-list/models/closeness-paginator.dto';
import {
  ContentType,
  HttpClientService,
} from '../shared/core/services/http-client.service';
import { ClosenessDetailDTO } from '../pages/closeness/pages/closeness-detail/models/closeness-detail.dto';

@Injectable()
export class ClosenessService {

  public uniqueCodeSearch: string = '';
  public businessNameSearch: string = '';
  public documentSearch: string = '';

  constructor(private readonly http: HttpClientService) {}

  searchUsers(text: string): Observable<UsersAutocompleteModel[]> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.users_path}/users/search-user-autocomplete?search=${text}`;
    return this.http.get<UsersAutocompleteModel[]>(path, options)
      .pipe(
        map(response => response || [])
      );
  }

  create(request: ClosenessCreateDTO): Observable<{idCloseness: number}> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.closeness_path}/closeness/insert-closeness`;
    return this.http.post<{idCloseness: number}>(path, request, options);
  }

  getCloseness(request: ClosenessGetDTO): Observable<ClosenessPaginatorDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.closeness_path}/closeness/list-closeness`;
    return this.http.post<ClosenessPaginatorDTO>(path, request, options);
  }

  getClosenessDetail(idCloseness: number): Observable<ClosenessDetailDTO> {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.closeness_path}/closeness/detail-closeness?idCloseness=${idCloseness}`;
    return this.http.get<ClosenessDetailDTO>(path, options);
  }

}
