<div *ngIf="type=='normal'" class="ibk-tooltip" [style.width]="width" [style.top]="top" [style.left]="left">
    <div [innerHTML]="text" class="content">

    </div>
</div>

<div id="mensajeError" *ngIf="type=='error'"  class="ibk-tooltip-error" [style.width]="width" [style.top]="top" [style.left]="left">
  <span>
    {{text}}
  </span>
</div>

