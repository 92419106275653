import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { newList, newReset } from "./new.actions";
import { selectNewList, selectNewLoading } from "./new.selectors";

@Injectable()
export class NewFacade {

    constructor(private readonly store: Store) {}
    
    getNew(request: any) {
        this.store.dispatch(newList({ payload: request }));
    }

    reset() {
        this.store.dispatch(newReset());
    }

    selectNew() {
        return this.store.select(selectNewList);
    }

    selectNewLoading() {
        return this.store.select(selectNewLoading);
    }

}