export function numeric() {
  return '^[0-9]*$';
}

export function decimal() {
  return '^[.0-9]*$';
}

export function alphabetic() {
  return '^[ña-zÑA-Z ]*$';
}

export function alphabeticWithSpaceAndAccent() {
  return '^[ña-z-ÑA-Z-áéíóúÁÉÍÓÚ ]';
}

export function alphaNumeric() {
  return '^[ña-zÑA-Z0-9 ]*$';
}

export function alphaNumericWithSpaceAndAccent() {
  return '^[0-9ña-z-ÑA-Z-áéíóúÁÉÍÓÚ ]';
}

export function phone() {
  return '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-0-9]*$';
}

export function email() {
  return '^[ña-zÑA-Z0-9._-]*@*[a-zA-Z.-]*$';
}

export function groupBy(data: any, key: string) {
  return data.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function sort(data: any, key: string, ascending: boolean = false) {
  return data.sort((a: any, b: any) => {
    if (b[key] < a[key]) return ascending ? 1 : -1;
    if (b[key] > a[key]) return ascending ? -1 : 1;
    return 0;
  });
}

export function fromDateToText(
  date: Date,
  separator: string = '-',
  type: number = 1
) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const dayValue = day < 10 ? '0' + day : day;
  const monthValue = month < 10 ? '0' + month : month;
  const hourValue = hour < 10 ? '0' + hour : hour;
  const minuteValue = minutes < 10 ? '0' + minutes : minutes;
  const secondsValue = seconds < 10 ? '0' + seconds : seconds;
  if (separator == '-') {
    if (type == 1) {
      return `${year}-${monthValue}-${dayValue} ${hourValue}:${minuteValue}:${secondsValue}`;
    } else if (type == 0) {
      return `${year}-${monthValue}-${dayValue}`;
    }
  }
  return `${dayValue}/${monthValue}/${year} ${hourValue}:${minuteValue}:${secondsValue}`;
}

export function fromDateToTextWithoutTime(date: Date, separator: string = '-') {
  if (!date) return null;

  const response = fromDateToText(date, separator);
  return response.substring(0, 10);
}

export function fromTextToDate(text: string, other: boolean = false) {
  if (!text) return '';

  if (other) {
    const day = +text.substring(0, 2);
    const month = +text.substring(3, 5) - 1;
    const year = +text.substring(6, 10);
    return new Date(year, month, day);
  }

  const year = +text.substring(0, 4);
  const month = +text.substring(5, 7) - 1;
  const day = +text.substring(8, 10);
  return new Date(year, month, day);
}

export function isNumber(str: string): boolean {
  if (str.trim() != str) {
    return false;
  }
  if (str.trim() === '') {
    return false;
  }
  return !Number.isNaN(Number(str));
}

export function getMonthName(month: number) {
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  return months[month - 1];
}
