import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { resumeJefeList, resumeJefeReset } from "./resume-jefe.actions";
import { selectResumeJefeList, selectResumeJefeLoading } from "./resume-jefe.selectors";

@Injectable()
export class ResumeJefeFacade {

    constructor(private readonly store: Store) {}
    
    getResume(request: any) {
        this.store.dispatch(resumeJefeList({ payload: request }));
    }

    reset() {
        this.store.dispatch(resumeJefeReset());
    }

    selectResumeBoss() {
        return this.store.select(selectResumeJefeList);
    }

    selectResumeBossLoading() {
        return this.store.select(selectResumeJefeLoading);
    }

}