export class TokenBO {
  token: string;
  fechaExpira: Date;
  tokenNoUrl: string;
  constructor() {
    this.token = '';
    this.fechaExpira = new Date();
    this.tokenNoUrl = '';
  }
}
