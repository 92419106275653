import { createReducer, on } from "@ngrx/store";
import { ValueOfferStateModel } from "./value-offer.model";
import { valueOfferList, valueOfferSuccess, valueOfferFailed, valueOfferReset } from './value-offer.actions';

export const initialState: ValueOfferStateModel = {
    data: null,
    loading: false,
    failed: false,
    error: null
};

const _valueOfferReducer = createReducer(initialState,
    on(valueOfferList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(valueOfferFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(valueOfferSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false
        }
    }),
    on(valueOfferReset, state => state = initialState)
);

export function valueOfferReducer(state: any, action: any): ValueOfferStateModel {
    return _valueOfferReducer(state, action);
}
