import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  format = { add: 'Agregar', remove: 'Remover', all: '', none: '',direction:'left-to-right', draggable: false ,locale: ''};
  source = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6','col7', 'col8' ];
  target = [];
  constructor() {
  }

  CloseDialog() {
    console.log(this.target)
  }
}
