export enum HierarchyEnum {
    GESTOR_CAMPAÑA = 1,
    BANCA = 2,
    ZONAL = 3,
    JEFATURA = 4,
    ANALISTA = 4,
    EJECUTIVO = 5
}

export enum HierarchyDescEnum {
    GESTOR_CAMPAÑA = "Gestor Campaña",
    BANCA = "Banca",
    ZONAL = "Zonal",
    JEFATURA = "Jefatura",
    ANALISTA = "Analista",
    EJECUTIVO = "Ejecutivo"
}

export enum HierarchyDescShortEnum {
    EJECUTIVO_NEGOCIO = 'EJECUTIVO NEGOCIO'
}
