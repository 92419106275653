import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';

@Pipe({
  name: 'operationFilter'
})
export class OperationFilterPipe implements PipeTransform {

  transform(data: any, firstDate: any, lastDate: any, types: string) {
    let response = data;
    // if (types && types.length > 0 && data.length > 0) {
    if (types.toLowerCase() != 'todas') {
      const filter = new FormArray([]);
      for (let item of data) {
        const exist = types.toLowerCase() == item.get('typeName').value?.toLowerCase();
        if (exist) {
          filter.push(item);
        }
      }
      response = filter.controls;
    }
    if (firstDate && lastDate) {
      const filter = new FormArray([]);
      for (let item of response) {
        const date = item.get('date').value;
        if (date >= firstDate && date <= lastDate) {
          filter.push(item);
        }
      }
      response = filter.controls;
    }
    return response;
  }

}
