import { createAction, props } from "@ngrx/store";
import { OperationGetRequest } from "src/app/pages/enote-config/pages/enote/models";

export const pipelineList = createAction('[Pipeline] List',
    // props<{operationType: string, products: number[], uniqueCode: string, businessName: string, ruc:string, user?:string}>()
    props<{payload: OperationGetRequest}>()
);
export const pipelineFailed = createAction('[Pipeline] Failed',
    props<{payload: any}>()
);
export const pipelineSuccess = createAction('[Pipeline] Success',
    props<{payload: any}>()
);
// export const pipelineAddItems = createAction('[Pipeline] Add Items',
//     props<{payload: any}>()
// );
// export const pipelineAddOneItem = createAction('[Pipeline] Add Item',
//     props<{payload: any}>()
// );
export const pipelineRemoveItemSuccess = createAction('[Pipeline] Remove Item Success',
    props<{id: number}>()
);
// export const pipelineUpdateItem = createAction('[Pipeline] Update Item',
//     props<{index: number, payload: any}>()
// );
export const pipelineCancel = createAction('[Pipeline] Cancel',
    props<{payload: any}>()
);
export const pipelineReset = createAction(
    '[Pipeline] Reset'
);
