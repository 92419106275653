import { createSelector } from '@ngrx/store';
import { ParamsState, selectParamsState } from '../../params.store';

// export const selectParamsData = createSelector(
//   selectParamsState,
//   (state: ParamsState) => state.params.data
// );

export const selectParamsData = (tableId: number) =>
  createSelector(selectParamsState, (state: ParamsState) =>
    state.params.data.filter((item) => item.idTabla == tableId)
  );
