import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap, throwError } from 'rxjs';
import { TypeInformationModal } from '../../ui/components';
import { DialogService } from './dialog.service';

@Injectable()
export class HttpClientService {
  constructor(
    private readonly http: HttpClient,
    private readonly dialogService: DialogService
  ) {}

  get<T>(url: string, options?: any) {
    return this.http.get<T>(url, options).pipe(
      map((response: any) => response as T),
      catchError((error: HttpErrorResponse) => {
        return this.httpThrowError(error);
      })
    );
  }

  post<T>(url: string, body: any, options?: any) {
    return this.http.post<T>(url, body, options).pipe(
      switchMap((response: any) => {
        if (response?.codigo) {
          return this.customError(response);
        }
        return of(response as T);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.httpThrowError(error);
      })
    );
  }

  put<T>(url: string, body: any, options?: any) {
    return this.http.put<T>(url, body, options).pipe(
      switchMap((response: any) => {
        if (response?.codigo) {
          return this.httpThrowError(response);
        }
        return of(response as T);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.httpThrowError(error);
      })
    );
  }

  delete<T>(url: string, options?: any) {
    return this.http.delete<T>(url, options).pipe(
      map((response: any) => response as T),
      catchError((error: HttpErrorResponse) => {
        return this.httpThrowError(error);
      })
    );
  }

  getPercentage(event: any, total: number) {
    if (event.type === HttpEventType.DownloadProgress) {
      return Math.round((100 * event.loaded) / total);
    } else if (event.type === HttpEventType.UploadProgress) {
        return Math.round((100 * event.loaded) / total);
    } else if (event.type == HttpEventType.Response) {
      return 100;
    }
    return 0;
  }

  customError(response: any) {
    const message = response.mensaje || 'Ocurrió un error inesperado.';
    this.dialogService.informationModal(message, TypeInformationModal.ERROR);
    return throwError(() => ({ codigo: response.codigo, mensaje: message }));
  }

  httpThrowError(error: HttpErrorResponse) {
    let message = 'Ocurrió un error inesperado.';
    let code = error?.status;
    if (code) {
      if (code == 0) {
        message =
          '<strong>¡Error de conectividad!</strong> <br>Actualiza la página en unos minutos.';
      } else {
        const msg = error?.error?.mensaje || 'Intenta nuevamente en unos minutos.';
        message = `<strong>¡Error al configurar!</strong> <br>${msg}`;
      }
      this.dialogService.informationModal(
        message,
        TypeInformationModal.ERROR,
        3500
      );
    } else {
      message = (error as any)?.mensaje;
      code = (error as any)?.codigo;
    }

    const customError = new HttpErrorResponse({
      error: {
        codigo: code,
        mensaje: message,
      },
      status: code,
      statusText: error?.statusText,
      url: error?.url || '',
    });
    return throwError(customError);
  }

  getContentType(type: ContentType) {
    let contentType = '';
    switch (type) {
      case ContentType.JSON:
        contentType = 'application/json;charset=UTF-8';
        break;
      case ContentType.FORM_DATA:
        contentType = 'multipart/form-data';
        break;
      case ContentType.FORM_URL_ENCODED:
        contentType = 'application/x-www-form-urlencoded';
        break;
    }
    return contentType;
  }
}

export enum ContentType {
  JSON = 0,
  FORM_DATA = 1,
  FORM_URL_ENCODED = 2,
}
