import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CommonsService } from 'src/app/services/commons.service';
import { paramsDataList, paramsDataSuccess, paramsDataFailed } from './params.actions';

@Injectable()
export class ParamsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly commonsService: CommonsService
    ) { }

    getParamsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(paramsDataList),
            mergeMap((action) => {
                return this.commonsService.getParams(action.id)
                    .pipe(
                        map((data) => {
                            return paramsDataSuccess({ payload: {
                                tableId: action.id,
                                parameters: data
                            } })
                        }),
                        catchError((error) => of(paramsDataFailed(error)))
                    )
            })
        )
    )

}
