import { createReducer, on} from "@ngrx/store";
import { closenessList, closenessSuccess, closenessFailed, closenessReset } from "./closeness-list.actions";
import { ClosenessListStateModel } from "./closeness-list.model";

export const initialState: ClosenessListStateModel = {
    data: {
        totalItems: 0,
        currentPage: 0,
        totalPages: 0,
        closenessList: []
    },
    loading: false,
    failed: false,
    loaded: false
};

const _closenessReducer = createReducer(initialState,
    on(closenessList, state => {
        return { 
            ...state,
            loading: true,
            loaded: false
        }
    }),
    on(closenessFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(closenessSuccess, (state, { payload }) => {
        const previousData = state.data?.closenessList || [];
        const newData = payload?.closenessList || [];
        const data = [...previousData, ...newData];
        return {
            ...state,
            data: {
                ...payload,
                closenessList: data
            },
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(closenessReset, state => state = initialState)
);

export function closenessReducer(state: any, action: any): ClosenessListStateModel {
    return _closenessReducer(state, action);
}
