import { Injectable } from '@angular/core';
import { LoginForm } from 'src/app/interfaces/login-form.interface';
import { catchError } from 'rxjs/operators'; import { map, tap } from 'rxjs/operators'
import { Router } from '@angular/router';
import { PathRest } from 'src/app/static/path.rest';
import { Observable, of } from 'rxjs';
import { Ilogin } from '../models/login.models';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../shared/core/services/local.storage.service';
import { USER_INFO } from '../shared/core/constants/local.storage.constants';
import { SessionStorageService } from '../shared/core/services/session.storage.service';
import { ContentType, HttpClientService } from '../shared/core/services/http-client.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClientService,
    private router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService) {

  }

  logIn(formData: LoginForm) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
        'clientId': environment.authAD.clientId,
        'clientSecret': environment.authAD.clientSecret,
        'channel': 'Web',
      }
    }
    const path = `${environment.base_url}${environment.security_path}/login`;
    return this.http.post<Ilogin>(path, formData, options)
      .pipe(
        tap(resp => {
          if(resp.userinfo && resp.access_token){
            localStorage.setItem('access_token', resp.access_token);
            this.localStorageService.setItem(USER_INFO, resp.userinfo.username);
          }
        })
      )
  }

  get token(): string {
    return localStorage.getItem('access_token') || '';
  }
  get user(): string {
    return this.localStorageService.getItem(USER_INFO) ?? '';
  }

  get userType(): Array<string> {
    let typesList:Array<string> = new Array();
    let userLogin = localStorage.getItem("usuarioLogin");
    if (userLogin && userLogin != null ) {
      let userLoginObject = JSON.parse(userLogin);
      if(userLoginObject.groups != undefined){

        let groupsUserList = userLoginObject.groups
        const contentAnalist = groupsUserList.some((group:string) => group.includes("ANALISTA"));
        if(contentAnalist) typesList.push('Analista');
        const contentEjecutive = groupsUserList.some((group:string) => group.includes("EJECU"));
        if(contentEjecutive) typesList.push('Ejecutivo');
        const contentAdmin = groupsUserList.some((group:string) => group.includes("ADMINISTRADOR"));
        if(contentAdmin) typesList.push('Administrador');
        const contentGestorC = groupsUserList.some((group:string) => group.includes("GESTOR_CAMPA"));
        if(contentGestorC) typesList.push('Gestor Campaña');

        return typesList;
      }
    }
    return typesList;
  }

  logOut() {
    let params = new HttpParams().append('username', this.user);
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
        'OAuth-Token': this.token,
        'clientId': environment.authAD.clientId,
        'clientSecret': environment.authAD.clientSecret,
        'channel': 'Web',
      },
      params
    }
    const path = `${environment.base_url}${environment.security_path}/logout`;
    this.sessionStorageService.clear();
    this.localStorageService.clear();
    this.router.navigateByUrl('/login');
    return this.http.delete(path, options);
  };

  validarToken(): Observable<boolean> {
    const options = {
      headers: {
        'Authorization': 'Basic ' + window.btoa(environment.authAD.clientId + ":" + environment.authAD.clientSecret),
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    const path = `${environment.base_url}${environment.security_path}/oauth/check_token`;
    return this.http.get(`${path}?token=${this.token}`, options).pipe(
      map(resp => true),
      catchError( error => of(false))
    )

  }

  generarTokenBO(user:string, pass: string){
    const body = {
      "password": pass,
      "authType": "secWinAD",
      "username": user+"@GRUPOIB.LOCAL"
    };
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'userRegistration': this.localStorageService.getItem<string>(USER_INFO) ?? '',
        'Content-Type': this.http.getContentType(ContentType.JSON)
      }
    }
    const path = `${environment.base_url}${environment.security_path}/getTockenBO`;
    return this.http.post(path, body, options);
  }

  getArbol(token:string){
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'userRegistration': this.localStorageService.getItem<string>(USER_INFO) ?? ''
      },
      responseType: "text"
    }
    const body = {
      "token": token
    };
    console.log("request Arbol: ", body);
    const path = `${environment.base_url}${environment.security_path}/getArbolDiario`;
    return this.http.post(path, body, options);
  }

  getAccessToken(id:any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${environment.base_url}${environment.users_path}/embed/getembedinfo`;
    return this.http.get(`${path}?report=${id}`, {headers});
  }
}
