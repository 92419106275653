import { createReducer, on} from "@ngrx/store";
import { InformationTypeStateModel } from "./escom-information-type.model";
import { informationTypeList, informationTypeFailed, informationTypeSuccess, informationTypeReset } from "./escom-information-type.actions";

export const initialState: InformationTypeStateModel = {
    data: [],
    loading: false,
    failed: false
};

const _informationTypeReducer = createReducer(initialState,
    on(informationTypeList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(informationTypeFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(informationTypeSuccess, (state, { payload }) => {
        return {
            ...state,
            data: [...payload],
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(informationTypeReset, state => state = initialState)
);

export function informationTypeReducer(state: any, action: any): InformationTypeStateModel {
    return _informationTypeReducer(state, action);
}
