import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuDTO, SubMenuDTO } from '../models/menu.dto';
import { AccessModuleEnum } from '../shared/core/enums/access.module.enum';
import { ContentType, HttpClientService } from '../shared/core/services/http-client.service';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    private menuItems$ = new BehaviorSubject<MenuDTO[]>([]);

    constructor(private readonly http: HttpClientService) {}

    getMenu(roleId: number) {
        const headers = {
          'Content-Type': this.http.getContentType(ContentType.JSON),
          'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
        };
        const path = `${environment.base_url}${environment.users_path}/menu/search-menu-rol`;
        return this.http.post<MenuDTO[]>(path, { idRole: roleId }, {headers});
    }

    public getMenuPath() {
        const accessStorage = localStorage.getItem("access");
        if (accessStorage) {
            const access: MenuDTO[] = JSON.parse(accessStorage || null!) || [];
            let paths: SubMenuDTO[] = [];
            for (let item of access) {
                const data = this.getPath(item.subOp)
                paths = [...paths, ...data];
            }
            return paths;
        }
        return [];
    }

    private getPath(sub: SubMenuDTO[]) {
        let arr: SubMenuDTO[] = [];
        for (let item of sub) {
            if (item.flgOption != AccessModuleEnum.NO_ACCESS) {
                arr.push(item)
            }
            if (item.subOp?.length > 0) {
                const data = this.getPath(item.subOp);
                arr = [...arr, ...data];
            }
        }
        return arr;
    }

    setMenuItems(menu: MenuDTO[]) {
        this.menuItems$.next(menu);
    }

    getMenuItems() {
        return this.menuItems$.asObservable();
    }

}
