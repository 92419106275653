import { createReducer, on} from "@ngrx/store";
import { EscomStateModel } from "./escom.model";
import { documentsTypeList, documentsTypeFailed, documentsTypeSuccess, documentsTypeReset, subDocumentsTypeSuccess, documentTypeItemExpanded, documentCreateSuccess, subDocumentsTypeList } from "./escom.actions";
import { DocumentsTypeDTO } from "src/app/pages/escom-list/models";
import { EscomDocumentEnum } from "src/app/shared/core/enums/escom-document.enum";
import { ActiveEnum } from "src/app/shared/core/enums/active.enum";

export const initialState: EscomStateModel = {
    data: [],
    loading: false,
    failed: false
};

const _escomReducer = createReducer(initialState,
    on(documentsTypeList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(documentsTypeFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(documentsTypeSuccess, (state, { payload, idTableRef, idItemRef }) => {
        const data: DocumentsTypeDTO[] = [...state.data.filter(item => item.idTableRef == +idTableRef && item.idItemRef == +idItemRef)];
        const result = data.length > 0 ? [...state.data] : [...state.data, ...payload];
        return {
            ...state,
            data: [...result],
            loading: false,
            failed: false
        }
    }),
    on(subDocumentsTypeList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(subDocumentsTypeSuccess, (state, { idTableFatherRef, idItemFatherRef, payload }) => {
        const data = [...state.data];
        const index = data.findIndex(x => x.idTable == +idTableFatherRef && x.idItem == +idItemFatherRef);
        if (index > -1) {
            const item = {...(data[index])};
            item.subDocumentType = [...payload];
            data.splice(index, 1, item);
        }
        
        return {
            ...state,
            data: [...data],
            loading: false
        }
    }),
    on(documentTypeItemExpanded, (state, { documentType, expanded }) => {
        const data = [...state.data];
        const index = data.findIndex(x => x.idTable == documentType.idTable && x.idItem == documentType.idItem);
        if (index > -1) {
            const item = {...(data[index])};
            item.expanded = expanded;
            data.splice(index, 1, item);
        }
        return {
            ...state,
            data: [...data]
        }
    }),
    on(documentCreateSuccess, (state, { request }) => {
        let data: DocumentsTypeDTO[] = [...state.data];
        if (request.idTableRef) {
            const index = data.findIndex(x => x.idTable == request.idTableRef && x.idItem == request.idItemRef);
            const document = { ...data[index] };
            const subIndex = document?.subDocumentType.findIndex(item => item.idTable == request.idTable && item.idItem == request.idItem);
            if (subIndex > -1) {
                const subDocuments = [ ...document.subDocumentType ];
                const sub = { ...subDocuments[subIndex], flagDocumentItem: 1 };
                subDocuments.splice(subIndex, 1, sub);
                if (document.flagChildren == EscomDocumentEnum.SPECIAL_CASE && document.flagDocumentItem == ActiveEnum.INACTIVE) {
                    document.flagDocumentItem = ActiveEnum.ACTIVE;
                }
                document.subDocumentType = [...subDocuments];
                data.splice(index, 1, document);
            }
        } else {
            const index = data.findIndex(x => x.idTable == request.idTable && x.idItem == request.idItem);
            if (index > -1) {
                const document = { ...data[index], flagDocumentItem: 1};
                data.splice(index, 1, document);
            }
        }
        return {
            ...state,
            data: [...data]
        }
    }),
    on(documentsTypeReset, state => state = initialState)
);

export function escomReducer(state: any, action: any): EscomStateModel {
    return _escomReducer(state, action);
}
