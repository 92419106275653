<div class="content-home">
  <div class="content-home-sub">
    <div>
      <div class="VP_principal"></div>
      <h1 class="content-home-title1">Hola,</h1>
      <h1 class="content-home-title2">{{this.lastnameUser | uppercase}},<br>{{this.nameUser}}</h1>
      <p class="content-home-parrafo"><b>¡Nos encanta tenerte aqu&iacute;!</b>, un mundo de <br>oportunidades para tu excelente gestión te espera.</p>
    </div>
    <div style="display: flex; flex-wrap: wrap; align-content: center">
      <img src="assets/img/img-bienvenida.svg" class="img-principal" alt=""/>
    </div>

    <div *ngIf="principalFormService.activeView" class="content-home_view-img" (click)="principalFormService.showModal = true">
      <img src="../../../assets/img/icon-eye-user.svg" alt="view">
    </div>

    <mat-card *ngIf="principalFormService.showModal">

      <mat-card-header>

        <div class="content-home_view-header">

          <div class="content-home_view-header-containerImg">
            <div class="content-home_view-header-containerImg iconUser">
              <img src="../../../assets/img/icon-user-blue.svg" alt="User">
            </div>
            <div class="content-home_view-header-containerImg iconLogOut" (click)="cancelChangeUser()">
              <img src="../../../assets/img/icon-close-eyer-userA.svg" alt="logOut">
            </div>
          </div>

          <div class="content-home_view-header-containerText">
            <mat-card-title>Usuario</mat-card-title>
            <mat-card-subtitle>{{this.lastnameUser}},{{this.nameUser}}</mat-card-subtitle>
            <p>Registro: {{this.user}}</p>
          </div>
        </div>
      </mat-card-header>
      <hr class="line-solid with-margin" />
      <mat-card-content>

        <div class="content-home_view-content">

          <div class="content-home_view-content-containerImg">

            <div class="content-home_view-content-containerImg iconView">
              <img src="../../../assets/img/icon-eye-blue.svg" alt="User">
            </div>
            <mat-label>Cambiar Visualización por:</mat-label>
          </div>


          <div class="content-home_view-content-containerSearch">
            <app-search
              placeholder="Ingresa el registro"
              (search)="searchUser($event)"
            ></app-search>
            <div *ngIf="this.error" class="content-home_view-content-containerSearch error">
              <img src="/assets/img/icon_error_modal.svg" alt="error">
              <p>Código no válido</p>
            </div>
            <div class="content-home_view-content-containerSearch result">
              <p class="content-home_view-content-containerSearch result-p1">Resultado de la busqueda:</p>
              <p class="content-home_view-content-containerSearch result-p2">{{principalFormService.userSearch.name}}</p>
              <p class="content-home_view-content-containerSearch result-p3">Registro: {{principalFormService.userSearch.user}}</p>
            </div>
          </div>
        </div>

      </mat-card-content>

      <mat-card-actions>
        <div class="content-home_view-actions">
          <button mat-stroked-button class="request" (click)="changeUser()" [disabled]="disabledButton" [ngClass]="{'disabled': disabledButton}">
            Ver Como
          </button>
        </div>
      </mat-card-actions>

    </mat-card>

  </div>
</div>
