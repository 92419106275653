import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OperationService } from 'src/app/services/operation.service';
// import { pipelineAddItems } from '../pipeline/pipeline.actions';
// import { pipelineAddItems } from '../pipeline/pipeline.actions';
import { operationSave, operationSuccess, operationFailed } from './new-operation.actions';

@Injectable()
export class NewOperationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly operationService: OperationService
    ) { }

    registerOperations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(operationSave),
            mergeMap((action) =>
                this.operationService.registerOperations(action.payload)
                    .pipe(
                        map((data) => {
                            return operationSuccess({ payload: data });
                        }),
                        catchError((error) => of(operationFailed(error)))
                    )
            )
        )
    )

}
