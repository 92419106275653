import { createAction, props } from "@ngrx/store";
import { InstructiveDTO, InstructiveGetDTO } from "src/app/pages/instructive/models";

export const instructiveList = createAction('[Instructive] List',
    props<{payload: InstructiveGetDTO}>()    
);
export const instructiveFailed = createAction('[Instructive] Failed',
    props<{payload: any}>()
);

export const instructiveSuccess = createAction('[Instructive] Success',
    props<{payload: InstructiveDTO[]}>()
);

export const instructiveReset = createAction(
    '[Instructive] Reset'
);
