import { createReducer, on } from "@ngrx/store";
import { FamilyClientListStateModel } from "./family-client-list.model";
import { familyClientList, familyClientSuccess, familyClientFailed, familyClientReset } from './family-client-list.actions';

export const initialState: FamilyClientListStateModel = {
    data: null,
    loading: false,
    failed: false,
    loaded: false,
    error: null
};

const _familyClientListReducer = createReducer(initialState,
    on(familyClientList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(familyClientFailed, (state, payload) => {
        return {
            ...state,
            data: null,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(familyClientSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(familyClientReset, state => state = initialState),
    
);

export function familyClientListReducer(state: any, action: any): FamilyClientListStateModel {
    return _familyClientListReducer(state, action);
}
