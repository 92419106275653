import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectNewList = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.new
);


export const selectNewLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.new.loading
);
