import { createReducer, on} from "@ngrx/store";
import { attributesList, attributesFailed, attributesSuccess, attributesReset } from "./attributes.actions";
import { CampaignStateModel } from "./attributes.model";

export const initialState: CampaignStateModel = {
    client: [],
    // campaign: [],
    stateInstance: [],
    products: [],
    loading: false,
    failed: false,
    loaded: false
};

const _attributesReducer = createReducer(initialState,
    on(attributesList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(attributesFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(attributesSuccess, (state, payload: any) => {
        return {
            ...state,
            client: payload ? payload.attributeClient : [],
            // campaign: payload ? payload.attributeCampaign : [],
            stateInstance: payload ? payload.state : [],
            products: payload ? payload.product : [],
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(attributesReset, state => state = initialState)
);

export function attributesReducer(state: any, action: any): CampaignStateModel {
    return _attributesReducer(state, action);
}
