import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CampaignSettingsService } from 'src/app/services/campaign-settings.service';
import {
    campaignFailed, campaignList, campaignSuccess
} from './campaign-list.actions';

@Injectable()
export class CampaignListEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly campaignSettingsService: CampaignSettingsService
    ) { }

    getCampaigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(campaignList),
            mergeMap((action) => {
                const request = {
                    limit: action?.limit,
                    offset: action?.offset,
                    state: action?.state,
                    name: action?.name,
                    periodIn: action?.periodIn,
                    periodFi: action?.periodFi,
                    type: action?.typeCampaign
                };
                return this.campaignSettingsService.getCampaigns(request)
                    .pipe(
                        map((data) => {
                            return campaignSuccess({ payload: data });
                        }),
                        catchError((error) => of(campaignFailed(error)))
                    )
            })
        )
    )

}
