import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CampaignSettingsService } from 'src/app/services/campaign-settings.service';
import { productsWithFileList, productsWithFileFailed, productsWithFileSuccess } from './products-file.actions';

@Injectable()
export class ProductsWithFileEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly campaignSettingsService: CampaignSettingsService
    ) { }

    getProductsWithFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(productsWithFileList),
            mergeMap((action) =>
                this.campaignSettingsService.getProductsWithFile(action.id)
                    .pipe(
                        map((data) => {
                            const productList = data ? data.productList || [] : [];
                            return productsWithFileSuccess({ payload: productList });
                        }),
                        catchError((error) => of(productsWithFileFailed(error)))
                    )
            )
        )
    )
}
