<div class="add-operation" [ngClass]="{'error': quantity.invalid}">
    <div class="buttons">
        <a href="javascript:void(0)" (click)="aggregate()">
            +
        </a>
        <a href="javascript:void(0)" (click)="subtract()">
            -
        </a>
    </div>
    <mat-form-field appearance="fill">
        <input matInput [formControl]="quantity">
    </mat-form-field>
    <a href="javascript:void(0)"
        (click)="add()"
        class="add-operation__submit">
        <img [src]="image" alt="img" class="image" *ngIf="showImage">
        <span>
            {{description}}
        </span>
    </a>
</div>
