import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailContactComponent } from './pages/detail-contact/detail-contact.component';
import { ListContactComponent } from './pages/list-contact/list-contact.component';
import { ListContactDualViewComponent } from './pages/list-contact-dual-view/list-contact-dual-view.component';

const routes: Routes = [
    {
        path: 'contact-list',
        component: ListContactComponent
    },
    {
        path: 'contact-detail/:idContactSelect/:ruc',
        component: DetailContactComponent
    },
    {
        path: 'contact-dual-view',
        component: ListContactDualViewComponent
    },
    {
        path: '',
        redirectTo: 'contact-list',
        pathMatch: 'full',
    }
];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContactsRoutingModule { }