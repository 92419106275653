import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeInformationModal } from '../information-modal/information-modal.model';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  //color icon default
  public src:string = "/assets/img/information.svg";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.setImg();
  }

  public setImg(){
    let type: TypeInformationModal = this.data.type;
    switch (type) {
      case TypeInformationModal.SUCCESS:
        this.src = "/assets/img/check_valid.svg";
        break;
      case TypeInformationModal.ERROR:
        this.src = "/assets/img/icon_error_modal.svg";
        break;
      case TypeInformationModal.WARNING:
        this.src = "/assets/img/information.svg";
        break;
    }
  }

}
