import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../shared/dashboard/dashboard.component';
import { AccessGuard } from '../shared/guards/access.guard';
import { NoAccessComponent } from './no-access/no-access.component';
import { PagesComponent } from './pages.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PrincipalComponent } from './principal/principal.component';

const routes: Routes = [
    {
        path: 'dashboard',
        component: PagesComponent,
        canActivate: [
          AccessGuard
        ],
        children: [
          { path: '', component: DashboardComponent },
          {
            path: 'clients',
            loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
          },
          {
            path: 'enote',
            loadChildren: () => import('./enote-config/enote-config.module').then(m => m.EnoteConfigModule)
          },
          {
            path: 'contacts',
            loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
          },
          { path: 'tableros', component: PortfolioComponent },
          { path: 'saldosDiario', component: PortfolioComponent },
          { path: 'ficha360', component: PortfolioComponent },
          {
            path: 'campaign',
            loadChildren: () => import('./campaign-config/campaign-config.module').then(m => m.CampaignConfigModule)
          },
          {
            path: 'client-list',
            loadChildren: () => import('./client-list/client-list.module').then(m => m.ClientListModule)
          },
          {
            path: 'instructive',
            loadChildren: () => import('./instructive/instructive.module').then(m => m.InstructiveModule)
          },
          { path: 'bienvenido', component: PrincipalComponent },

          {
            path: 'NPS',
            loadChildren: () => import('./nps/nps.module').then(m => m.NpsModule)
          },
          {
            path: 'report-list',
            loadChildren: () => import('./report-list/report-list.module').then(m => m.ReportListModule)
          },
          {
            path: 'resectorizacion',
            loadChildren: () => import('./resectorizacion/resectorizacion.module').then(m => m.ResectorizacionModule)
          },
          {
            path: 'CE',
            loadChildren: () => import('./electronic-voucher/electronic-voucher.module').then(m => m.ElectronicVoucherModule)
          },
          {
            path: 'administration',
            loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
          },
          {
            path: 'closeness',
            loadChildren: () => import('./closeness/closeness.module').then(m => m.ClosenessModule)
          },
          {
            path: 'escom',
            loadChildren: () => import('./escom-list/escom-list.module').then(m => m.EscomListModule)
          },
          {
            path: 'powerBI',
            loadChildren: () => import('./poweBI/reportBI.module').then(m => m.ReportBIModule)
          },
          {
            path: 'qualitative-sheet',
            loadChildren: () => import('./qualitative-sheet/qualitative-sheet.module').then(m => m.QualitativeSheetModule)
          }
        ]
    },
    {
      path: 'sin-acceso',
      component: PagesComponent,
      children: [
        {
          path: '',
          component: NoAccessComponent
        }
      ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
