<mat-toolbar color="primary">
    <mat-toolbar-row>
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <li>
                    <button mat-icon-button>
                        <mat-icon>notifications</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button>
                        <mat-icon>person</mat-icon>
                    </button>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>