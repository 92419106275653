import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { map, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpeechNotification } from 'src/app/models/speech-notification';
import { BaseComponent } from 'src/app/shared/core/base/base.component';
import { SpeechErrorEnum } from 'src/app/shared/core/enums/speech-error.enum';
import { SpeechEventEnum } from 'src/app/shared/core/enums/speech-event.enum';
import { SpeechRecognizerService } from 'src/app/shared/core/services/speech-recognizer.service';

@Component({
  selector: 'app-speech-to-text',
  templateUrl: './speech-to-text.component.html',
  styleUrls: ['./speech-to-text.component.scss'],
  providers: [SpeechRecognizerService]
})
export class SpeechToTextComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() onMessage = new EventEmitter<string>();
  @Output() onError = new EventEmitter<string>();
  @Output() listening = new EventEmitter<boolean>(false);
  defaultError$ = new Subject<string | undefined>();
  totalTranscript: string = '';
  mensajeTemporal: string;

  constructor(public speechRecognizer: SpeechRecognizerService) {
    super();

  }

  ngOnInit(): void {
    const webSpeechReady = this.speechRecognizer.initialize();
    if (webSpeechReady) {
      this.initRecognition();
    } else {
      this.onError.emit('Tu navegador no es compatible. Por favor prueba Google Chrome.');
    }
  }

  override ngOnDestroy(): void {
      super.ngOnDestroy();
      this.speechRecognizer.stop();
  }

  start(): void {
    if (this.speechRecognizer.isListening) {
      this.stop();
      return;
    }

    this.defaultError$.next(undefined);
    this.speechRecognizer.start();
  }

  stop(): void {
    this.speechRecognizer.stop();
  }

  private initRecognition(): void {
    this.speechRecognizer.onResult()
    .pipe(takeUntil(this.destroy$))
    .subscribe(notification => this.processNotification(notification));

    this.onListening();
    this.setError();
  }

  private onListening(): void {
    merge(this.speechRecognizer.onStart(), this.speechRecognizer.onEnd())
      .pipe(map((notification) => notification.event === SpeechEventEnum.Start))
      .pipe(takeUntil(this.destroy$))
      .subscribe((isListening) => {
        this.listening.emit(isListening);
      });
  }

  private setError(): void {
    merge(
      this.speechRecognizer.onError(),
      this.defaultError$
    ).pipe(map((data) => (data ? this.getErrorMessage(data) : '')))
    .subscribe(message => this.onError.emit(message));
  }

  getErrorMessage(data: SpeechNotification<never> | string) {
    if (typeof data === 'string') return data;

    let message;
    switch (data.error) {
      case SpeechErrorEnum.NotAllowed:
        message = `Su navegador no está autorizado a acceder a su micrófono. Verifique que su navegador tenga acceso a su micrófono e inténtelo nuevamente.`;
        break;
      case SpeechErrorEnum.NoSpeech:
        message = `No se ha detectado ninguna voz. Inténtalo de nuevo.`;
        break;
      case SpeechErrorEnum.AudioCapture:
        message = `El micrófono no está disponible. Verifique la conexión de su micrófono e inténtelo nuevamente.`;
        break;
      default:
        message = '';
        break;
    }
    return message;
  }

  private processNotification(notification: SpeechNotification<string>): void {
    this.mensajeTemporal = this.getMessage(notification);
    this.onMessage.emit(this.mensajeTemporal);
    if (notification.event === SpeechEventEnum.FinalContent) {
      this.totalTranscript = this.getMessage(notification);
      this.onMessage.emit(this.totalTranscript);
      this.mensajeTemporal = '';
    }
  }

  getMessage(notification: SpeechNotification<string>) {
    const message = notification.content?.trim() || '';
    return this.totalTranscript
      ? `${this.totalTranscript} ${message}`
      : message;
  }
}
