import { AbstractControl } from "@angular/forms";

export const checkedMinLengthArray = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    const exist = control.value.some((x: any) => x.checked);
      if (exist) {
        return null;
      } else {
        return { checkedMinLengthArray: true };
      }
  };