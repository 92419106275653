import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { instructiveProductReset, instructiveProductsList, instructiveRemoveProduct, instructiveUpdateProduct } from "./instructive-products.actions";
import { selectInstructiveProducts } from "./instructive-products.selectors";

@Injectable()
export class InstructiveProductsFacade {

    constructor(private readonly store: Store) {}
    
    getProductsInstructive(idCampaign: number | null) {
        this.store.dispatch(instructiveProductsList({ idCampaign }));
    }

    removeProduct(idElementFormat: number) {
        this.store.dispatch(instructiveRemoveProduct({ idElementFormat }));
    }

    updateProduct(idElementFormat: number, name: string) {
        this.store.dispatch(instructiveUpdateProduct({ idElementFormat, name }));
    }

    selectInstructiveProducts() {
        return this.store.select(selectInstructiveProducts);
    }

    reset() {
        this.store.dispatch(instructiveProductReset());
    }

}