<div
    class="tooltip-ui animate__animated animate__fadeInUp"
    [style.width]="width"
    [style.top]="top"
    [style.right]="right"
    [style.left]="left"
    [style.background-color]="bgColor"
    [style.color]="color"
    *ngIf="open"
    >
    <ng-content></ng-content>
</div>
