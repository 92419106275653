import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { OperationGetRequest } from "src/app/pages/enote-config/pages/enote/models";
import { pipelineList, pipelineReset, pipelineCancel, pipelineRemoveItemSuccess } from "./pipeline.actions";
import { selectPipelineOperations, selectPipelineOperationsLoading } from "./pipeline.selectors";

@Injectable()
export class PipelineFacade {

    constructor(private readonly store: Store) {}
    
    getPipelines(request: OperationGetRequest) {
        this.store.dispatch(pipelineList({ payload: request}));
    }

    reset() {
        this.store.dispatch(pipelineReset());
    }

    cancel(data: any) {
        this.store.dispatch(pipelineCancel({ payload: data }));
    }

    selectPipelineOperations() {
        return this.store.select(selectPipelineOperations);
    }

    selectPipelineOperationsLoading() {
        return this.store.select(selectPipelineOperationsLoading);
    }

    removeItem(id: number) {
        this.store.dispatch(pipelineRemoveItemSuccess({ id }));
    }

}