import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { AttributesEffects } from './state/attributes/attributes.effects';
import { CampaignStateModel } from './state/attributes/attributes.model';
import { attributesReducer } from './state/attributes/attributes.reducer';
import { inputsStatesReducer } from './state/inputs-states/inputs-states.reducer';
import { InputsStatesModel } from './state/inputs-states/inputs-states.model';
import { CampaignConfigurationStateModel } from './state/configuration/configuration.model';
import { configurationsReducer } from './state/configuration/configuration.reducer';
import { CampaignConfigurationEffects } from './state/configuration/configuration.effects';
import { CampaignListStateModel } from './state/list/campaign-list.model';
import { campaignListReducer } from './state/list/campaign-list.reducer';
import { CampaignListEffects } from './state/list/campaign-list.effects';
import { productswithFileReducer } from './state/products-file/products-file.reducer';
import { ProductsWithFileEffects } from './state/products-file/products-file.effects';
import { ProductsWithFileStateModel } from './state/products-file/products-file.model';
import { ValueOfferStateModel } from './state/value-offer/value-offer.model';
import { valueOfferReducer } from './state/value-offer/value-offer.reducer';
import { ValueOfferEffects } from './state/value-offer/value-offer.effects';

export const CAMPAIGN_FEATURE_KEY = 'campaign';

export const selectCampaignState = createFeatureSelector<CampaignState>(
  CAMPAIGN_FEATURE_KEY
);

export const CampaignEffects: any[] = [
  AttributesEffects,
  CampaignConfigurationEffects,
  CampaignListEffects,
  ProductsWithFileEffects,
  ValueOfferEffects
];

export interface CampaignState {
  attributes: CampaignStateModel;
  inputsStates: InputsStatesModel;
  configuration: CampaignConfigurationStateModel
  list: CampaignListStateModel,
  productsFile: ProductsWithFileStateModel,
  valueOffer: ValueOfferStateModel
}

export const CampaignReducerModule: ActionReducerMap<CampaignState> = {
  attributes: attributesReducer,
  inputsStates: inputsStatesReducer,
  configuration: configurationsReducer,
  list: campaignListReducer,
  productsFile: productswithFileReducer,
  valueOffer: valueOfferReducer
};
