<div class="containerC">

  <!--Header-->
  <div class="containerC_header">
    <div class="containerC_header__text">
      <div class="containerC_header__text title">
        <ng-container *ngIf="leadSelected else contactTitle">
          <h1 (mouseenter)="showTooltip($event)" 
          (mouseleave)="hideTooltip()">{{leadSelected.company}}</h1>
          <app-tooltip-ui 
              #tooltip_title
              [top]="'35px'" 
              left="0"
              bgColor="#B7BABC">
              {{leadSelected?.company}}
          </app-tooltip-ui>
          <h5>Contactos</h5>
        </ng-container>
        <ng-template #contactTitle>
          <h1>Contactos</h1>
        </ng-template>
      </div>
    </div>

    <div class="containerC_header__actions">

      <div class="containerC_header__actions-search">
        <app-search *ngIf="!leadSelected" 
          placeholder="Busca aquí por Ruc, Nombre o Código único..." 
          (search)="searchContactByCustomer($event)">
        </app-search>
      </div>
      <div class="containerC_header__actions-contactadd">
        <app-add-operation 
          *hasPermission
          [description]="'Añadir contacto'" 
          [maxValue]="10" 
          [showImage]=true  
          [image]="'/assets/img/icon-user-btn.svg'" 
          (addClick)="addContact($event)">
        </app-add-operation>
      </div>
      <div *hasPermission class="containerC_header__actions-btnEdit"
          [ngClass]="{'disabled-control': listContacts.length == 0}"
          (click)="editContacts()">
        <div class="edit_content">
            <img src="/assets/img/edit_white.svg" alt="list">
            <span>Editar contacto</span>
        </div>
      </div>
      <div class="containerC_header__actions-filterhierarchy"
        *ngIf="!isExecutive"
        [ngClass]="{'disabled-filter': loading}" (click)="openFilter = true">
        <div class="containerC_header__actions-filterhierarchy_content">
          <img src="/assets/img/filter.svg" alt="filter">
        </div>
      </div>
      <app-vertical-modal 
        [ngClass]="{'display-none': !openFilter}"
        title="Filtros"
        (close)="openFilter = false"
      >
        <app-hierarchy
          (sendFilter)="filterHierarchy($event)"
          (firstHierarchiesLoaded)="setFirstHierarchy($event)">
        </app-hierarchy>
      </app-vertical-modal>
    </div>
  </div>

  <div class="containerC_header__btnLeads" *ngIf="leadSelected">
    <a class="back" href="javascript:void(0)"
        (click)="navigateLeadDetail()">
        <img src="/assets/img/arrow/arrow-back.svg" alt="<">
        <span>Retornar</span>
    </a>
  </div>

  <app-loading [loading]="loading"></app-loading>

  <!--Contenido-->
  <div class="containerC_contentList__body" [ngClass]="{'leads': !!leadSelected, 'padding-bottom-55': newRecords || typeOperation == 'edit'}">
    <ng-container>
      <div class="containerC_contentList__sticky-container">
        <div class="containerC_contentList__sticky-container__header"
          [ngClass]="{'leads': !!leadSelected}">
          <div class="containerC_contentList__sticky-container__header-company">
            Empresa
          </div>
          <div class="containerC_contentList__sticky-container__header-cu">
            CU / RUC
          </div>
          <div class="containerC_contentList__sticky-container__header-nameContact">
            <span>Nombre del contacto</span>
            <div class="searchContact">
              <button [ngClass]="{'disabled-control': listContacts.length == 0}" 
                [disabled]="listContacts.length == 0" 
                (click)="activateContactSearch = true">
                <img src="/assets/img/search-white.svg" alt="search">
              </button>
            </div>
          </div>
          <div class="containerC_contentList__sticky-container__header-position">
            Cargo
          </div>
          <div class="containerC_contentList__sticky-container__header-phone1">
            Telefono 1
          </div>
          <div class="containerC_contentList__sticky-container__header-phone2">
            Telefono 2
          </div>
          <div class="containerC_contentList__sticky-container__header-email">
            Email
          </div>
          <div class="containerC_contentList__sticky-container__header-lastUpdate">
            Fecha Ult. actualización
          </div>
          <div class="containerC_contentList__sticky-container__header-favorite">
            
          </div>
          <div class="containerC_contentList__sticky-container__header-remove">

          </div>

        </div>

        <div class="containerC_contentList__sticky-container__searchActions" *ngIf="activateContactSearch == true">
          <div class="containerC_contentList__sticky-container__searchActions-search">
            <app-search placeholder="Busca aquí al contacto..." (search)="searchContact($event)" outline=true></app-search>
          </div>
          <div class="containerC_contentList__sticky-container__searchActions-close" (click)="closeSearchContacts()">
            <img [src]="'/assets/img/icon-close-NPS.svg'" alt="img" class="image">
            <span>
              Cerrar
            </span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="listContacts.length > 0 else empty">
        <div *ngFor="let contact of listContacts; let i = index">
          <app-operation-contact-list
            [typeOperation]="typeOperation"
            [index]="i"
            (removeContact)="removeContact($event)"
            [itemContact]="contact"
            [searchActivate]="activeSearch"
            (contactUpdate)="prepareContact($event)"
            [getCompanyName]="getCompanyName.bind(this)"
            [leads]="!!leadSelected"
            (reload)="getContacts()"
          >
          </app-operation-contact-list>
  
          <hr class="line-solid with-margin" />
        </div>
      </ng-container>
      <ng-template #empty>
        <h4 class="containerC_contentList__emptyText">
          {{leadSelected ? 'El cliente no cuenta con contactos' : 'No se tienen registro de contactos'}}
        </h4>
      </ng-template>
    </ng-container>

  </div>

  <div class="containerC_buttons" *ngIf="(typeOperation == 'edit' || newRecords)">
    <div class="containerC_buttons-actions">
      <button *hasPermission mat-button class="save" color="primary" (click)="save()" [ngClass]="{'incomplete': incomplete}" [disabled]="incomplete || isButtonDisabled">
        Guardar
      </button>
      <button mat-button class="cancel" (click)="cancel()">
        Cancelar
      </button>
    </div>

    <ng-container *ngIf="invalidContactAcept">
      <div class="containerC_buttons-v_line"> </div>
      <div class="containerC_buttons-menssage_icon">
        <img src="/assets/img/information.svg" alt="info">
      </div>
      <div class="containerC_buttons-menssage_text">
        <p>No todos los campos cumplen con el formato adecuado.<br> ¿Deseas guardarlos?</p>
      </div>
    </ng-container>
  </div>

</div>
