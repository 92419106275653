import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationComponent,
  InformationModalComponent,
} from '../../ui/components';
import { TypeInformationModal } from '../../ui/components/information-modal/information-modal.model';

@Injectable()
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  informationModal(
    text: string,
    type: TypeInformationModal = TypeInformationModal.SUCCESS,
    timeOut: number = 2500,
    disableClose: boolean = true,
    width: string = '500px',
  ) {
    return this.dialog.open(InformationModalComponent, {
      width,
      disableClose,
      data: {
        description: text,
        type: type,
        timeOut
      },
    });
  }

  confirmationModal(
    description: string,
    okText: string = 'Aceptar',
    cancelText: string = 'Cancelar',
    type: TypeInformationModal = TypeInformationModal.WARNING,
    width: string = '500px'
  ) {
    return this.dialog.open(ConfirmationComponent, {
      width,
      disableClose: true,
      data: {
        description,
        type,
        okText,
        cancelText,
      },
    });
  }
}
