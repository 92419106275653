import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { CampaignReducerModule, CampaignEffects } from './campaign.store';
import { EffectsModule } from '@ngrx/effects';
import { CAMPAIGN_FEATURE_KEY } from './campaign.store';
import { AttributesFacade } from './state/attributes/attributes.facade';
import { InputsStatesFacade } from './state/inputs-states/inputs-states.facade';
import { ProductsFileFacade } from './state/products-file/products-file.facade';
import { ValueOfferFacade } from './state/value-offer/value-offer.facade';
import { ConfigurationCampaignFacade } from './state/configuration/configuration.facade';
import { CampaignListFacade } from './state/list/campaign-list.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CAMPAIGN_FEATURE_KEY, CampaignReducerModule),
    EffectsModule.forFeature(CampaignEffects),
  ],
  providers: [
    AttributesFacade,
    InputsStatesFacade,
    ProductsFileFacade,
    ValueOfferFacade,
    ConfigurationCampaignFacade,
    CampaignListFacade
  ],
})
export class CampaignModule {}
