import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { lossesList, lossesReset } from "./losses.actions";
import { selectLossesList, selectLossesListLoading } from "./losses.selectors";

@Injectable()
export class LossesFacade {

    constructor(private readonly store: Store) {}
    
    getLosses(request: any) {
        this.store.dispatch(lossesList({ payload: request }));
    }

    reset() {
        this.store.dispatch(lossesReset());
    }

    selectLosses() {
        return this.store.select(selectLossesList);
    }

    selectLossesLoading() {
        return this.store.select(selectLossesListLoading);
    }

}