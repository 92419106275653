import { CampaignModule } from './campaign/campaign.module';
import { EnoteModule } from './enote/enote.module';
import { ParamsModule } from './params/params.module';
import { ClientModule } from './client/client.module';
import { InstructiveModule } from './instructive/instructive.module';
import { ClosenessModule } from './closeness/closeness.module';
import { EscomModule } from './escom/escom.module';
import { QualitativeStoreModule } from './qualitative/qualitative.module';
import { FinancialStoreModule } from './financial/financial.module';

export const STORE_MODULES = [
    CampaignModule,
    EnoteModule,
    ParamsModule,
    ClientModule,
    InstructiveModule,
    ClosenessModule,
    EscomModule,
    QualitativeStoreModule,
    FinancialStoreModule
];

export * from './campaign/campaign.module';
export * from './enote/enote.module';
export * from './client/client.module';
export * from './instructive/instructive.module';
export * from './closeness/closeness.module';
export * from './escom/escom.module';
export * from './qualitative/qualitative.module';
export * from './financial/financial.module';
