import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { ClosenessListEffects } from './state/closeness-list/closeness-list.effects';
import { ClosenessListStateModel } from './state/closeness-list/closeness-list.model';
import { closenessReducer } from './state/closeness-list/closeness-list.reducer';

export const CLOSENESS_FEATURE_KEY = 'closeness';

export const selectClosenessState = createFeatureSelector<ClosenessState>(
    CLOSENESS_FEATURE_KEY
);

export const ClosenessEffects: any[] = [
  ClosenessListEffects
];

export interface ClosenessState {
  closeness: ClosenessListStateModel
}

export const ClosenessReducerModule: ActionReducerMap<ClosenessState> = {
  closeness: closenessReducer
};
