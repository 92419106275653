import { AbstractControl } from '@angular/forms';
import { numeric, decimal } from '../../core/utils';

export const inputOnlyNumbersValidator = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const value = control.value;
    if (value) {
        let pattern = new RegExp(numeric());
        let valid = pattern.test(value);
        if (!valid) {
            const found = value.match(/[0-9]/g);
            const text = found ? found.join('') : '';
            control.setValue(text, { emitEvent: false });
        }
    }
    return null;
};

export const inputOnlyDecimalValidator = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const value = control.value;
    if (value) {
        let pattern = new RegExp(decimal());
        let valid = pattern.test(value);
        if (!valid) {
            const found = value.match(/[.0-9]/g);
            const text = found ? found.join('') : '';
            control.setValue(text, { emitEvent: false });
        } else {
            const length = value?.toString()?.split('.')?.length || 0;
            if (length > 2) {
                const val = value.toString()?.substring(0, value.length - 1);
                control.setValue(val, { emitEvent: false });
            } else {
                const data = value?.toString()?.split('.') || [];
                if (data[1]?.length > 2) {
                    const val = value.substring(0, value.length - 1);
                    control.setValue(val, { emitEvent: false });
                }
            }
        }
    }
    return null;
};

export const inputOnlyPercentValidator = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const value = control.value;
    if (value) {
        let pattern = new RegExp(decimal());
        let valid = pattern.test(value);
        if (!valid) {
            const found = value.match(/[.0-9]/g);
            const text = found ? found.join('') : '';
            control.setValue(text, { emitEvent: false });
        } else {
            const data = +value;
            const length = value?.toString()?.split('.')?.length || 0;
            if ((data > 100 || (data == 100 && value.length > 3)) || length > 2) {
                const val = value.toString()?.substring(0, value.length - 1);
                control.setValue(val, { emitEvent: false });
            } else {
                const data = value?.toString()?.split('.') || [];
                if (data[1]?.length > 2) {
                    const val = value.substring(0, value.length - 1);
                    control.setValue(val, { emitEvent: false });
                }
            }
        }
    }
    return null;
};