import { createSelector } from "@ngrx/store";
import { ParamsState, selectParamsState } from "../../params.store";


export const selectEnoteProducts = createSelector(
    selectParamsState,
    (state: ParamsState) => state.products
);

export const selectEnoteProductsData = createSelector(
    selectParamsState,
    (state: ParamsState) => state.products.data
);