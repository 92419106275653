<img
  *ngIf="speechRecognizer.isListening; else mic"
  src="/assets/img/iconos/voice-start.svg"
  (click)="stop()"
  alt="stop"
/>

<ng-template #mic>
  <img src="/assets/img/iconos/voice.svg" (click)="start()" alt="start" />
</ng-template>
