import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { operationSave, operationReset } from "./new-operation.actions";
import { selectNewOperation } from "./new-operation.selectors";

@Injectable()
export class NewOperationFacade {

    constructor(private readonly store: Store) {}
    
    saveOperation(request: any) {
        this.store.dispatch(operationSave({ payload: request }));
    }

    reset() {
        this.store.dispatch(operationReset());
    }

    selectNewOperation() {
        return this.store.select(selectNewOperation);
    }

}