import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { materialModule } from 'src/material';
import { HttpClientModule } from '@angular/common/http';
import { ContactService } from './service/contact.service';
import { ListContactComponent } from './pages/list-contact/list-contact.component';
import { ContactsRoutingModule } from './contacts.routing';
import { DetailContactComponent } from './pages/detail-contact/detail-contact.component';
import { ListContactDoubleComponent } from './components/list-contact-double/list-contact-double.component';
import { ListContactDualViewComponent } from './pages/list-contact-dual-view/list-contact-dual-view.component';
import { DialogContactComponent } from './components/dialog-contact/dialog-contact.component';
import { OperationContactListComponent } from './components/operation-contact-list/operation-contact-list.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    ListContactComponent,
    DetailContactComponent,
    ListContactDoubleComponent,
    ListContactDualViewComponent,
    DialogContactComponent,
    OperationContactListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    materialModule,
    HttpClientModule,
    ContactsRoutingModule,
    ComponentsModule,
    UiModule,
    MatTooltipModule
  ],
  providers: [ContactService]
})
export class ContactsModule { }
