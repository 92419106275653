import { createSelector } from '@ngrx/store';
import { EnoteState, selectEnoteState } from '../../../enote.store';

export const selectPipelineOperations = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.pipeline
);

export const selectPipelineOperationsLoading = createSelector(
    selectEnoteState,
    (state: EnoteState) => state.pipeline.loading 
);
