import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() {}

  getItem<T>(key: string): T {
    const data: any = localStorage.getItem(key) ?? null;
    return JSON.parse(data) as T;
  }

  setItem<T>(key: string, value: T): void {
    const data = JSON.stringify(value ?? '');
    localStorage.setItem(key, data);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

}
