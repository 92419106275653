import { createAction, props } from "@ngrx/store";
import { ParamsDTO } from "src/app/models/params.dto";

export const paramsDataList = createAction('[Params Data] List',
    props<{id: number}>()    
);
export const paramsDataFailed = createAction('[Params Data] Failed',
    props<{payload: any}>()
);
export const paramsDataSuccess = createAction('[Params Data] Success',
    props<{payload: { tableId: number, parameters: ParamsDTO[] }}>()
);

