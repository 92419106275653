import { Component, OnInit } from '@angular/core';
import { PrincipalFormService } from '../principal/services/principal-form.service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

  nameUser: string;
  lastnameUser: string;

  constructor(public readonly principalFormService: PrincipalFormService) { }

  ngOnInit(): void {
    const usuarioLoginStorage = localStorage.getItem("usuarioLogin");
    if (usuarioLoginStorage) {
      const user = JSON.parse(usuarioLoginStorage);
      const userArr = user?.name?.split(',') || []
      this.lastnameUser = userArr[0];
      this.nameUser = userArr[1];
    }
  }

}
