import { Injectable } from "@angular/core";
import { ParametersEnum } from "src/app/shared/core/enums/parameters.enum";
import { AttributesFacade } from "src/app/store/campaign/state/attributes/attributes.facade";
import { ParamsFacade } from "src/app/store/params/state/params/params.facade";

@Injectable()
export class CampaignConfigFacadeService {

    campaignTypes$ = this.paramsFacade.selectParamsData(ParametersEnum.campaignTypes);
    attributeType$ = this.paramsFacade.selectParamsData(ParametersEnum.attributeType);
    inputAttribute$ = this.paramsFacade.selectParamsData(ParametersEnum.inputAttribute);
    campaignProductsTypes$ = this.paramsFacade.selectParamsData(ParametersEnum.campaignProductsTypes);
    campaignState$ = this.paramsFacade.selectParamsData(ParametersEnum.campaignState);
    campaignStateValidate$ = this.paramsFacade.selectParamsData(ParametersEnum.campaignStateValidate);

    selectAttributes$ = this.attributesFacade.selectAttributes();
    selectCustomerAttributes$ = this.attributesFacade.selectCustomerAttributes();
    selectStateInstance$ = this.attributesFacade.selectStateInstance();
    selectProductsCampaign$ = this.attributesFacade.selectProductsCampaign();

    constructor(private readonly paramsFacade: ParamsFacade,
        private readonly attributesFacade: AttributesFacade) {}
    
    getDetailCatalog() {
        this.attributesFacade.getDetailCatalog();
    }

    getCampaignTypes() {
        this.paramsFacade.getParams(ParametersEnum.campaignTypes);
    }

    getAttributeTypes() {
        this.paramsFacade.getParams(ParametersEnum.attributeType);
    }

    getInputAttribute() {
        this.paramsFacade.getParams(ParametersEnum.inputAttribute);
    }

    getCampaignProductsTypes() {
        this.paramsFacade.getParams(ParametersEnum.campaignProductsTypes);
    }

    getCampaignStates() {
        this.paramsFacade.getParams(ParametersEnum.campaignState);
    }

    getCampaignStateValidate() {
        this.paramsFacade.getParams(ParametersEnum.campaignStateValidate);
    }

}