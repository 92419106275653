import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ContactsFacadeService } from '../../services/contacts-facade.service';
import { TableHeaderModel } from 'src/app/models/table-header.model';
import { CustomerContactUniverseDTO } from '../../models/customer-contact-universe.dto';
import { BaseComponent } from 'src/app/shared/core/base/base.component';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { email, phone } from 'src/app/shared/core/utils';
import { inputAlphabeticWithAccentValidator } from 'src/app/shared/ui/validators/input-alphabetic-accent.validator';
import { inputPhoneValidator } from 'src/app/shared/ui/validators/input-phone.validator';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-contact-list-detail',
  templateUrl: './contact-list-detail.component.html',
  styleUrls: ['./contact-list-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactListDetailComponent extends BaseComponent implements OnInit {

  @Input() documentNumber: string;
  @Input() documentType: number;
  @Output() onClose = new EventEmitter();
  @Output() onRefresh = new EventEmitter();

  tableHeaders: TableHeaderModel[] = [
    { label: '', name: 'situation', width: '25px', filter: false, sort: false },
    { label: 'Nombre de contacto', name: 'contactName', width: 'calc(15% - 25px)', filter: false, sort: false },
    { label: 'Documento', name: 'nunDocContact', width: '10%', filter: false, sort: false },
    { label: 'Cargo', name: 'position', width: '13%', filter: false, sort: false },
    { label: 'Correo', name: 'emailOne', width: '15%', filter: false, sort: false },
    { label: 'Teléfono 1', name: 'phoneOne', width: '12%', filter: false, sort: false },
    { label: 'Teléfono 2', name: 'phoneTwo', width: '12%', filter: false, sort: false },
    { label: 'Ult. actualización', name: 'lastUpdate', width: '10%', filter: false, sort: false },
    { label: '', name: 'icon', width: '13%', filter: false, sort: false },
  ];
  contactList: CustomerContactUniverseDTO[] = [];
  loading: boolean = false;
  contactForm: FormArray = this.fb.array([]);
  writingMode: boolean = false;
  searchText: string;

  constructor(private readonly contactsFacadeService: ContactsFacadeService,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe
  ) { 
    super();
  }

  ngOnInit(): void {
    this.getContactUniverse();
  }

  createContactForm() {
    this.contactForm.clear();
    this.contactList.forEach(item => {
      const form = this.createContactItemForm(false, item);
      this.contactForm.push(form);
    });
  }

  createContactItemForm(writingMode: boolean, contact: CustomerContactUniverseDTO) {
    let patternTelefono = phone();
    return this.fb.group({
      writingMode: [writingMode],
      idContact: [contact.idContact],
      nunDocContact: [contact.nunDocContact || '', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      contactName: [contact.name, [Validators.required, Validators.minLength(2), inputAlphabeticWithAccentValidator]],
      position: [contact.position, [Validators.min(1)]],
      positionId: [writingMode ? -1 : contact.positionId, [Validators.required, Validators.min(1)]],
      phoneOne: [contact.phoneOne, [Validators.pattern(patternTelefono), inputPhoneValidator, Validators.minLength(6), Validators.maxLength(15)]],
      phoneTwo: [contact.phoneTwo, [Validators.pattern(patternTelefono), inputPhoneValidator, Validators.minLength(6), Validators.maxLength(15)]],
      situation: [contact.situation],
      lastUpdate: [contact.lastUpdate],
      favorite: [contact.favorite || false],
      emailOne: [contact.email, [Validators.pattern(email())]]
    })
  }

  search(text: string) {
    this.searchText = text;
    this.getContactUniverse();
  }

  getFilterContact() {
    if (this.searchText) {
      const isNumber = !isNaN(this.searchText as any);
      return {
        conDocNum: isNumber ? this.searchText : null,
        contactName: !isNumber ? this.searchText : null
      }
    }
    return {
      conDocNum: null,
      contactName: null
    }
  }

  getContactUniverse() {
    const request = {
      typeUniverse: 0,
      numberDocument: this.documentNumber, //"20100002621"
      typeDocument: this.documentType,
      // numberDocument: "20100002621",
      // typeDocument: 2,
      ...this.getFilterContact()
    };
    this.loading = true;
    this.contactsFacadeService.getContactUniverse(request)
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.contactList = response;
          this.createContactForm();
        },
        error: () => this.loading = false
      })
  }

  refresh() {
    this.getContactUniverse(); 
    this.writingMode = false;
    this.onRefresh.emit();
  }

  addContact() {
    if (!this.writingMode) {
      const contact = { lastUpdate: this.datePipe.transform(new Date(), 'dd-MM-yyyy')} as CustomerContactUniverseDTO;
      this.writingMode = true;
      const form = this.createContactItemForm(true, contact);
      this.contactForm.insert(0, form);
    }
  }

  remove(index: number) {
    this.contactForm.removeAt(index);
    this.writingMode = false;
  }

  cancelEdit(contactId: number) {
    const index = this.contactList.findIndex(x => x.idContact == contactId);
    if (index > -1) {
      this.writingMode = false;
      const contact = this.contactList[index];
      const form = this.createContactItemForm(false, contact);
      this.contactForm.setControl(index, form);
    }
  }

  closeContacts() {
    this.onClose.emit();
  }


}
