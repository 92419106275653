import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { attributesList } from "./attributes.actions";
import { selectAttributes, selectCustomerAttributes, selectProducts, selectStateInstance } from "./attributes.selectors";

@Injectable()
export class AttributesFacade {

    constructor(private readonly store: Store) {}
    
    getDetailCatalog() {
        this.store.dispatch(attributesList());
    }

    selectAttributes() {
        return this.store.select(selectAttributes);
    }

    selectCustomerAttributes() {
        return this.store.select(selectCustomerAttributes);
    }

    selectStateInstance() {
        return this.store.select(selectStateInstance);
    }

    selectProductsCampaign() {
        return this.store.select(selectProducts);
    }

}