<div class="page-error">
    <div class="page-error__info">
        <img src="/assets/img/logo-vpCloud.svg" alt="logo"/>
        <h1 class="margin-0 f-size-28 f-weight-500 margin-top-30">Hola,</h1>
        <h1 class="margin-0 f-size-20 f-weight-500">
            {{this.lastnameUser | uppercase}},<br>{{this.nameUser}}
        </h1>
        <div class="margin-top-40">
            <p class="margin-0 f-size-16 f-weight-600 color-red margin-bottom-4">
                ¡No cuentas con acceso a los módulos!
            </p>
            <small class="f-size-16 f-weight-500 color-dark-liver">
                Solicítalo al siguiente correo:
            </small>
            <p class="margin-0 mail-hover margin-top-4">gestioncomercial@intercorp.com.pe</p>
        </div>
    </div>
    <div class="page-error__image">
        <img src="/assets/img/dashboard/no-access.svg" alt="img"/>
    </div>
</div>