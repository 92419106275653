import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  destroy$: Subject<any> = new Subject();

  /**
   * Handles the destroy event.
   */
  protected destroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnDestroy() {
    this.destroy();
  }

}
