import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { qualitativeValidationStep, qualitativeValidationStepFailed, qualitativeValidationStepSuccess } from "./qualitative-new.actions";
import { QualitativeService } from 'src/app/services/qualitative.service';

@Injectable()
export class QualitativeNewEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly qualitativeService: QualitativeService
    ) { }

    getQualitativeValidationStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(qualitativeValidationStep),
            mergeMap((action) => {
                return this.qualitativeService.getValidationStep(action.id)
                    .pipe(
                        map((data) => {
                            return qualitativeValidationStepSuccess({ payload: data });
                        }),
                        catchError((error) => of(qualitativeValidationStepFailed(error)))
                    )
            })
        )
    )

}
