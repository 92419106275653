import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../../../services/message.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ContactsFacadeService } from '../../services/contacts-facade.service';
import { Router,ActivatedRoute } from '@angular/router';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';

@Component({
  selector: 'app-list-contact-dual-view',
  templateUrl: './list-contact-dual-view.component.html',
  styleUrls: ['./list-contact-dual-view.component.scss']
})
export class ListContactDualViewComponent implements OnInit {

  public contactSelect:any = {};
  public enterpriseSelect:any = {};
  //Filtros
  public terminoChildAux: string = '';
  public filtroContact: any =null;
  public enterpriseList: any[] = [];

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  constructor(private _Activatedroute:ActivatedRoute,
              private messageService: MessageService,
              public loaderService:LoaderService,
              private contactService: ContactsFacadeService,
              private router: Router,
            private readonly localStorageService: LocalStorageService){



  }

  ngOnInit(): void {
    this.getContactsByEnterprises();
  }

  validateFilters(termino: string){
    this.filtroContact = termino;
  }

  searchContactParent(termino: string) {
    //validaciones
    if(termino.length == 2 || termino.length == 1){
      this.messageService.showWarning("No se puede filtrar con menos de 3 digitos", 'top right');
      return;
    }
    //Validar filtros
    this.validateFilters(termino);
    //Listar Contactos
    this.getContactsByEnterprises();

  }

  goToListContacts(){
    let url= '/dashboard/contacts/contact-list'
    this.router.navigateByUrl(url)
  }

  public getContactsByEnterprises(){
    let promise = new Promise((resolve, reject) => {
      let body = {
        "search": this.filtroContact,
        "userRegistration": this.user
      }


      this.contactService.getContactsByEnterprises(body).subscribe(
        enterpriseList => {
          if(enterpriseList.mensaje!=null){
            this.messageService.showWarning(enterpriseList.mensaje, 'top right');
            return;
          }
          this.enterpriseList=enterpriseList;
          console.log(this.enterpriseList)
          resolve(1);
        },
        error => {
          if(error.error.mensaje){
            this.messageService.showError(error.error.mensaje, 'top right');
          }
          else{
            this.messageService.showError(error.statusText, 'top right');
          }

        }
      )
    });
    return promise;
  }

  selectEnterprise(enterprise:any){
    let body = {
        "documentNumber": "20100188202",
        "type": 2
    }
    this.contactSelect = {};
    this.enterpriseSelect = enterprise;
    this.contactService.getListContactsByEnterprises(body).subscribe(
      contactList => {
        this.enterpriseSelect.lisContacts = contactList;
        if(contactList.length>0){
          let i = 0;

          this.enterpriseSelect.listContactsView = [];

          this.enterpriseSelect.lisContacts.forEach((contact:any) => {
            if(i<12){
              this.enterpriseSelect.listContactsView.push(contact);
            }
            i++;
          });

          let url= '/dashboard/contacts/contact-detail/'+null+'/'+enterprise.companyName;
          this.router.navigateByUrl(url);

        }
      }
    )
    console.log(this.enterpriseSelect);
  }

}
