import { createReducer, on } from "@ngrx/store";
import { ClientListStateModel } from "./client-list.model";
import { clientList, clientSuccess, clientFailed, clientReset } from './client-list.actions';

export const initialState: ClientListStateModel = {
    data: null,
    loading: false,
    failed: false,
    loaded: false,
    error: null
};

const _clientListReducer = createReducer(initialState,
    on(clientList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(clientFailed, (state, payload) => {
        return {
            ...state,
            data: null,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(clientSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(clientReset, state => state = initialState),
    
);

export function clientListReducer(state: any, action: any): ClientListStateModel {
    return _clientListReducer(state, action);
}
