import { createAction, props } from "@ngrx/store";
import { HierarchyRequestDTO } from "src/app/models";
import { HierarchyModel } from "src/app/models/hierarchy.model";

export const hierarchyList = createAction('[Hierarchy] List',
    props<{request: HierarchyRequestDTO}>()
);

export const hierarchyFailed = createAction('[Hierarchy] Failed',
    props<{payload: any}>()
);
export const hierarchySuccess = createAction('[Hierarchy] Success',
    props<{payload: HierarchyModel}>()
);

export const hierarchyReset = createAction('[Hierarchy] Reset');