

<div fxLayout="column">
    <mat-card class="ibk-card">
        <div class="header">
            <div class="contacts">
                <div class="contactsTitle">
                    <h3>Lista de Contactos</h3>
                    <mat-icon class="green">account_box</mat-icon>
                </div>
            </div>
        </div>
        <div class="sub-header">
            <div class="ibk-states" fxLayout="row" fxLayout fxLayoutAlign="flex-start" fxLayoutGap="0px" fxLayoutGap.xs="0">
                <ng-container *hasPermission>
                    <div *ngIf="newEvent!=2" class="circle">
                        <mat-icon>add</mat-icon>
                    </div>
                    <button *ngIf="newEvent!=2" mat-stroked-button class="border-button button-add-contact">Añadir Contacto</button> 
                </ng-container>
            </div>
        </div>

        <div fxLayout="row">
            <div fxFlex="35%" >
                <mat-card class="mat-card-view-double mat-card-center">
                    <div fxLayout="column" fxFlex="80%" >
                        <div class="div-contact-list" *ngFor="let contact of enterpriseSelect.listContactsView">
                            <mat-card [ngClass]="contactSelect.idContact == contact.idContact ? 'mat-card-contact mat-card-contact-select' : 'mat-card-contact'"
                            (click)="selectContact(contact)">
                                <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100%">
                                    <div>
                                        {{contact.contactName}} {{contact.contactFatherLastName}} {{contact.contactMotherLastName}}
                                        <br><div *ngIf="contact.position" class="letter-possition-contact">({{contact.position}})</div>
                                    </div>
                                    <div class="mat-icon-delete">
                                        <button [disabled]="newEvent==2" mat-icon-button *hasPermission>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                    
                                </div>
                            </mat-card>
                        </div>
                        <button mat-button class="button-ver-mas" 
                        *ngIf="enterpriseSelect.listContactsView?.length!=enterpriseSelect.lisContacts?.length"
                        (click)="verMasContacts()">Ver más</button>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="65%" *ngIf="contactSelect.contactName">
                <mat-card class="mat-card-view-double">
                    <div fxLayout="row" fxLayoutAlign="space-between" class="mat-card-detail">
                        <div class="detail-contact-subtittle">
                            <h3>Información General</h3>
                            <mat-icon class="green">circle</mat-icon>
                        </div>
                        <div>
                            <button mat-stroked-button class="border-button" (click)="editar()" *hasPermission>Editar</button>
                            <button mat-stroked-button class="border-button" *hasPermission>Guardar</button>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Nombre:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.contactName}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Apellido Paterno:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.contactFatherLastName}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Apellido Materno:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.contactMotherLastName}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Documento de Identidad:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.contactDocument}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Cargo:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.position}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Roles:</b></mat-label>
                        </div>
                    </div>
                    <div fxLayout="row" class="label-detail-contact">
                        <div class="border-roles-center" *ngFor="let role of contactSelect.productRoleList">
                            <div class="border-roles">
                                <mat-label><b>{{role}}</b></mat-label>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Celular Principal:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.phoneOne}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact" *ngIf="contactSelect.phoneTwo">
                        <div fxFlex="20%">
                            <mat-label><b>Celular N°2:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.phoneTwo}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact" *ngIf="contactSelect.phoneThree">
                        <div fxFlex="20%">
                            <mat-label><b>Celular N°3:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <mat-label>{{contactSelect.phoneThree}}</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact">
                        <div fxFlex="20%">
                            <mat-label><b>Correo:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <div class="mat-label-blue">
                                <mat-label>{{contactSelect.emailOne}}</mat-label>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="label-detail-contact" *ngIf="contactSelect.emailTwo">
                        <div fxFlex="20%">
                            <mat-label><b>Correo N°2:</b></mat-label>
                        </div>
                        <div fxFlex="75%">
                            <div class="mat-label-blue">
                                <mat-label>{{contactSelect.emailTwo}}</mat-label>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let contactAddress of contactSelect.contactAddress; let i = index">
                        <div fxLayout="row" class="mat-card-detail">
                            <div class="detail-contact-subtittle">
                                <h3>Dirección de Contacto {{i+1}}</h3>
                                <mat-icon class="green">circle</mat-icon>
                            </div>
                        </div>
                        
                        <div fxLayout="row" class="label-detail-contact">
                            <div fxFlex="20%">
                                <mat-label><b>Departamento:</b></mat-label>
                            </div>
                            <div fxFlex="75%">
                                <mat-label>{{contactAddress.department}}</mat-label>
                            </div>
                        </div>
    
                        <div fxLayout="row" class="label-detail-contact">
                            <div fxFlex="20%">
                                <mat-label><b>Provincia:</b></mat-label>
                            </div>
                            <div fxFlex="75%">
                                <mat-label>{{contactAddress.province}}</mat-label>
                            </div>
                        </div>
    
                        <div fxLayout="row" class="label-detail-contact">
                            <div fxFlex="20%">
                                <mat-label><b>Distrito:</b></mat-label>
                            </div>
                            <div fxFlex="75%">
                                <mat-label>{{contactAddress.district}}</mat-label>
                            </div>
                        </div>
    
                        <div fxLayout="row" class="label-detail-contact">
                            <div fxFlex="20%">
                                <mat-label><b>Dirección:</b></mat-label>
                            </div>
                            <div fxFlex="75%">
                                <mat-label>{{contactAddress.address}}</mat-label>
                            </div>
                        </div>
    
                        <div fxLayout="row" class="label-detail-contact">
                            <div fxFlex="20%">
                                <mat-label><b>Referencia:</b></mat-label>
                            </div>
                            <div fxFlex="75%">
                                <mat-label>{{contactAddress.reference}}</mat-label>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
          </div>
    </mat-card>
</div>