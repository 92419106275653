import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { fallsJefeList, fallsJefeReset } from "./falls-jefe.actions";
import { selectFallsJefeList, selectFallsJefeLoading } from "./falls-jefe.selectors";

@Injectable()
export class FallsJefeFacade {

    constructor(private readonly store: Store) {}
    
    getFallsBoss(request: any) {
        this.store.dispatch(fallsJefeList({ payload: request }));
    }

    reset() {
        this.store.dispatch(fallsJefeReset());
    }

    selectFallsJefe() {
        return this.store.select(selectFallsJefeList);
    }

    selectFallsJefeLoading() {
        return this.store.select(selectFallsJefeLoading);
    }

}