import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { MessageService } from '../services/message.service';
import  mockConfig  from '../mock/mock.config';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  public totalRequests = 0;
  public currentMockEndpoint: any;

  constructor(private messageService: MessageService, public loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*this.currentMockEndpoint = mockConfig[req.method] && mockConfig[req.method][req.url] || null;
    return this.currentMockEndpoint ? this.currentMockEndpoint.handler() : next.handle(req);*/
    this.totalRequests++;
    this.loaderService.isLoading.next(true);
    return next.handle(req).pipe(
      finalize(
        () =>{
          this.totalRequests--;
          if (this.totalRequests === 0) {
            this.loaderService.isLoading.next(false)

          }
        }
      ),
      catchError((error) => this.herrorHandler(error))
    )
  }

  herrorHandler(error: HttpErrorResponse): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.log('ERROR DE CLIENTE', error);
        this.messageService.showError(error.error.message, 'top right');
      } else {
        this.messageService.showError('ERROR DE SERVIDOR', 'top right');
        if (error?.status == 0) {
          this.messageService.showError('Servidor Detenido', 'top right');
        } else {
          const msg = error?.error?.mensaje || 'ERROR DE SERVIDOR';
          this.messageService.showError(msg, 'top right');
        }
      }
    } else {
      console.warn(error)
      this.messageService.showError('OTRO TIPO DE ERROR', 'top right');
    }
    return throwError(error);
  }

}
