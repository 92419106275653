import { Injectable } from '@angular/core';
import { FamilyClientFacade } from 'src/app/store/client/state/familyClient/family-client-list.facade';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/core/base/base.component';

@Injectable({
  providedIn: 'root'
})
export class OperationVariablesService extends BaseComponent{

  variables$ = this.familyClientFacade.selectFamilyClientData();
  variableFamilyList: any[];
  variableFamilyListWithData: any[];

  someNotSelectedVariable: boolean;
  allVariablesNotSelected: boolean;

  constructor(private familyClientFacade: FamilyClientFacade) {
    super();
  }

  listenClientState() {
    this.variables$.pipe(takeUntil(this.destroy$)).subscribe(familyClientDTO => {
      if(familyClientDTO.data != null) {
        const { loading, data } = familyClientDTO;
        this.variableFamilyList = familyClientDTO.data.variables.map((item: any) =>
          Object.assign({}, item, {selected:false}))

        this.variableFamilyListWithData = this.variableFamilyList.filter(element =>
          element.variableDTOList && element.variableDTOList.length > 0).map(element => ({...element, selected: true}));
      }
    })
  }

  listenClientStateChecked(checkedFilters: any) {
    this.variables$.pipe(takeUntil(this.destroy$)).subscribe(familyClientDTO => {
      if(familyClientDTO.data != null) {
        const { loading, data } = familyClientDTO;
        this.variableFamilyList = familyClientDTO.data.variables.map((item: any) =>
          Object.assign({}, item, {selected:false}))

        this.variableFamilyListWithData = this.variableFamilyList.filter(element =>
          element.variableDTOList && element.variableDTOList.length > 0).map(element => {
            const isMatchVariable = checkedFilters.some((variableFilter: any) =>
              variableFilter.idClientFamily === element.idClientFamily // Use strict comparison (===)
            );
            return { ...element, selected: isMatchVariable };
          });
        this.someNotSelectedVariable = true;
      }
    })
  }

}
