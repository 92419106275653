import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { ContactsService } from 'src/app/services/contacts.service';

@Injectable()
export class ContactsFacadeService {

  selectedHierarchyUser: string | null;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly contactsService: ContactsService
  ) { }

  get user(): string {
    return this.localStorageService.getItem<string>(USER_INFO) ?? '';
  }

  addAndRemovePriority(favorite: boolean, contactId: number) {
    return this.contactsService.favoriteContact({ favorite, idContact: contactId });
  }

  deleteContact(body: any) {
    return this.contactsService.deleteContact(body);
  };

  saveContact(body: any) {
    return this.contactsService.saveContact(body);
  }

  getContact(body: any) {
    return this.contactsService.getContact(body);
  };

  getCustomerWithUniqueCode(body: any) {
    return this.contactsService.getCustomerWithUniqueCode(body);
  };

  getContactsByEnterprises(body: any) {
    return this.contactsService.getContactsByEnterprises(body);
  };


  getListContactsByEnterprises(body: any){
    return this.contactsService.getListContactsByEnterprises(body);
  }

  getContactTypeUniverse(body: any){
    return this.contactsService.getContactTypeUniverse(body);
  }
};
