import { createAction, props } from "@ngrx/store";

export const rankingList = createAction('[Ranking] List',
    props<{payload: any}>()
);
export const rankingFailed = createAction('[Ranking] Failed',
    props<{payload: any}>()
);
export const rankingSuccess = createAction('[Ranking] Success',
    props<{payload: any}>()
);

export const rankingReset = createAction(
    '[Ranking] Reset'
);

