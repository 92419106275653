import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input() text: any;
  @Input() width: string = '147px';
  @Input() left: string = '0';
  @Input() top: string = '20px';

  @Input() type: any = 'normal';
  @Input() position:string = 'above';
  @Input() invalid:boolean = true;
  @Input() hideDelay:number = 1000;


  constructor() { }

  ngOnInit(): void {
    const mensaje = document.querySelector('span');
    if(mensaje){
      mensaje.dataset['texto'] = this.text;
    }

   /*if(this.invalid){
      this.mostrarMensajeError();
    }*/

  }

  /*mostrarMensajeError() {
    debugger;
    console.log("entro al mostrar")
    const mensajeError = document.getElementById('mensajeError');
    if(mensajeError){
      mensajeError.classList.add('mostrar');
    }

    setTimeout(() => {
      this.ocultarMensajeError();
    }, 2000);
  }

  ocultarMensajeError() {
    debugger;
    console.log("ocultar")
    const mensajeError = document.querySelector('mensajeError');
    if(mensajeError){
      mensajeError.classList.add('hide');
    }

  }*/


}
