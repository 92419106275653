import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TooltipUiComponent } from '../tooltip-ui/tooltip-ui.component';

@Component({
  selector: 'app-tooltip-text',
  templateUrl: './tooltip-text.component.html',
  styleUrls: ['./tooltip-text.component.scss']
})
export class TooltipTextComponent implements OnInit, AfterViewInit {

  @ViewChild("tooltip_text") tooltip_text: TooltipUiComponent;
  @ViewChild("textDiv") textDiv: ElementRef;

  @Input() color: string;
  @Input() width: string = '148px';
  @Input() right: string = 'calc(50% - 74px)';
  @Input() left: string = 'auto';
  @Input() top: string = '-43px';
  @Input() bgColor: string = '#B7BABC';
  @Input() timeout: number = 0;
  @Input() open: boolean;
  @Input() description: string;
  @Input() lines: number = 2;
  @Input() buttonText: string = '';
  @Output() clickButton = new EventEmitter();
  
  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  showTooltip() {
    if (this.isClamped()) {
      this.tooltip_text.show();
    }
  }

  isClamped() {
    const div = this.textDiv?.nativeElement;
    if (!div) return false;

    // const lineHeight = parseInt(window.getComputedStyle(div).lineHeight, 10);
    // const lines = Math.ceil(div.scrollHeight / lineHeight);
    return this.lines > 1 ? div.scrollHeight > div.clientHeight : div.scrollWidth > div.clientWidth;
  }

}
