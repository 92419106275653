import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { FinancialEeffStateModel } from './financial-eeff/financial-eeff.model';
import { financialEEFFReducer } from './financial-eeff/financial-eeff.reducer';
import { FinancialEeffEffects } from './financial-eeff/financial-eeff.effects';

export const FINANCIAL_FEATURE_KEY = 'financial';

export const selectFinancialState = createFeatureSelector<FinancialState>(
  FINANCIAL_FEATURE_KEY
);

export const FinancialEffects: any[] = [
  FinancialEeffEffects
];

export interface FinancialState {
  financialEeff: FinancialEeffStateModel;
}

export const FinancialReducerModule: ActionReducerMap<FinancialState> = {
  financialEeff: financialEEFFReducer
};
