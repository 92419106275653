import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Validates if it contains values ​​when the attribute type is a checkBox or dropDown
 * @param control the `FromControl` in this case.
 */
export const inputValuesValidator = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const form = (control.parent as FormGroup);
  const attributeType = form ? form.get('attributeType') as FormControl : null;
  const inputValues = control.value;
  if (attributeType && (attributeType.value == 8 || attributeType.value == 10)
    && (!inputValues || inputValues.length == 0)) {
    return { noValues: true };
  } else {
    return null;
  }
};

export const valueCero = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const exist = control.value == 0;
  if (exist) {
    return { value: true };
  } else {
    return null;
  }
};


