import { createReducer, on} from "@ngrx/store";
import { rankingList, rankingFailed, rankingSuccess, rankingReset } from "./ranking.actions";
import { RankingStateModel } from "./ranking.model";

export const initialState: RankingStateModel = {
    data: {},
    loading: false,
    failed: false
};

const _rankingReducer = createReducer(initialState,
    on(rankingList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(rankingFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(rankingSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false
        }
    }),
    on(rankingReset, state => state = initialState)
);

export function rankingReducer(state: any, action: any): RankingStateModel {
    return _rankingReducer(state, action);
}
