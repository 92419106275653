import { createAction, props } from "@ngrx/store";

export const valueOfferList = createAction('[Value Offer] List',
    props<{id: number}>()
);

export const valueOfferFailed = createAction('[Value Offer] Failed',
    props<{payload: any}>()
);
export const valueOfferSuccess = createAction('[Value Offer] Success',
    props<{payload: any}>()
);

export const valueOfferReset = createAction('[Value Offer] Reset');
