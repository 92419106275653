import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { materialModule } from 'src/material';
import { HttpClientModule } from '@angular/common/http';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ClientFilterComponent } from './client-filter/client-filter.component';
import { AddOperationComponent } from './add-operation/add-operation.component';
import { SearchComponent } from './search/search.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { OperationVariablesComponent } from './operation-variables/operation-variables.component';
import { OperationClientFilterComponent } from './operation-client-filter/operation-client-filter.component';
import { SpeechToTextComponent } from './speech-to-text/speech-to-text.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';

@NgModule({
  declarations: [
    ClientFilterComponent,
    AddOperationComponent,
    SearchComponent,
    AutocompleteComponent,
    OperationVariablesComponent,
    OperationClientFilterComponent,
    SpeechToTextComponent,
    HierarchyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    materialModule,
    HttpClientModule,
    UiModule
  ],
  exports: [
    ClientFilterComponent,
    AddOperationComponent,
    SearchComponent,
    AutocompleteComponent,
    OperationVariablesComponent,
    OperationClientFilterComponent,
    SpeechToTextComponent,
    HierarchyComponent
  ]
})
export class ComponentsModule { }
