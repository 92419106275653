import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CampaignSettingsService } from 'src/app/services/campaign-settings.service';
import { configurationsList, configurationsSuccess, configurationsFailed,
    configurationsElementList, configurationsElementFailed, configurationsElementSuccess } from './configuration.actions';

@Injectable()
export class CampaignConfigurationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly campaignSettingsService: CampaignSettingsService
    ) { }

    getConfigurations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(configurationsList),
            mergeMap((action) =>
                this.campaignSettingsService.searchCampaignAttribute(action.id, (action.typeElement || 0))
                    .pipe(
                        map((data) => {
                            return configurationsSuccess({ payload: data });
                        }),
                        catchError((error) => of(configurationsFailed(error)))
                    )
            )
        )
    )

    getConfigurationsElement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(configurationsElementList),
            mergeMap((action) =>
                this.campaignSettingsService.searchCampaignElement(action.id, (action.typeElement || 0))
                    .pipe(
                        map((data) => {
                            return configurationsElementSuccess({ payload: data });
                        }),
                        catchError((error) => of(configurationsElementFailed(error)))
                    )
            )
        )
    )

}
