import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { InstructiveEffects } from './state/instructive/instructive.effects';
import { InstructiveStateModel } from './state/instructive/instructive.model';
import { instructiveReducer } from './state/instructive/instructive.reducer';
import { InstructiveProductsEffects } from './state/products/instructive-products.effects';
import { InstructiveProductsStateModel } from './state/products/instructive-products.model';
import { instructiveProductsReducer } from './state/products/instructive-products.reducer';

export const INSTRUCTIVE_FEATURE_KEY = 'instructive';

export const selectInstructiveState = createFeatureSelector<InstructiveState>(
  INSTRUCTIVE_FEATURE_KEY
);

export const InstructiveListEffects: any[] = [
  InstructiveProductsEffects,
  InstructiveEffects
];

export interface InstructiveState {
  products: InstructiveProductsStateModel;
  instructive: InstructiveStateModel
}

export const InstructiveReducerModule: ActionReducerMap<InstructiveState> = {
  products: instructiveProductsReducer,
  instructive: instructiveReducer
};
