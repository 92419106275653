import { createSelector } from "@ngrx/store";
import { CampaignState, selectCampaignState } from "../../campaign.store";

export const selectAttributes = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.attributes 
);

export const selectCustomerAttributes = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.attributes.client
);

export const selectStateInstance = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.attributes.stateInstance
);

export const selectProducts = createSelector(
    selectCampaignState,
    (state: CampaignState) => state.attributes.products
);
