import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EscomHistoryEffects } from './state/escom-history/escom-history.effects';
import { HistoryStateModel } from './state/escom-history/escom-history.model';
import { escomHistoryReducer } from './state/escom-history/escom-history.reducer';
import { EscomEffects } from './state/escom-list/escom.effects';
import { EscomStateModel } from './state/escom-list/escom.model';
import { escomReducer } from './state/escom-list/escom.reducer';
import { EscomInformationTypeEffects } from './state/escom-type-information/escom-information-type.effects';
import { InformationTypeStateModel } from './state/escom-type-information/escom-information-type.model';
import { informationTypeReducer } from './state/escom-type-information/escom-information-type.reducer';

export const ESCOM_FEATURE_KEY = 'escom';

export const selectEscomState = createFeatureSelector<EscomState>(
    ESCOM_FEATURE_KEY
);

export const EscomListEffects: any[] = [
  EscomEffects,
  EscomHistoryEffects,
  EscomInformationTypeEffects
];

export interface EscomState {
  escom: EscomStateModel,
  history: HistoryStateModel;
  informationType: InformationTypeStateModel;
}

export const EscomReducerModule: ActionReducerMap<EscomState> = {
  escom: escomReducer,
  history: escomHistoryReducer,
  informationType: informationTypeReducer
};
