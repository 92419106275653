import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DocumentsTypeDTO } from 'src/app/pages/escom-list/models';
import { EscomService } from 'src/app/services/escom.service';
import { documentsTypeList, documentsTypeFailed, documentsTypeSuccess, subDocumentsTypeList, subDocumentsTypeSuccess, subDocumentsTypeFailed } from './escom.actions';

@Injectable()
export class EscomEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly escomService: EscomService
    ) { }

    getDocumentsType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentsTypeList),
            mergeMap((action) => {
                return this.escomService.getDocumentsType(action.request)
                    .pipe(
                        map((data) => {
                            const result = data?.map(item => {
                                return {
                                    ...item,
                                    idTableRef: +action.request.idTableRef,
                                    idItemRef: +action.request.idItemRef
                                } as DocumentsTypeDTO;
                            }) || [];
                            return documentsTypeSuccess({ payload: result, idTableRef: action.request.idTableRef, idItemRef: action.request.idItemRef });
                        }),
                        catchError((error) => of(documentsTypeFailed(error)))
                    )
            })
        )
    )

    getSubDocumentsType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(subDocumentsTypeList),
            mergeMap((action) => {
                return this.escomService.getDocumentsType(action.request)
                    .pipe(
                        map((data) => {
                            return subDocumentsTypeSuccess({idTableFatherRef: action.request.idTableRef, idItemFatherRef: action.request.idItemRef, payload: data });
                        }),
                        catchError((error) => of(subDocumentsTypeFailed(error)))
                    )
            })
        )
    )

}
