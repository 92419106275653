import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../../../../services/message.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-list-contact-double',
  templateUrl: './list-contact-double.component.html',
  styleUrls: ['./list-contact-double.component.scss']
})
export class ListContactDoubleComponent implements OnInit {

  public newEvent = 0;
  @Input() enterpriseSelect: any;
  @Input() contactSelect: any;


  constructor(private messageService: MessageService,
              public loaderService:LoaderService){ 
      

  }

  ngOnInit(): void {
  }

  editar(){
    console.log(this.enterpriseSelect)
    console.log(this.contactSelect)
  }

  selectContact(contact:any){
    this.contactSelect = contact;
    if(this.contactSelect.productRole!=null && this.contactSelect.productRole!=""){
      this.contactSelect.productRoleList = this.contactSelect.productRole.split(',');
    }
    console.log(contact);
  }

  verMasContacts(){
    this.enterpriseSelect.listContactsView=this.enterpriseSelect.lisContacts;
  }


}