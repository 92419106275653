import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OperationGetRequest } from '../pages/enote-config/pages/enote/models';
import { ContentType, HttpClientService } from '../shared/core/services/http-client.service';
import { AuthService } from './auth.service';

@Injectable()
export class OperationService {

  // private urlReporting = `http://localhost:8082${environment.reporting_path}`;
  private urlReporting = `${environment.base_url}${environment.reporting_path}`;
  private urlContact = `${environment.base_url}${environment.contact_path}`;

  constructor(
      // private readonly http: HttpClient,
      private readonly http: HttpClientService,
      private readonly authService: AuthService) {

  }

  getResume(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/generate-resume`;
    return this.http.post<any>(path, request, {headers});
  }

  getResumeJefe(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/generate-resume`;
    return this.http.post<any>(path, request, {headers});
  }

  // getOperations(operationType: string, products: number[], uniqueCode: string, businessName: string, ruc: string, user?: string) {
  getOperations(request: OperationGetRequest) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    // const request = {
    //   operation: operationType.toLocaleLowerCase() == 'pipeline' ? 1 : 2,
    //   date: fromDateToText(new Date(), '/')?.substring(0, 10),
    //   productos: products,
    //   user: user != undefined ? user : this.authService.user,
    //   uniqueCode: uniqueCode,
    //   businessName: businessName,
    //   document: ruc
    // };
    const path = `${this.urlReporting}/enote/list-operations`;
    return this.http.post<any>(path, request, {headers});
  }

  removeOperation(id: number, codeOperationType: number, userOperation: string) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const request = {
      codeOperation: id,
      codeOperationType,
      codeUser: userOperation,
      codeUserDelete: this.authService.user
    };
    const path = `${this.urlReporting}/enote/delete-operation`;
    return this.http.post<any>(path, request, {headers});
  }

  registerOperations(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/register-or-update`;
    return this.http.post<any>(path, request, {headers});
  }

  registerAmount(request: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/deposit/accurate/closing-balance-projection/save`;
    return this.http.post<any>(path, request, {headers});
  }

  getCustomer(cu: string) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const request = {
      uniqueCode: cu
    }
    const path = `${this.urlContact}/customer/search-customer`;
    return this.http.post<any>(path, request, {headers});
  }

  getNew(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const url = `${this.urlReporting}/enote/boss/list-pipeline`;
    return this.http.post<any>(url, request, {headers});
  }

  getFalls(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const url = `${this.urlReporting}/enote/boss/list-fall`;
    return this.http.post<any>(url, request, {headers});
  }

  getLosses(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };const url = `${this.urlReporting}/enote/boss/list-pipeline`;
    return this.http.post<any>(url, request, {headers});
  }

  getRanking(request: any) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/boss/v1/ranking/executive`;
    return this.http.post<any>(path, request, {headers});
  }

  downloadJefe(body: any, isFall: boolean){
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const text = isFall ? 'fall' : 'pipeline';
    const path = `${this.urlReporting}/enote/boss/download-${text}`;
    return this.http.post<any>(path, body, { headers: headers, responseType: 'blob'})
  }

  downloadExecutive(body: any){
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/download-excel-operations`;
    return this.http.post<any>(path, body, {headers: headers,responseType: 'blob'})
  }

  getExchangeRate(date: string) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/exchange-rate/get-exchange-rate?exchangeDate=${date}`
    return this.http.get<any>(path, { headers });
  }

  getBalance(nameProcess: string) {
    const headers = {
      'Content-Type': this.http.getContentType(ContentType.JSON),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/enote/date-process?nameProcess=${nameProcess}`;
    return this.http.get<{ name_date_process: string, date_process: string, date_process_format: string }>(path, { headers });
  }

}
