import { createReducer, on } from "@ngrx/store";
import { ProductsWithFileStateModel } from "./products-file.model";
import { productsWithFileSuccess, productsWithFileList, productsWithFileFailed, productsWithFileReset } from './products-file.actions';

export const initialState: ProductsWithFileStateModel = {
    data: null,
    loading: false,
    failed: false,
    error: null
};

const _productswithFileReducer = createReducer(initialState,
    on(productsWithFileList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(productsWithFileFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(productsWithFileSuccess, (state, {payload}) => {
        return {
            ...state,
            data: [...payload],
            loading: false,
            failed: false
        }
    }),
    on(productsWithFileReset, state => state = initialState)
);

export function productswithFileReducer(state: any, action: any): ProductsWithFileStateModel {
    return _productswithFileReducer(state, action);
}
