import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { valueOfferList, valueOfferReset } from './value-offer.actions';
import { selectValueOffer } from './value-offer.selectors';

@Injectable()
export class ValueOfferFacade {
  constructor(private readonly store: Store) {}

  getValueOffer(campaignId: number) {
    this.store.dispatch(valueOfferList({ id: campaignId }));
  }

  resetValueOffer() {
    this.store.dispatch(valueOfferReset());
  }

  selectValueOffer() {
    return this.store.select(selectValueOffer);
  }

}
