import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @ViewChild('search_input') search_input: ElementRef;
  @Output() search = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() writing = new EventEmitter<string>();
  @Input() placeholder: string = 'Busca aquí por Empresa, CU o producto...';
  @Input() outline: boolean = false;
  @Input() autocomplete: boolean = false;
  @Input() timeAutocomplete: number = 3000;
  @Input() error: boolean;

  private _disabled: boolean;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.text.disable();
    } else {
      this.text.enable();
    }
  }
  get disabled() {
    return this._disabled || false;
  }

  text = new FormControl('');
  timeout: any;

  constructor() {}

  ok() {
    this.resetTimeOut();
    this.search.emit(this.text.value);
  }

  clear() {
    this.text.setValue('');
    this.search.emit('');
  }

  clearFocus() {
    this.search_input.nativeElement.blur();
  }

  keyup(event: any) {
    const code = event.keyCode;
    if ((code == 8 || (code >= 48 && code <= 57) || (code >= 65 && code <= 90)) && this.autocomplete) {
      this.writing.emit(this.text.value);
      this.resetTimeOut();
      this.timeout = setTimeout(() => this.ok(), this.timeAutocomplete);
    }
  }

  resetTimeOut() {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
  }

}
