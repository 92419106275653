import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {

  @ViewChild('scrollTable') scrollTable: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (this.hasScroll) {
      let docViewTop = this.scrollTable.nativeElement.scrollTop;
      let docViewBottom = docViewTop + this.scrollTable.nativeElement.clientHeight;
      if(docViewBottom + 10 > this.scrollTable.nativeElement.scrollHeight 
        && !this.loading && this.currentPage != this.totalPages) {
        this.onScrolling.emit();
      }
    }
  }
  
  @Input() headers: any = [];
  @Input() data: any = [];
  @Input() headerTemplate: any;
  @Input() bodyTemplate: any;
  @Input() tableHeight: string = 'auto';
  @Input() minWidth: string = '1100px';

  @Input() hasScroll: boolean = false;
  @Input() loading: boolean;
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Output() onScrolling = new EventEmitter();
  @Input() descriptionEmpty: string = 'No se encontraron registros con los filtros ingresados';
  @Input() heightEmpty: number = 400;
  @Input() showEmpty: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  getName(row: any, header: any) {
    if (!header.name) return { column: '', value: '' };
    const columns = header.name?.split(';') || [];

    return columns.length > 1 ?
      {column: header.name, value: this.getData(row, columns)} : 
      {column: header.name, value: this.getValueDefault(row, header.name)};
  }

  getData(row: any, columnNames: string[]) {
    return columnNames.map(column => {
      const arr = column?.split('.') || [];
      return arr.length > 1 ? this.getNestedData(row, arr) : row[column];
    });
  }

  getValueDefault(row: any, columnName: string) {
    const columns = columnName?.split('.') || [];
    return columns.length > 1 ? this.getNestedData(row, columns) : row[columnName];
  }

  getNestedData(row: any, columnNames: string[]) {
    for (const item of columnNames) {
      row = row[item]
    }
    return row;
  }

}
