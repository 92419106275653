import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ClientSettingsService } from 'src/app/services/client-settings.service';
import {
    hierarchyFailed,hierarchyList,hierarchySuccess
} from './hierarchy-list.actions';

@Injectable()
export class HierarchyListEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly clientSettingsService: ClientSettingsService
    ) { }

    getHierarchy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(hierarchyList),
            mergeMap((action) => {
                return this.clientSettingsService.getHierarchy(action.request)
                    .pipe(
                        map((data) => {
                            const response = data || {};
                            return hierarchySuccess({ payload: response });
                        }),
                        catchError((error) => of(hierarchyFailed(error)))
                    )
            })
        )
    )

}
