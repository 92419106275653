import { createReducer, on} from "@ngrx/store";
import { productsList, productsSuccess, productsFailed } from "./products.actions";
import { ProductsStateModel } from "./products.model";

export const initialState: ProductsStateModel = {
    data: [],
    loading: false,
    failed: false
};

const _productsReducer = createReducer(initialState,
    on(productsList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(productsFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(productsSuccess, (state, { payload }) => {
        return {
            ...state,
            data: [...payload],
            loading: false,
            failed: false
        }
    })
);

export function productsReducer(state: any, action: any): ProductsStateModel {
    return _productsReducer(state, action);
}
