<div class="client-filter" *ngIf="openFilter">
    <div class="client-filter__content">
        <div class="client-filter__header">
            <div class="info">
                <!--<img src="/assets/img/arrow-down.svg" alt="arrow">-->
                <h6 *ngIf="opcion == 0" class="title">Filtros</h6>
            </div>
            <!--<img src="/assets/img/close.svg" class="close" alt="close" (click)="close()">-->
          <mat-icon class="close" (click)="close()">close</mat-icon>
        </div>

      <div *ngIf="opcion == 0" class="client-filter__body" [formGroup]="filterForm">
        <!--<mat-expansion-panel>
          <mat-expansion-panel-header class="check" style="background: #F2F2F2; margin-top: 10px">
            <h4>Banca</h4>
          </mat-expansion-panel-header>
          <div *ngFor="let item of bancaList">
            <mat-checkbox color="primary"
                          (change)="selectBanca(item);"
                          (click)="$event.stopPropagation();"
                          (keydown)="$event.stopPropagation();">
              {{item.nameUser}}
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [disabled]="zonal">
          <mat-expansion-panel-header class="check" style="background: #F2F2F2; margin-top: 10px">
            <h4>Zonal</h4>
          </mat-expansion-panel-header>
          <div *ngFor="let item of zonalList">
            <mat-checkbox color="primary"
                          (change)="selectZonal(item);"
                          (click)="$event.stopPropagation();"
                          (keydown)="$event.stopPropagation();">
              {{item.nameUser}}
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [disabled]="jefatura">
          <mat-expansion-panel-header class="check" style="background: #F2F2F2; margin-top: 10px">
            <h4>Jefatura</h4>
          </mat-expansion-panel-header>
          <div *ngFor="let item of jefaturaList">
            <mat-checkbox color="primary"
                          (change)="selectJefatura(item);"
                          (click)="$event.stopPropagation();"
                          (keydown)="$event.stopPropagation();">
              {{item.nameUser}}
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [disabled]="ejecutivo">
          <mat-expansion-panel-header class="check" style="background: #F2F2F2; margin-top: 10px">
            <h4>Ejecutivo</h4>
          </mat-expansion-panel-header>
          <div *ngFor="let item of ejecutivoList">
            <mat-checkbox color="primary"
                          (change)="selectEjecutivo(item);"
                          (click)="$event.stopPropagation();"
                          (keydown)="$event.stopPropagation();">
              {{item.nameUser}}
            </mat-checkbox>
          </div>
        </mat-expansion-panel>-->
            <div class="control" *ngIf="hierarchySelected?.id<=1">
                <mat-form-field appearance="fill">
                  <mat-label>Banca</mat-label>
                    <mat-select placeholder="Banca" formControlName="banca"
                    (selectionChange)="selectBanca()">
                        <mat-option [value]="item" *ngFor="let item of bancaList">
                            {{item.nameUserFilter}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="control" *ngIf="hierarchySelected?.id<=2">
                <mat-form-field appearance="fill">
                  <mat-label>Zonal</mat-label>
                    <mat-select placeholder="Zonal" formControlName="zonal"
                    (selectionChange)="selectZonal()">
                        <mat-option [value]="item" *ngFor="let item of zonalList">
                            {{item.nameUserFilter}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="control" *ngIf="hierarchySelected?.id<=3">
                <mat-form-field appearance="fill">
                  <mat-label>Jefatura</mat-label>
                    <mat-select placeholder="Jefatura" formControlName="jefatura"
                    (selectionChange)="selectJefatura()">
                        <mat-option [value]="item" *ngFor="let item of jefaturaList">
                            {{item.nameUserFilter}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="control" *ngIf="hierarchySelected?.id<=4">
                <mat-form-field appearance="fill">
                  <mat-label>Ejecutivo</mat-label>
                    <mat-select placeholder="Ejecutivo" formControlName="ejecutivo"
                    (selectionChange)="selectEjecutivo()">
                        <mat-option [value]="item" *ngFor="let item of ejecutivoList">
                            {{item.nameUserFilter}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="control" *ngIf="hierarchySelected?.id == 5 && sectoristasList?.length > 0">
              <mat-form-field appearance="fill">
                <mat-label>Ejecutivo</mat-label>
                  <mat-select placeholder="Ejecutivo" formControlName="ejecutivo"
                  (selectionChange)="selectSectorista()">
                      <mat-option [value]="item" *ngFor="let item of sectoristasList">
                          {{item.nameUserFilter}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
      <div class="client-filter__buttons">
        <button mat-stroked-button style="margin-right: 2rem" class="ok" (click)="ok()">
          Guardar
        </button>
        <button mat-stroked-button class="cancel" (click)="cancel()">
          Limpiar
        </button>
      </div>
    </div>
</div>
