import { createReducer, on} from "@ngrx/store";
import {
    paramsDataList, paramsDataSuccess, paramsDataFailed}
from "./params.actions";
import { ParamsStateModel } from "./params.model";

export const initialState: ParamsStateModel = {
    data: [],
    loading: false,
    failed: false
};

const _paramsReducer = createReducer(initialState,
    on(paramsDataList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(paramsDataFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(paramsDataSuccess, (state, { payload }) => {
        const data = [...state.data.filter(item => item.idTabla != payload.tableId)];
        const result = [...data, ...payload.parameters];
        return {
            ...state,
            data: [...result],
            loading: false,
            failed: false
        }
    }),
    // on(paramsList, state => {
    //     return { 
    //         ...state,
    //         loading: true
    //     }
    // }),
    // on(paramsFailed, (state, { payload }) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         failed: true
    //     }
    // }),
    // on(paramsSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         types: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(inputAttributeSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         inputs: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(enoteTypesSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         enoteTypes: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(reasonSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         reason: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(ratesSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         rates: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(banksSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         banks: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(campaignTypesSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         campaignTypes: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(currencySuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         currency: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(productTypesSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         productTypes: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(campaignStateSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         campaginState: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // }),
    // on(campaignStateValidateSuccess, (state, { payload }) => {
    //     return {
    //         ...state,
    //         campaignStateValidate: [...payload],
    //         loading: false,
    //         failed: false
    //     }
    // })
);

export function paramsReducer(state: any, action: any): ParamsStateModel {
    return _paramsReducer(state, action);
}
