import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { campaignList, campaignReset } from './campaign-list.actions';
import { selectCampaignData } from './campaign-list.selectors';

@Injectable()
export class CampaignListFacade {
  constructor(private readonly store: Store) {}

  getCampaigns(request: any) {
    const { limit, name, offset, periodIn, periodFi, state, typeCampaign } = request;
    this.store.dispatch(
      campaignList({ limit, name, offset, periodIn, periodFi, state, typeCampaign})
    );
  }

  resetCampaigns() {
    this.store.dispatch(campaignReset());
  }

  selectCampaign() {
    return this.store.select(selectCampaignData);
  }
}
