import { createReducer, on} from "@ngrx/store";
import { instructiveList, instructiveSuccess, instructiveFailed, instructiveReset } from "./instructive.actions";
import { InstructiveStateModel } from "./instructive.model";

export const initialState: InstructiveStateModel = {
    data: [],
    loading: false,
    failed: false
};

const _instructiveReducer = createReducer(initialState,
    on(instructiveList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(instructiveFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(instructiveSuccess, (state, { payload }) => {
        return {
            ...state,
            data: [...payload],
            loading: false,
            failed: false
        }
    }),
    on(instructiveReset, state => state = initialState)
);

export function instructiveReducer(state: any, action: any): InstructiveStateModel {
    return _instructiveReducer(state, action);
}
