export interface Iuser {
  username: string;
  name: string;
  title: string;
  department: string;
  division: string;
  deliveryOfficeName: string;
  mobile: string;
  email: string;
  groups: string[];
  supervisor: string;
  supervisorUserName: string;
  applicationOrigin: string;
  authorities: any[];
  roleActionList: any[];
  moduleList: any[];
  role: Role;
}

interface Role {
  idRole: number;
  description: string;
  nameRole: string;
  networkGroup: string;
  flgState: number;
  priority?: any;
  registrationIdUser: string;
  registrationDate: string;
  lastUpdateIdUser: string;
  lastUpdateDate: string;
}