import { createAction, props } from "@ngrx/store";

export const productsWithFileList = createAction('[Products With File] List',
    props<{id: number}>()
);

export const productsWithFileFailed = createAction('[Products With File] Failed',
    props<{payload: any}>()
);
export const productsWithFileSuccess = createAction('[Products With File] Success',
    props<{payload: any}>()
);

export const productsWithFileReset = createAction('[Products With File] Reset');
