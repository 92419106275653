import { createReducer, on} from "@ngrx/store";
import { HistoryStateModel } from "./escom-history.model";
import { historyList, historyFailed, historySuccess, historyReset, historyDelete } from "./escom-history.actions";

export const initialState: HistoryStateModel = {
    data: {
        totalItems: 0,
        currentPage: 0,
        totalPages: 0,
        items: []
    },
    loading: false,
    failed: false
};

const _escomHistoryReducer = createReducer(initialState,
    on(historyList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(historyFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(historySuccess, (state, { payload }) => {
        const previousData = state.data?.items || [];
        const newData = payload?.items || [];
        const data = [...previousData, ...newData];
        return {
            ...state,
            data: {
                ...payload,
                items: data
            },
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(historyDelete, (state, { idItemCreditDocument }) => {
        const documents = [...state.data?.items];
        const index = documents.findIndex(item => item.idItemCreditDocument == idItemCreditDocument);
        documents.splice(index, 1);
        return {
          ...state,
          data: {
            ...state.data,
            items: [...documents]
          }
        };
      }),
    on(historyReset, state => state = initialState)
);

export function escomHistoryReducer(state: any, action: any): HistoryStateModel {
    return _escomHistoryReducer(state, action);
}
