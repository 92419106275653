import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { productsWithFileList } from './products-file.actions';
import { selectProductsWithFile } from './products-file.selectors';

@Injectable()
export class ProductsFileFacade {
  constructor(private readonly store: Store) {}

  getProductsWithFileList(id: number) {
    this.store.dispatch(productsWithFileList({ id }));
  }

  selectProductsWithFile() {
    return this.store.select(selectProductsWithFile);
  }

}
