import { createAction, props } from "@ngrx/store";

export const operationSave = createAction('[New Operation] Save',
    props<{payload: any}>()
);
export const operationFailed = createAction('[New Operation] Failed',
    props<{payload: any}>()
);
export const operationSuccess = createAction('[New Operation] Success',
    props<{payload: any}>()
);
export const operationReset = createAction(
    '[New Operation] Reset'
);
