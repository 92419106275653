import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  @Input() stepNumber: number = 0;
  @Input() description: string = 'Sin texto';
  @Input() active: boolean = false;
  @Input() checked: boolean = false;
  @Input() hasBg: boolean; 
  @Input() error: boolean = false;
  @Output() sendSetp = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.sendSetp.emit(this.stepNumber);
  }

}
