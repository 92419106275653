import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ClientReducerModule, ClientEffects } from './client.store';
import { EffectsModule } from '@ngrx/effects';
import { CommonsService } from 'src/app/services/commons.service';
import { FamilyClientFacade } from './state/familyClient/family-client-list.facade';
import { CLIENT_FEATURE_KEY } from "./client.store";
import { HierarchyListFacade } from './state/hierarchy-list/hierarchy-list.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CLIENT_FEATURE_KEY, ClientReducerModule),
    EffectsModule.forFeature(ClientEffects)
  ],
  providers: [CommonsService, FamilyClientFacade, HierarchyListFacade]
})
export class ClientModule { }
