import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  InstructiveDeleteDTO,
  InstructiveDTO,
  InstructiveGetDTO,
  InstructiveProductDeleteDTO,
  InstructiveProductsCreateDTO,
  InstructiveProductsDTO,
  InstructiveProductsUpdateDTO,
} from '../pages/instructive/models';
import {
  ContentType,
  HttpClientService,
} from '../shared/core/services/http-client.service';

@Injectable()
export class InstructiveService {
  constructor(private readonly http: HttpClientService) {}

  getInstructive(body: InstructiveGetDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/productFile/listFileProduct`;
    return this.http.post<any>(path, body, options)
      .pipe(
        map(response => {
          return (response?.productFileList || []) as InstructiveDTO[];
        })
      );
  }

  setPriority(body: any, isDelete: boolean) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const basePath = `${environment.base_url}${environment.reporting_path}/productFile/`;
    const action = isDelete ? 'deletePriority' : 'registerPriority';
    const path = basePath + action;
    return this.http.post<any>(path, body, options);
  }

  getProductsWithFile(idCampaign: number | null) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/productFile/listProductWithFile`;
    return this.http.post<any>(path, { idCampaign }, options)
      .pipe(
        map(response => {
          return (response?.productList || []) as InstructiveProductsDTO[];
        })
      );
  }

  deleteInstructive(body: InstructiveDeleteDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/productFile/deleteProductFile`;
    return this.http.post<any>(path, body, options);
  }

  createInstructive(body: FormData) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/productFile/registerProductFile`;
    return this.http.post<any>(path, body, options);
  }

  updateInstructive(body: FormData) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }

    const path = `${environment.base_url}${environment.reporting_path}/productFile/updateProductFile`;
    return this.http.post<any>(path, body, options);
  }

  download(id: number) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      },
      responseType: 'blob'
    }

    const path = `${environment.base_url}${environment.reporting_path}/productFile/downloadFileProduct`;
    return this.http.get(`${path}?idProductFile=${id}`, options);
  }

  registerProduct(body: InstructiveProductsCreateDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/element-format/register-product`;
    return this.http.post<any>(path, body, options);
  }

  updateProduct(body: InstructiveProductsUpdateDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/element-format/update-product`;
    return this.http.post<any>(path, body, options);
  }

  deleteProduct(body: InstructiveProductDeleteDTO) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.reporting_path}/element-format/delete-product`;
    return this.http.post<any>(path, body, options);
  }
}
