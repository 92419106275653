import { createAction, props } from "@ngrx/store";
import { ClosenessGetDTO } from "src/app/pages/closeness/pages/closeness-list/models";
import { ClosenessPaginatorDTO } from "src/app/pages/closeness/pages/closeness-list/models/closeness-paginator.dto";

export const closenessList = createAction('[Closeness] List',
    props<{payload: ClosenessGetDTO}>()    
);
export const closenessFailed = createAction('[Closeness] Failed',
    props<{payload: any}>()
);

export const closenessSuccess = createAction('[Closeness] Success',
    props<{payload: ClosenessPaginatorDTO}>()
);

export const closenessReset = createAction(
    '[Closeness] Reset'
);
