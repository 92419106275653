import { Injectable } from '@angular/core';
import { ContentType, HttpClientService } from '../shared/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  constructor(
    private readonly http: HttpClientService) {}

  getUser(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.http.getContentType(ContentType.JSON),
      }
    }
    const path = `${environment.base_url}${environment.users_path}/users/search-user`;
    return this.http.post<any>(path, body, options);
  }

}
