import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ParametersEnum } from "src/app/shared/core/enums/parameters.enum";
import { informationTypeList, informationTypeReset } from "./escom-information-type.actions";
import { selectInformationType } from "./escom-information-type.selectors";

@Injectable()
export class EscomInformationTypeFacade {

    constructor(private readonly store: Store) {}

    getInformationType() {
        this.store.dispatch(informationTypeList({tableId: ParametersEnum.escom}));
    }

    selectInformationType() {
        return this.store.select(selectInformationType);
    }

    reset() {
        this.store.dispatch(informationTypeReset());
    }

}