import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CommonsService } from 'src/app/services/commons.service';
import { productsList, productsFailed, productsSuccess } from './products.actions';

@Injectable()
export class ProductsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly commonsService: CommonsService
    ) { }

    getProductsCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(productsList),
            mergeMap((action) => {
                return this.commonsService.getParams(action.id)
                    .pipe(
                        map((data) => {
                            return productsSuccess({ payload: data });
                        }),
                        catchError((error) => of(productsFailed(error)))
                    )
            })
        )
    )

}
