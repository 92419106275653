import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClosenessEffects, ClosenessReducerModule } from './closeness.store';
import { CLOSENESS_FEATURE_KEY } from './closeness.store';
import { CoreModule } from 'src/app/shared/core/core.module';
import { ClosenessFacade } from './state/closeness-list/closeness-list.facade';
import { ClosenessService } from 'src/app/services/closeness.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    StoreModule.forFeature(CLOSENESS_FEATURE_KEY, ClosenessReducerModule),
    EffectsModule.forFeature(ClosenessEffects)
  ],
  providers: [
    ClosenessService,
    ClosenessFacade
  ]
})
export class ClosenessModule { }
