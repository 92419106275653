import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ClosenessGetDTO } from "src/app/pages/closeness/pages/closeness-list/models";
import { closenessList, closenessReset } from "./closeness-list.actions";
import { selectCloseness } from "./closeness-list.selectors";

@Injectable()
export class ClosenessFacade {

    constructor(private readonly store: Store) {}
    
    getCloseness(request: ClosenessGetDTO) {
        this.store.dispatch(closenessList({ payload: request }));
    }

    selectCloseness() {
        return this.store.select(selectCloseness);
    }

    reset() {
        this.store.dispatch(closenessReset());
    }

}