<div class="operation">
  <div class="operation_row" [ngClass]="{'leads': leads}"
  [formGroup]="contactoForm">

    <div class="operation_row_item company" [ngClass]="{'new': contacto.isNew}">
      <app-tooltip-text 
        [top]="'-40px'"
        [right]="'auto'"
        [width]="'250px'"
        [description]="contactoForm.value.company | capitalize">
      </app-tooltip-text>
    </div>

    <div class="operation_row_item cu">
      <ng-container *ngIf="contacto.isNew else cuText">
        <mat-form-field appearance="outline">
            <input matInput formControlName="cu" maxlength="14" (blur)="getCompany()">

            <app-tooltip *ngIf="contactoForm.get('cu').errors && (contactoForm.get('cu').touched || contactoForm.get('cu').dirty)"
            [text]="getErrorMsg('cu')"
            [invalid]="contactoForm.get('cu').errors"
            [type]="'error'"
            [left]="'50px'" [width]="'170px'" [top]="'-50px'">
          </app-tooltip>

        </mat-form-field>
      </ng-container>
      <ng-template #cuText>
        {{this.contactoForm.value.cu}}
      </ng-template>
    </div>

    <div class="operation_row_item nombreContacto">
      <ng-container *ngIf="contacto.isNew else nombreText">

        <mat-form-field appearance="outline">
          <input matInput formControlName="nombre" minlength="2">

         <app-tooltip *ngIf="contactoForm.get('nombre').errors && (contactoForm.get('nombre').touched || contactoForm.get('nombre').dirty)"
            [text]="getErrorMsg('nombre')"
            [invalid]="contactoForm.get('nombre').errors"
            [type]="'error'"
            [left]="'50px'" [width]="'170px'" [top]="'-50px'">
          </app-tooltip>

        </mat-form-field>
      </ng-container>
      <ng-template #nombreText>
        <app-tooltip-text 
            [top]="'-40px'"
            [right]="'auto'"
            [width]="'250px'"
            [description]="contactoForm.value.nombre | capitalize">
          </app-tooltip-text>
      </ng-template>
    </div>

    <div class="operation_row_item posicion">
      <ng-container *ngIf="contacto.isNew || tipoOperacion == 'edit'  || (tipoOperacion == 'edit'  && searchActivate == true) else cargoText">
        <mat-form-field appearance="outline">
            <input matInput formControlName="cargo" minlength="2">

            <app-tooltip *ngIf="contactoForm.get('cargo').errors && (contactoForm.get('cargo').touched || contactoForm.get('cargo').dirty)"
            [text]="getErrorMsg('cargo')"
            [invalid]="contactoForm.get('cargo').errors"
            [type]="'error'"
            [left]="'50px'" [width]="'170px'" [top]="'-50px'">
          </app-tooltip>

        </mat-form-field>
      </ng-container>
      <ng-template #cargoText>
          <app-tooltip-text 
            [top]="'-50px'"
            [right]="'auto'"
            [width]="'250px'"
            [description]="contactoForm.value.cargo | capitalize">

          </app-tooltip-text>
      </ng-template>
    </div>

    <div class="operation_row_item phone">
      <ng-container *ngIf="contacto.isNew || tipoOperacion == 'edit' || (tipoOperacion == 'edit'  && searchActivate == true) else phoneText">
        <mat-form-field appearance="outline">
            <input matInput formControlName="telefono1" minlength="2" maxlength="15">

            <app-tooltip *ngIf="contactoForm.get('telefono1').errors && (contactoForm.get('telefono1').touched || contactoForm.get('telefono1').dirty)"
            [text]="getErrorMsg('telefono1')"
            [invalid]="contactoForm.get('telefono1').errors"
            [type]="'error'"
            [left]="'50px'" [width]="'170px'" [top]="'-50px'">
          </app-tooltip>

        </mat-form-field>
      </ng-container>
      <ng-template #phoneText>
        {{this.contactoForm.value.telefono1}}
      </ng-template>
    </div>

    <div class="operation_row_item phone">
      <ng-container *ngIf="contacto.isNew || tipoOperacion == 'edit' || (tipoOperacion == 'edit'  && searchActivate == true) else phone2Text">
        <mat-form-field appearance="outline">
            <input matInput formControlName="telefono2" minlength="2" maxlength="15">

            <app-tooltip *ngIf="contactoForm.get('telefono2').errors && (contactoForm.get('telefono2').touched || contactoForm.get('telefono2').dirty)"
            [text]="getErrorMsg('telefono2')"
            [invalid]="contactoForm.get('telefono2').errors"
            [type]="'error'"
            [left]="'50px'" [width]="'170px'" [top]="'-50px'">
          </app-tooltip>

        </mat-form-field>
      </ng-container>
      <ng-template #phone2Text>
        {{this.contactoForm.value.telefono2}}
      </ng-template>
    </div>

    <div class="operation_row_item correo">
      <ng-container *ngIf="contacto.isNew || tipoOperacion == 'edit' || (tipoOperacion == 'edit'  && searchActivate == true) else correoText">
        <mat-form-field appearance="outline">
            <input matInput formControlName="correo" minlength="2">

            <app-tooltip 
              *ngIf="contactoForm.get('correo').errors && 
                (contactoForm.get('correo').touched || 
                contactoForm.get('correo').dirty)"
              [text]="getErrorMsg('correo')"
              [invalid]="contactoForm.get('correo').errors"
              [type]="'error'"
              [left]="'50px'" [width]="'170px'" [top]="'-50px'">
            </app-tooltip>

        </mat-form-field>
      </ng-container>
      <ng-template #correoText>
        <app-tooltip-text 
          [top]="'-50px'"
          [right]="'auto'"
          [width]="'220px'"
          [lines]="1"
          [description]="this.contactoForm.value.correo | lowercase"
          [buttonText]="'Copiar'"
          (clickButton)="copyToClipboard()">
        </app-tooltip-text>
      </ng-template>
    </div>

    <div class="operation_row_item lastUpdate">
      {{this.contactoForm.value.fechaActualizacion}}
    </div>

    <div class="operation_row_item remove">
      <ng-container *ngIf="this.contacto.isNew else borrarRegistro">
        <a (click)="remove('agregado', this.indiceContacto)" *hasPermission>
          <img src="/assets/img/close.svg" alt="delete">
        </a>
      </ng-container>
      <ng-template #borrarRegistro>
        <a (click)="remove('listado',this.indiceContacto)" *hasPermission>
          <img src="/assets/img/delete_2.svg" alt="delete">
        </a>
      </ng-template>

   </div>

  </div>

</div>
