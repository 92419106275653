import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EscomService } from 'src/app/services/escom.service';
import { historyList, historyFailed, historySuccess } from './escom-history.actions';

@Injectable()
export class EscomHistoryEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly escomService: EscomService
    ) { }

    getHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(historyList),
            mergeMap((action) => {
                return this.escomService.getHistory(action.request)
                    .pipe(
                        map((data) => {
                            return historySuccess({ payload: data });
                        }),
                        catchError((error) => of(historyFailed(error)))
                    )
            })
        )
    )

}
