import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InstructiveListEffects, InstructiveReducerModule } from './instructive.store';
import { INSTRUCTIVE_FEATURE_KEY } from './instructive.store';
import { InstructiveService } from 'src/app/services/instructive.service';
import { CoreModule } from 'src/app/shared/core/core.module';
import { InstructiveFacade } from './state/instructive/instructive.facade';
import { InstructiveProductsFacade } from './state/products/instructive-products.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    StoreModule.forFeature(INSTRUCTIVE_FEATURE_KEY, InstructiveReducerModule),
    EffectsModule.forFeature(InstructiveListEffects)
  ],
  providers: [
    InstructiveService,
    InstructiveFacade,
    InstructiveProductsFacade
  ]
})
export class InstructiveModule { }
