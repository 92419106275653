import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/shared/core/services/dialog.service';

@Component({
  selector: 'app-vertical-modal',
  templateUrl: './vertical-modal.component.html',
  styleUrls: ['./vertical-modal.component.scss']
})
export class VerticalModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() close = new EventEmitter<boolean>(false);
  @Input() title: string;
  /**
   * Show confirmation modal before exit
   */
  @Input() confirm: boolean = false;
  @Input() confirmDescription: string = '¿Desea salir sin guardar los cambios realizados?';
  @Input() confirmOkText: string = 'Si, salir';
  @Input() confirmCancelText: string = 'No';

  constructor(private readonly dialogService: DialogService) { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      (document.getElementById('contentId') as any).style.overflow = 'hidden';
    }, 1000);
  }

  ngOnDestroy(): void {
    (document.getElementById('contentId') as any).style.overflow = 'auto';
  }

  closeModal() {
    if (this.confirm) {
      this.dialogService.confirmationModal(this.confirmDescription, this.confirmOkText, this.confirmCancelText)
        .afterClosed().subscribe(response => {
          if (response) {
            this.goOut();
          }
        });
    } else {
      this.goOut();
    }
  }

  goOut() {
    (document.getElementById('contentId') as any).style.overflow = 'auto';
    this.close.emit(true);
  }

}
