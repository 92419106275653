import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {

  constructor() {}

  getItem<T>(key: string): T {
    const data: any = sessionStorage.getItem(key) ?? null;
    return JSON.parse(data) as T;
  }

  setItem<T>(key: string, value: T): void {
    const data = JSON.stringify(value ?? '');
    sessionStorage.setItem(key, data);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }

}
