import { createAction, props } from "@ngrx/store";
import { InformationTypeDTO } from "src/app/pages/escom-list/models";

export const informationTypeList = createAction('[Escom Information Type] List',
    props<{tableId: number}>()    
);
export const informationTypeFailed = createAction('[Escom Information Type] Failed',
    props<{payload: any}>()
);

export const informationTypeSuccess = createAction('[Escom Information Type] Success',
    props<{payload: InformationTypeDTO[]}>()
);

export const informationTypeReset = createAction(
    '[Escom Information Type] Reset'
);
