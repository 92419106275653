import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InstructiveService } from 'src/app/services/instructive.service';
import { instructiveProductsList, instructiveProductsFailed, instructiveProductsSuccess } from './instructive-products.actions';

@Injectable()
export class InstructiveProductsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly instructiveService: InstructiveService
    ) { }

    getInstructiveProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(instructiveProductsList),
            mergeMap((action) => {
                return this.instructiveService.getProductsWithFile(action.idCampaign)
                    .pipe(
                        map((data) => {
                            return instructiveProductsSuccess({ payload: data });
                        }),
                        catchError((error) => of(instructiveProductsFailed(error)))
                    )
            })
        )
    )

}
