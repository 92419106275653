<div class="contact-list-detail">
  <app-loading [loading]="loading"></app-loading>

  <div class="contact-list-detail__filter">
    <div class="display-flex align-items-center">
      <div class="display-flex align-items-center">
        <app-search placeholder="Busca aquí al contacto..." (search)="search($event)" outline=true>
        </app-search>
        <div class="contact-list-detail__filter-add"
          [ngClass]="{'disabled-control': writingMode}"
          (click)="addContact()" *hasPermission>
          <img src="/assets/img/icon-user-btn.svg" alt="img" class="image">
          <span>
            Añadir contacto
          </span>
        </div>
      </div>
      <div>
        <div class="display-flex align-items-center padding-left-20 padding-right-30">
          <div class="display-flex align-items-center padding-right-30 position-relative cursor-pointer" 
            (mouseenter)="tooltip_validado.show()"
            (mouseleave)="tooltip_validado.hide()">
            <span class="contact-list-detail__situation bg-regal-blue"></span>
            <span class="color-text-color-default f-size-14">Validado</span>
            <app-tooltip-ui
                #tooltip_validado
                [top]="'20px'"
                left="0"
                bgColor="#5d73a0">
                Contacto con datos completados y validado por EN/AN
            </app-tooltip-ui>
          </div>
          <div class="display-flex align-items-center position-relative cursor-pointer"
            (mouseenter)="tooltip_sugerido.show()"
            (mouseleave)="tooltip_sugerido.hide()">
            <span class="contact-list-detail__situation bg-primary"></span>
            <span class="color-text-color-default f-size-14">Sugerido</span>
            <app-tooltip-ui
                #tooltip_sugerido
                [top]="'20px'"
                left="0"
                bgColor="#5dca96">
                Contacto recomendado, obtenido por fuentes externas
            </app-tooltip-ui>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-list-detail__filter-close" (click)="closeContacts()">
      <img src="/assets/img/icon-close-NPS.svg" alt="img" class="image">
      <span>
        Cerrar
      </span>
    </div>
  </div>

  <div class="padding-right-10 padding-left-10 padding-bottom-10">
    <div class="table-container">
      <div class="table-container__header z-index-1">
        <div *ngFor="let header of tableHeaders; let i = index" class="table-header-cell"
          [ngStyle]="{'width': header.width}">
          <ng-container [ngSwitch]="header.name">
            <ng-container *ngSwitchDefault>
              <div class="width-100-per color-white">
                {{ header.label }}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="table-container__body bg-progress">
        <ng-container *ngIf="contactForm?.controls?.length > 0 else nofilter">
          <ng-container *ngFor="let row of contactForm.controls; let i = index">
            <app-contact-list-detail-item 
              [form]="row"
              [writingMode]="writingMode"
              [documentType]="documentType"
              [documentNumber]="documentNumber"
              [tableHeaders]="tableHeaders"
              (reloadList)="refresh()"
              (onEdit)="writingMode = true"
              (onRemove)="remove(i)"
              (onCancelEdit)="cancelEdit($event)">
            </app-contact-list-detail-item>
          </ng-container>
        </ng-container>
        <ng-template #nofilter>
          <app-empty *ngIf="showEmpty" [description]="'No se encontraron registros con los filtros ingresados'" [height]="160"></app-empty>
        </ng-template>

      </div>
    </div>
  </div>

</div>