import { Injectable } from '@angular/core';
import { ParamsFacade } from 'src/app/store/params/state/params/params.facade';
import { ParametersEnum } from 'src/app/shared/core/enums/parameters.enum';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/core/base/base.component';

@Injectable({
  providedIn: 'root'
})
export class PrincipalFormService extends BaseComponent{

  userAccess$ = this.paramsFacade.selectParamsData(ParametersEnum.userAccessView);
  usersAccess: any;
  activeView: boolean = false;
  mainUser: string = "";
  userSearch = {
    name: "Aún no se ha generado la búsqueda",
    user: "--------"
  }

  userChanged = {
    name: "Aún no se ha realizado el cambio",
    user: "--------"
  }

  showModal: boolean = false;
  valueUserSearch: string = "";

  constructor(
    private readonly paramsFacade: ParamsFacade,
  ) { super() }

  public getUsers() {
    if(this.usersAccess == undefined && !this.activeView){
      this.paramsFacade.getParams(ParametersEnum.userAccessView);
    }else if(this.usersAccess != undefined && !this.activeView){
      this.verifyActivateView();
    }
  }

  listenUsers() {
    if(this.usersAccess == undefined && !this.activeView){
      this.userAccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.usersAccess = users;
        if(this.usersAccess.length > 0 && !this.activeView){
          this.verifyActivateView();
        }
      });
    }
  }

  public verifyActivateView() {
    for (const objeto of this.usersAccess) {
      if (objeto && Object.keys(objeto).length > 0) {
        const user = objeto["campo1"];
        if (user === this.mainUser) {
          this.activeView = true;
          return;
        } else {
          this.activeView = false;
        }
      }
    }
  }

  public clearUserSearch() {
    this.userSearch = {
      name: "Aún no se ha generado la búsqueda",
      user: "--------"
    }
  }

  public clearUserChanged() {
    this.userChanged = {
      name: "Aún no se ha realizado el cambio",
      user: "--------"
    }
  }

  resetData() {
    this.usersAccess = undefined;
    this.activeView = false;
    this.showModal = false;
    this.valueUserSearch = "";
    this.mainUser = "";
    this.clearUserSearch();
    this.clearUserChanged();
    // Restablecer otras propiedades si es necesario
  }
}
