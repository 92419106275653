import { AbstractControl } from "@angular/forms";

export const PeriodValidator = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const value = control.value;
    const year = value ? value.toString().substring(0, 4) : 0;
    const month = value ? value.toString().substring(4, 6) : 0;
    const now = new Date();
    if (year > 0 && year >= now.getFullYear() 
        && month > 0 && [1,2,3,4,5,6,7,8,9,10,11,12].some(x => x == +month) && +month >= (now.getMonth() + 1)) {
     return null;
    }
    return { periodInvalid: true };
};