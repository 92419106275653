import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ParamsListEffects, ParamsReducerModule } from './params.store';
import { PARAMS_FEATURE_KEY } from './params.store';
import { CommonsService } from 'src/app/services/commons.service';
import { ParamsFacade } from './state/params/params.facade';
import { ProductsFacade } from './state/products/products.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(PARAMS_FEATURE_KEY, ParamsReducerModule),
    EffectsModule.forFeature(ParamsListEffects),
  ],
  providers: [CommonsService, ParamsFacade, ProductsFacade],
})
export class ParamsModule {}
