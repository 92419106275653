import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from '../../../assets/ts/datepickerConf';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { SearchComponent } from 'src/app/components/search/search.component';

import { PrincipalService } from 'src/app/services/principal.service';
import { DialogService } from 'src/app/shared/core/services/dialog.service';
import { TypeInformationModal } from 'src/app/shared/ui/components';
import { PrincipalFormService } from './services/principal-form.service';
import { USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./principal.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MY_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})

export class PrincipalComponent implements OnInit {

  //Obtener el componente busqueda - para limpiar texto
  @ViewChild(SearchComponent) appSearchComponent!: SearchComponent;

  loginForm: FormGroup;
  public formSubmitted = false;
  userLogn: any;
  nameUser: string;
  lastnameUser: string;
  user: string;

  //--------

  disabledButton:boolean = true;
  error:boolean = false;

  constructor(
    private principalService: PrincipalService,
    public readonly principalFormService: PrincipalFormService,
    private readonly dialogService: DialogService,
    private readonly localStorageService: LocalStorageService
  ) {
    if(this.principalFormService.usersAccess == undefined && !this.principalFormService.activeView){
      if(localStorage.getItem("usuarioLogin")){
        this.userLogn = JSON.parse(localStorage.getItem("usuarioLogin")!);
        let arrayUserthis = this.userLogn.name.split(',')
        this.lastnameUser = arrayUserthis[0];
        this.nameUser = arrayUserthis[1];
        this.user = this.userLogn.username;
        this.principalFormService.mainUser = this.user;
      }else{
        this.userLogn = {name: ""};
      }
    }else {
      if(localStorage.getItem("usuarioLogin")){
        const userLoginObj = localStorage.getItem('usuarioLogin');
        if (userLoginObj) {
          const userLogin: { username?: string, name ?: string} = JSON.parse(userLoginObj);

          if (userLogin.username && userLogin.name) {
            const userInfo = this.localStorageService.getItem<string>(USER_INFO) ?? '';
            if (userLogin.username === userInfo) {
              let arrayUserthis = userLogin.name.split(',')
              this.lastnameUser = arrayUserthis[0];
              this.nameUser = arrayUserthis[1];
              this.user = userLogin.username;
              this.principalFormService.mainUser = this.user;
            }else{
              let arrayUserChanged = this.principalFormService.userChanged.name.split(',')
              this.lastnameUser = arrayUserChanged[0];
              this.nameUser = arrayUserChanged[1];
              this.user = this.principalFormService.userChanged.user;
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.principalFormService.getUsers();
    this.principalFormService.listenUsers();
  }

  public searchUser(value:string) {
    let newValue = value.trim()
    let patternLetterNumber = /^[a-zA-Z0-9]+$/;
    this.disabledButton = false;
    if( newValue.length >= 1 && newValue.length < 7 && patternLetterNumber.test(newValue)){
      this.error = false;
      this.principalFormService.valueUserSearch = value;
      this.getUser();
    }else if( newValue.length == 0){
      this.error = false;
      this.clear();
    }else{
      this.error = true;
      this.clear();
    }
  }

  private getUser(){
    let body = {
      "userRegistration": this.principalFormService.valueUserSearch
    }

    if(this.principalFormService.valueUserSearch == "" || this.principalFormService.valueUserSearch == null) {
      this.disabledButton = true;
      this.error = false
    }else{
      this.principalService.getUser(body).subscribe({
        next: (infoUser: any) => {
          if(infoUser !== null || infoUser !== undefined){
            this.principalFormService.userSearch.name = infoUser.nameUser;
            this.principalFormService.userSearch.user = infoUser.userRegistration;
          }else{
            this.dialogService.informationModal("No se encontro el usuario con la busqueda realizada, Ingrese otro registro de usuario", TypeInformationModal.WARNING);
          }
        },
        error: (error) => {
          this.dialogService.informationModal("Error al realizar la busqueda, Intenta nuevamente en unos minutos", TypeInformationModal.ERROR);
        }
      })
    }
  }

  private clear() {
    this.principalFormService.clearUserSearch();
    this.disabledButton = true;
  }

  public changeUser() {
    this.localStorageService.setItem(USER_INFO, this.principalFormService.userSearch.user);
    this.principalFormService.userChanged = this.principalFormService.userSearch;
    let arrayUserChanged = this.principalFormService.userChanged.name.split(',')
    this.lastnameUser = arrayUserChanged[0];
    this.nameUser = arrayUserChanged[1];
    this.user = this.principalFormService.userChanged.user;
    this.appSearchComponent.clear();
    this.clear();
    this.dialogService.informationModal("Se cambio el usuario correctamente", TypeInformationModal.SUCCESS);
    this.principalFormService.showModal = false;
  }

  public cancelChangeUser() {
    this.principalFormService.showModal = false;
  }

}
