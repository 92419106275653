import { createReducer, on } from "@ngrx/store";
import { CampaignListStateModel } from "./campaign-list.model";
import { campaignList, campaignSuccess, campaignFailed, campaignReset } from './campaign-list.actions';

export const initialState: CampaignListStateModel = {
    data: null,
    loading: false,
    failed: false,
    loaded: false,
    error: null
};

const _campaignListReducer = createReducer(initialState,
    on(campaignList, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(campaignFailed, (state, payload) => {
        return {
            ...state,
            data: null,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(campaignSuccess, (state, {payload}) => {
        return {
            ...state,
            data: {...payload},
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(campaignReset, state => state = initialState)
);

export function campaignListReducer(state: any, action: any): CampaignListStateModel {
    return _campaignListReducer(state, action);
}
