import { createAction, props } from "@ngrx/store";

export const fallsJefeList = createAction('[FallsJefe] List',
    props<{payload: any}>()
);
export const fallsJefeFailed = createAction('[FallsJefe] Failed',
    props<{payload: any}>()
);
export const fallsJefeSuccess = createAction('[FallsJefe] Success',
    props<{payload: any}>()
);

export const fallsJefeReset = createAction(
    '[FallsJefe] Reset'
);

