import { Component, OnInit } from '@angular/core';
import { CampaignConfigFacadeService } from './services/campaign-config-facade.service';

@Component({
  selector: 'app-campaign-config',
  templateUrl: './campaign-config.component.html',
  styleUrls: ['./campaign-config.component.scss']
})
export class CampaignConfigComponent implements OnInit {

  constructor(
    private readonly campaignConfigFacadeService: CampaignConfigFacadeService) { }

  ngOnInit(): void {
    this.campaignConfigFacadeService.getDetailCatalog();
    this.campaignConfigFacadeService.getCampaignTypes();
    this.campaignConfigFacadeService.getAttributeTypes();
    this.campaignConfigFacadeService.getInputAttribute();
    this.campaignConfigFacadeService.getCampaignProductsTypes();
    this.campaignConfigFacadeService.getCampaignStates();
    this.campaignConfigFacadeService.getCampaignStateValidate();
  }

}
