<div class="automplete-search">
    <app-search
        #search
        [placeholder]="placeholder"
        [autocomplete]="true"
        [timeAutocomplete]="1200"
        [error]="error"
        (search)="filter($event)"
        (writing)="onWriting($event)"
        (onFocus)="onFocus()"
        (onFocusOut)="onFocusOut()"
    >
    </app-search>
    <div class="automplete-search__content" *ngIf="open && text">
        <app-loading [loading]="loading" [size]="20"></app-loading>
        <ng-container *ngIf="!loading">
            <div class="automplete-search__item" *ngFor="let item of dataSource"
                (click)="setItem(item)">
                <span>{{item.description}}</span>
            </div>
        </ng-container>
    </div>
</div>
