import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InstructiveDTO } from 'src/app/pages/instructive/models';
import { InstructiveService } from 'src/app/services/instructive.service';
import { instructiveList, instructiveFailed, instructiveSuccess } from './instructive.actions';

@Injectable()
export class InstructiveEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly instructiveService: InstructiveService
    ) { }

    getInstructive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(instructiveList),
            mergeMap((action) => {
                const request = action.payload;
                return this.instructiveService.getInstructive(request)
                    .pipe(
                        map((data) => {
                            return instructiveSuccess({ payload: data });
                        }),
                        catchError((error) => of(instructiveFailed(error)))
                    )
            })
        )
    )

}
