import { createReducer, on} from "@ngrx/store";
import { QualitativeValidationStepsStateModel } from "./qualitative-new.model";
import { qualitativeValidationStep, qualitativeValidationStepFailed, qualitativeValidationStepSuccess, qualitativeValidationStepReset } from "./qualitative-new.actions";

export const initialState: QualitativeValidationStepsStateModel = {
    data: {
        flgStepFour: 0,
        flgStepOne: 0,
        flgStepThree: 0,
        flgStepTwo: 0,
        idQualitativeRecord: 0,
        state: 0
    },
    loading: false,
    failed: false,
    loaded: false
};

const _qualitativeNewReducer = createReducer(initialState,
    on(qualitativeValidationStep, state => {
        return { 
            ...state,
            loading: true,
            loaded: false
        }
    }),
    on(qualitativeValidationStepFailed, (state, { payload }) => {
        return {
            ...state,
            loading: false,
            failed: true,
            loaded: true
        }
    }),
    on(qualitativeValidationStepSuccess, (state, { payload }) => {
        return {
            ...state,
            data: {
                ...payload
            },
            loading: false,
            failed: false,
            loaded: true
        }
    }),
    on(qualitativeValidationStepReset, state => state = initialState)
);

export function qualitativeNewReducer(state: any, action: any): QualitativeValidationStepsStateModel {
    return _qualitativeNewReducer(state, action);
}
