import { createReducer, on} from "@ngrx/store";
import { operationFailed, operationSave, operationReset, operationSuccess } from "./new-operation.actions";
import { NewOperationStateModel } from "./new-operation.model";

export const initialState: NewOperationStateModel = {
    data: null,
    loading: false,
    failed: false
};

const _newOperationReducer = createReducer(initialState,
    on(operationSave, state => {
        return { 
            ...state,
            loading: true
        }
    }),
    on(operationFailed, (state, payload) => {
        return {
            ...state,
            loading: false,
            failed: true
        }
    }),
    on(operationSuccess, (state, {payload}) => {
        return {
            ...state,
            data: payload,
            loading: false,
            failed: false
        }
    }),
    on(operationReset, state => state = initialState)
);

export function newOperationReducer(state: any, action: any): NewOperationStateModel {
    return _newOperationReducer(state, action);
}
