import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LeadService } from 'src/app/services/lead.service';
import { configurationsElementList, configurationsList, configurationsReset } from './configuration.actions';
import { selectCampaignConfigurationState, selectCampaignConfigurations, selectCampaignConfigurationsElement } from './configuration.selectors';

@Injectable()
export class ConfigurationCampaignFacade {
  constructor(private readonly store: Store,
    private readonly leadService: LeadService) {}

  getConfiguration(id: number, typeElement?: number) {
    this.store.dispatch(configurationsList({ id, typeElement }));
  }

  getConfigurationElement(id: number, typeElement?: number) {
    this.store.dispatch(configurationsElementList({ id, typeElement }));
  }

  resetConfigurations() {
    this.store.dispatch(configurationsReset());
  }

  selectCampaignConfiguration() {
    return this.store.select(selectCampaignConfigurationState);
  }

  selectCampaignAttributeData() {
    return this.store.select(selectCampaignConfigurations)
  }

  selectCampaignElements() {
    return this.store.select(selectCampaignConfigurationsElement);
  }

  registerDetailLead(request: any): Observable<any> {
    return this.leadService.registerDetailLead(request);
  }

}
