import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OperationService } from 'src/app/services/operation.service';
import { fallsJefeList, fallsJefeFailed, fallsJefeSuccess } from "./falls-jefe.actions";

@Injectable()
export class FallsJefeEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly operationService: OperationService
    ) { }

    getFallsJefe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fallsJefeList),
            mergeMap((action) =>
                this.operationService.getFalls(action.payload)
                    .pipe(
                        map((data) => {
                            return fallsJefeSuccess({ payload: data });
                        }),
                        catchError((error) => of(fallsJefeFailed(error)))
                    )
            )
        )
    )

}
