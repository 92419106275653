import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactsFacadeService } from '../../services/contacts-facade.service';
import { CustomerContactRequestDTO } from '../../models/customer-contact.request.dto';
import { TableHeaderModel } from 'src/app/models/table-header.model';
import { CustomerContactExpandedDTO, CustomerContactPaginatorDTO } from '../../models/customer-contact.dto';
import { TableComponent } from 'src/app/shared/ui/components';
import { HierarchyModel } from 'src/app/models/hierarchy.model';
import { HierarchyDescShortEnum } from 'src/app/shared/core/enums/hierarchy.enum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {

  @ViewChild('table') tableComponent: TableComponent;

  tableHeaders: TableHeaderModel[] = [
    { label: 'Empresa', name: 'enterpriseName', width: '30%', filter: false, sort: false },
    { label: 'CU/RUC', name: 'uniqueCode;documentNumber', width: '17.5%', filter: false, sort: false },
    { label: 'Contactos validados', name: 'valid', width: '17.5%', filter: false, sort: false },
    { label: 'Contactos sugeridos', name: 'suggested', width: '17.5%', filter: false, sort: false },
    { label: 'Fecha de última actualización', name: 'fechaMod', width: '17.5%', filter: false, sort: false }
  ];

  customerContactList: CustomerContactExpandedDTO[] = [];
  offset: number = 0;
  limit: number = 100;
  currentPage: number = 0;
  totalPages: number = 0;
  totalItems: number = 0;
  loading: boolean = false;
  searchText: string = '';
  // openFilter: boolean = false;
  // isExecutive: boolean = true;
  
  constructor(private readonly contactsFacadeService: ContactsFacadeService,
    private readonly datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // this.contactsFacadeService.selectedHierarchyUser = null;
    this.getCustomerContact();
    this.contactsFacadeService.getPositions();
  }

  search(text: string) {
    this.searchText = text;
    this.offset = 0;
    this.customerContactList = [];
    this.totalPages = 0;
    this.totalItems = 0;
    this.getCustomerContact();
  }

  getCustomerContact() {
    const request: CustomerContactRequestDTO = {
      limit: this.limit,
      offset: this.offset,
      search: this.searchText,
      userRegistration: this.contactsFacadeService.user
      // userRegistration: this.contactsFacadeService.selectedHierarchyUser || this.contactsFacadeService.user,
    }
    this.loading = true;
    this.contactsFacadeService.getCustomerContact(request)
      .subscribe({
        next: (response: CustomerContactPaginatorDTO) => {
          this.loading = false;
          const { listCustomerContact, totalItems, totalPages, currentPage } = response;
          const previousData = [...this.customerContactList];
          const newData = listCustomerContact.map(x => { 
            const item: CustomerContactExpandedDTO = {
              ...x, expanded: false
            }
            return item;
           }) || [];
          const data = [...previousData, ...newData];
          this.customerContactList = data;
          this.totalItems = totalItems;
          this.totalPages = totalPages;
          this.currentPage = currentPage;
        },
        error: () => this.loading = false
      })
  }

  // setFirstHierarchy(hierarchy: HierarchyModel) {
  //   this.isExecutive = hierarchy?.hierarchyShort == HierarchyDescShortEnum.EJECUTIVO_NEGOCIO;
  // }
  
  // filterHierarchy(user: string) {
  //   this.openFilter = false;
  //   this.contactsFacadeService.selectedHierarchyUser = user;
  //   this.getCustomerContact()
  // }

  onScroll() {
    this.offset += 1;
    this.getCustomerContact();
  }

  expandir(index: number) {
    this.tableComponent.toggleRowExpansion(index);
  }

  refreshItem(index: number) {
    console.log(index);
    this.customerContactList[index].fechaMod = this.datePipe.transform(new Date(), 'dd-MM-yyyy') || '';
  }
  
}
