import { environment } from "src/environments/environment";

export class PathRest {

    static readonly GET_LOGOUT = environment.base_url + '/api/reverse-proxy/security-api/security/logout';
  
    static readonly DELETE_CONTACT = `${environment.base_url}${environment.contact_path}/contact/delete-contact`;
    static readonly CREATESHORT_CONTACT = `${environment.base_url}${environment.contact_path}/contact/process-contact-short-massive`;
    static readonly GET_CONTACT = `${environment.base_url}${environment.contact_path}/contact/list-contact`;
    static readonly GET_CONTACT_TYPE_UNIVERSE = `${environment.base_url}${environment.contact_path}/contact/validation-contact-universe`;
    static readonly GET_CUSTOMER_WITH_UNIQUE_CODE = `${environment.base_url}${environment.contact_path}/customer/search-customer`;
    static readonly GET_CONTACT_BY_ENTERPRISES = `${environment.base_url}${environment.contact_path}/contact/list-contact-dual-customer`;
    static readonly GET_LIST_CONTACT_BY_ENTERPRISES = `${environment.base_url}${environment.contact_path}/contact/list-contact-dual-contact`;

    static readonly DOWNLOAD_NPS = `${environment.base_url}${environment.contact_path}/contact/download-nps-client?period=`;
    static readonly LOAD_NPS_DATA = `${environment.base_url}${environment.contact_path}/contact/load-nps-data`;
    static readonly LIST_FOLDERS = `${environment.base_url}${environment.reporting_path}/productFile/listFolder`;
    static readonly URL_DOWNLOAD = `${environment.base_url}${environment.reporting_path}/productFile/downloadFileProduct?idProductFile=`;
    static readonly URL_DOWNLOAD_REPORTs = `${environment.base_url}${environment.reporting_path}/reportFile/downloadFileReport?idReportFile=`;
    //Resectorization
    static readonly GET_LIST_RESECTORIZATION = `${environment.base_url}${environment.leads_path}/customer/get-resectorization`;
    static readonly GET_LIST_CUSTOMER_SOLICITUD = `${environment.base_url}${environment.leads_path}/customer/get-customer`;
    static readonly GET_LIST_DESTINY_SECTORIST = `${environment.base_url}${environment.users_path}/shortCode/list-codes-subordinate-special`;
    static readonly CREATE_NEW_RESECTORIZATION = `${environment.base_url}${environment.leads_path}/customer/insert-resectorization`;

    //CE
    static readonly GET_LIST_VOUCHER_ELECTRONIC = `${environment.base_url}${environment.leads_path}/customer/list-customer-ce`;
    static readonly GET_LIST_CONTACTS_CLIENTS_CE = `${environment.base_url}${environment.contact_path}/contact/list-contact-universe`;
    static readonly GET_UPDATE_CONTACTS_CE = `${environment.base_url}${environment.contact_path}/contact/process-contact-universe`;
    static readonly GET_CHANGE_STATE_CONTACTS_CE = `${environment.base_url}${environment.contact_path}/contact/change-contact-universe`;


}
