import { createAction, props } from "@ngrx/store";

export const configurationsList = createAction('[Campaign configuration attribute] List',
    props<{id: number, typeElement?: number}>()
);

export const configurationsFailed = createAction('[Campaign configuration attribute] Failed',
    props<{payload: any}>()
);
export const configurationsSuccess = createAction('[Campaign configuration attribute] Success',
    props<{payload: any}>()
);

export const configurationsElementList = createAction('[Campaign configuration element] List',
    props<{id: number, typeElement?: number}>()
);

export const configurationsElementFailed = createAction('[Campaign configuration element] Failed',
    props<{payload: any}>()
);
export const configurationsElementSuccess = createAction('[Campaign configuration element] Success',
    props<{payload: any}>()
);

export const configurationsReset = createAction('[Campaign configuration] Reset');
