import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { qualitativeSheetList, qualitativeSheetReset } from "./qualitative.actions";
import { QualitativeGetDTO } from "src/app/pages/qualitative-sheet/models";
import { selectQualitative } from "./qualitative.selectors";
import { QualitativeService } from "src/app/services/qualitative.service";

@Injectable()
export class QualitativeFacade {

    constructor(private readonly store: Store, private readonly qualitativeService: QualitativeService) {}

    get(request: QualitativeGetDTO) {
        this.store.dispatch(qualitativeSheetList({request}));
    }

    selectQualitativeData() {
        return this.store.select(selectQualitative);
    }

    reset() {
        this.store.dispatch(qualitativeSheetReset());
    }

    downloadPDF(id: number) {
       return this.qualitativeService.downloadPDF(id);
    }

}