export class MenuListModel {
    checked: boolean;
    value: number;
    description: string;
    data?: any;

    constructor(checked: boolean, value: number, description: string, data?: any) {
        this.checked = checked;
        this.value = value;
        this.description = description;
        this.data = data;
    }
}